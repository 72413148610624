import React, { useEffect, useState, useRef, createRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { Colors, Fonts, Index, Breakpoints } from '../style/Variables';
import CustomEvents, { Events } from '../core/CustomEvents';
import CustomOverDetection from '../core/utils/CustomOverDetection';
import Image, { ImageData } from './Image';
import ImageCarousel from './ImageCarousel';
import Functions from '../style/Functions';

const Container = styled.div`
	margin-top: var(--contentModuleGap);
	width: 100vw;
	height: auto;
	position: relative;
	margin-left: -(var(--gridMargin));
	padding: 0;
	overflow: hidden;
	${Functions.breakpoint(Breakpoints.mobile)} {
		height: 100vh;
	}
`;

const ContainerMobile = styled.div``;

const ContainerDesktop = styled.div`
	width: 100vw;
	height: 100vh;
	position: relative;
`;

const ImageContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ImageTitle = styled.div`
	position: absolute;
	font-size: var(--fontMassive);
	text-align: center;
	font-family: ${Fonts.sprittenFamily};
	//mix-blend-mode: difference;
	color: white;
	z-index: 3;
	pointer-events: none;
`;

const SelectorOverlay = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	z-index: 2;
	position: absolute;
`;

const Selector = styled.div`
	background-color: transparent;
	flex-grow: 4;
`;

export default function HistoryCarousel({ data }: { data: { images: Array<any> } }) {
	const innerRef = React.useRef<HTMLDivElement>();
	const titleRef = React.useRef<HTMLDivElement>();
	const elementsRef = useRef(data.images.map(() => useRef()));
	const selectorRef = useRef(data.images.map(() => useRef()));
	const [isMobile, setIsMobile] = useState(false);
	let testMobile = false;

	useEffect(() => {
		data.images.forEach(item => {
			item.text = item.defaultFieldMetadata.title;
			item.image = { url: item.url, alt: '', title: item.title, defaultFieldMetadata: item.defaultFieldMetadata };
		});
	}, []);

	useEffect(() => {
		onBreakpointChange();
		CustomEvents.listen(Events.BREAKPOINT, onBreakpointChange);
		return () => {
			CustomOverDetection.Instance.remove(innerRef.current);
		};
	}, []);

	const onBreakpointChange = () => {
		if (window.innerWidth <= parseInt(Breakpoints.mobile)) {
			CustomOverDetection.Instance.remove(innerRef.current);
			setIsMobile(true);
			testMobile = true;
			console.log('WE DID THIS!, MOBILE TRUE');
		} else {
			CustomOverDetection.Instance.add(innerRef.current, mouseOver, mouseOut, moveAround, Index.historyCarousel);
			setIsMobile(false);
			testMobile = false;
			console.log('NOW WE DID THIS, MOBILE FALSE');
		}
	};

	const mouseOver = () => {
		CustomEvents.dispatch(Events.HIDEMOUSE, {});
	};

	const mouseOut = () => {
		CustomEvents.dispatch(Events.SHOWMOUSE);
		CustomEvents.dispatch(Events.STANDARDCURSOR);
		if (titleRef) {
			titleRef.current.innerHTML = data.images[0].defaultFieldMetadata.title;
			titleRef.current.style.top =
				Number(
					innerRef.current.getBoundingClientRect().height / 2 - titleRef.current.getBoundingClientRect().height / 2
				) + 'px';
			titleRef.current.style.left =
				Number(
					innerRef.current.getBoundingClientRect().width / 2 - titleRef.current.getBoundingClientRect().width / 2
				) + 'px';
		}
	};

	const moveAround = (x, y) => {
		if (titleRef) {
			//try {
			titleRef.current.style.top = Number(y - titleRef.current.getBoundingClientRect().height / 2) + 'px';
			titleRef.current.style.left = Number(x - titleRef.current.getBoundingClientRect().width / 2) + 'px';
			//} catch {
			//console.log("fuckin' error");
			//}
		}
		//console.log(x, y);
	};

	const setFirstImage = () => {
		gsap.to(elementsRef.current[0].current, { opacity: 1 });
	};

	const onSectionEnter = (index, title) => {
		gsap.to(elementsRef.current[index].current, { opacity: 1 });
		titleRef.current.innerHTML = title;
	};

	const onSectionLeave = index => {
		gsap.to(elementsRef.current[index].current, { opacity: 0 });
	};

	return (
		<Container>
			{!isMobile && (
				<ContainerDesktop ref={innerRef}>
					<ImageTitle ref={titleRef}></ImageTitle>
					<SelectorOverlay onMouseLeave={setFirstImage}>
						{data.images.map((image, index) => (
							<Selector
								onMouseEnter={e => onSectionEnter(index, image.defaultFieldMetadata.title)}
								onMouseLeave={e => onSectionLeave(index)}
								ref={selectorRef.current[index]}
								key={index}
							></Selector>
						))}
					</SelectorOverlay>
					{data.images.map((image, index) => (
						<ImageContainer ref={elementsRef.current[index]} key={index}>
							<Image src={image.url} />
						</ImageContainer>
					))}
				</ContainerDesktop>
			)}
			{isMobile && (
				<ContainerMobile>
					<ImageCarousel data={data} />
				</ContainerMobile>
			)}
		</Container>
	);
}
