import React from 'react';
import styled from 'styled-components';
import { getWord } from '../core/utils/Language';
import { Fonts } from '../style/Variables';
import CommonWords from '../types/CommonWords';

const Container = styled.div`
	border: 1px solid #000000;
	border-radius: 100px;
	text-align: center;
	padding: 10px 14px;
	width: fit-content;
	font-family: ${Fonts.monoFamily};
	font-size: var(--fontSmall);
	letter-spacing: 0.04em;
	text-transform: uppercase;

	display: flex;
	align-items: center;
`;

export default function TypeTag({ tag, type }: { tag: string; type: string }) {
	return tag ? <Container>{tag}</Container> : <></>;
}
