import React from 'react';
import styled from 'styled-components';
import HTML from '../core/utils/HTML';
import Functions from '../style/Functions';
import { Breakpoints, Colors, Fonts } from '../style/Variables';
import Image, { ImageData } from './Image';
import ImageWithDescription from './ImageWithDescription';
import RichTextModule from './RichTextModule';

const Container = styled.div`
	margin-top: var(--contentModuleGap);
	display: flex;
	height: fit-content;
	flex-direction: column;
	${Functions.breakpoint(Breakpoints.mobile)} {
		flex-direction: row;
	}
`;

const Story = styled.div`
	width: 100%;
	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${Functions.col(5, 12)};
		margin-left: ${Functions.col(1, 12, 1)};
	}
`;

const ImageContainer = styled.div`
	height: fit-content;
	width: 100%;
	margin-top: 50px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${Functions.col(4, 12)};
		margin-left: ${Functions.col(1, 12, 2)};
		margin-top: 0;
	}
`;

export default function ImageWithStory({
	data,
}: {
	data: { image: { image: ImageData; text: string }[]; story: string };
}) {
	return (
		<Container>
			<Story>
				<RichTextModule text={data.story} size={14} />
			</Story>
			<ImageContainer>
				<ImageWithDescription image={data.image[0].image} text={data.image[0].text} />
			</ImageContainer>
		</Container>
	);
}
