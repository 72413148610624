import React, { DOMElement, forwardRef, useEffect, useImperativeHandle } from 'react';
import styled from 'styled-components';
import Functions from '../../style/Functions';
import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import { Index } from '../../style/Variables';

const Container = styled.div`
	height: fit-content;
	width: 100%;
	position: relative;
	overflow-x: hidden;
	z-index: ${Index.mobileImageCarousel};
	${Functions.breakpoint('tablet')} {
		overflow-x: visible;
	}
`;
const Inner = styled.div`
	position: relative;
	/* white-space: nowrap; */
	left: 0;
	display: flex;
	gap: var(--gridGutter);
`;

export interface ImperativeDraggableCarousel {
	triggerHandleResize: () => void;
}

function DraggableCarousel(
	{
		children,
		className,
		activeIndex,
		dragToNewIndex,
	}: { children: Array<any>; className?: string; activeIndex: number; dragToNewIndex: (index: number) => void },
	ref: React.ForwardedRef<ImperativeDraggableCarousel>
) {
	const carouselContainer = React.useRef<HTMLDivElement>();
	let draggable: globalThis.Draggable[];

	useEffect(() => {
		initDraggable();

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useImperativeHandle(ref, () => ({
		triggerHandleResize() {
			handleResize();
		},
	}));

	const initDraggable = () => {
		gsap.registerPlugin(Draggable, InertiaPlugin);

		calcMaxBounds();

		const snapPoints = calcSnapPoints();

		draggable = Draggable.create(carouselContainer.current, {
			bounds: calcMaxBounds(),
			type: 'x',
			// intertia: true,
			throwProps: true,
			throwResistance: 10000,
			// maxDuration: 3,
			edgeResistance: 0.65,
			snap: {
				x: snapPoints,
			},
			onDrag: function (e) {
				updateIndex(calcNewIndex(this.x));
			},
			onThrowUpdate: function (e) {
				updateIndex(calcNewIndex(this.x));
			},
			// stopPropagation: true,
			// allowEventDefault: false,
		});
	};

	const calcSnapPoints = () => {
		let snapPoints = [];
		let elementWidth: number = calcElementWidth();
		let elementsOnScreen = 1;

		for (let index = 0; index < children.length - elementsOnScreen + 1; index++) {
			snapPoints.push(Math.ceil(index * -elementWidth));
		}
		return snapPoints;
	};

	const calcElementWidth = () => {
		const element: Element = carouselContainer.current.firstElementChild;

		return element.clientWidth + parseInt(getComputedStyle(document.documentElement).getPropertyValue('--gridGutter'));
	};

	const calcMaxBounds = () => {
		let elementWidth: number = calcElementWidth();
		let boundsMax: number = 0;

		boundsMax = elementWidth * children.length;

		return { minX: 0, maxX: -boundsMax, minY: 0, maxY: 0 };
	};

	const calcNewIndex = (xPos: number) => {
		let lowestDifference: number = 1000000000;
		let lowestDifIndex: number;

		const snapPoints = calcSnapPoints();

		for (let index = 0; index < snapPoints.length; index++) {
			const snapPoint = snapPoints[index];

			let difference = Math.abs(Math.abs(xPos) - Math.abs(snapPoint));

			if (difference < lowestDifference) {
				lowestDifference = difference;
				lowestDifIndex = index;
			}
		}
		return lowestDifIndex;
	};

	const updateIndex = (newIndex: number) => {
		let elementsOnScreen = 1;
		if (newIndex < 0) {
			newIndex = React.Children.count(children) - elementsOnScreen;
		} else if (newIndex >= React.Children.count(children) - elementsOnScreen + 1) {
			newIndex = 0;
		}
		// console.log('after' + newIndex);

		dragToNewIndex(newIndex);
		return newIndex;
	};

	function handleResize() {
		if (!draggable) initDraggable();
		draggable[0].applyBounds(calcMaxBounds());

		// @ts-ignore
		draggable[0].vars.snap.x = calcSnapPoints();
		let newIndex = calcNewIndex(draggable[0].x);

		updateIndex(newIndex);
		moveToIndex(newIndex, 0);
		draggable[0].update();
		// console.log(draggableRef.current.getBoundingClientRect().width);
	}

	function moveToIndex(index: number, duration: number) {
		gsap.to(carouselContainer.current, {
			x: calcSnapPoints()[index],
			duration: duration,
		});
	}

	return (
		<Container className={className}>
			<Inner ref={carouselContainer}>
				{React.Children.map(children, (child, index) => {
					return React.cloneElement(child, {});
				})}
			</Inner>
		</Container>
	);
}
export default forwardRef(DraggableCarousel);
