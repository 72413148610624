import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Colors } from '../style/Variables';
import CustomOverDetection from '../core/utils/CustomOverDetection';
import gsap, { Power1, Power2 } from 'gsap';
import { Fonts } from '../style/Variables';
import StyledButtonBig from './Buttons/StyledButtonBig';
import CustomEvents, { Events } from '../core/CustomEvents';

const Container = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 7;
	cursor: none;
`;

const Canvas = styled.canvas``;

const TextContainer = styled.div`
	position: absolute;
	z-index: 10;
	//mix-blend-mode: difference;
	color: black;
	pointer-events: none;
	white-space: nowrap;
	user-select: none;
`;

export default function MalteseCross({
	isSolid = false,
	text,
	interaction,
	isPlaying,
	cross,
	...props
}: {
	isSolid: boolean;
	text: string;
	interaction?: Function;
	isPlaying?: boolean;
	cross: boolean;
}) {
	const canvasRef = useRef<HTMLCanvasElement>();
	const containerRef = useRef<HTMLDivElement>();
	const textRef = useRef<HTMLImageElement>();

	let context = undefined;
	let xAnchor = undefined;
	let yAnchor = undefined;
	let focusPosition = { x: undefined, y: undefined };
	let mousePosition = { x: undefined, y: undefined };

	function togglePlaying() {
		interaction();
		if (isPlaying !== undefined) {
			if (isPlaying) {
				showTextAndCross();
			} else {
				hideTextAndCross();
			}
		}
	}

	const showTextAndCross = () => {
		CustomEvents.dispatch(Events.HIDEMOUSE);
		if (isPlaying === true) {
			gsap.to(textRef.current, { opacity: 1, duration: '0.1' });
			gsap.to(containerRef.current, { opacity: 1, duration: '0.1' });
			gsap.to(containerRef.current, { cursor: 'none' });
		}
	};

	const hideTextAndCross = () => {
		gsap.to(textRef.current, { opacity: 0, duration: '0.1' });
		gsap.to(containerRef.current, { opacity: 0, duration: '0.1' });
		gsap.to(containerRef.current, { cursor: 'default' });
	};

	const moveText = (x: number, y: number) => {
		if (textRef.current) {
			textRef.current.style.left = Number(x - textRef.current.getBoundingClientRect().width / 2) + 'px';
			textRef.current.style.top = Number(y - textRef.current.getBoundingClientRect().height / 2) + 'px';
		}
	};

	useEffect(() => {
		onResize();
		focusPosition = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
		mousePosition = { x: focusPosition.x, y: focusPosition.y };

		CustomOverDetection.Instance.add(canvasRef.current, showTextAndCross, recenterCross, setFocusPoint, 1);

		window.addEventListener('resize', onResize);

		gsap.ticker.add(animateCrossPosition);

		return () => {
			gsap.ticker.remove(animateCrossPosition);
			CustomOverDetection.Instance.remove(canvasRef.current);
			window.removeEventListener('resize', onResize);
		};
	}, []);

	const onResize = () => {
		setCanvasSize();
		findAnchors();
		recenterCross();
	};

	const setCanvasSize = () => {
		if (canvasRef.current) {
			context = canvasRef.current.getContext('2d');
			const ratio = window.devicePixelRatio || 1;

			const width = containerRef.current.offsetWidth;
			const height = containerRef.current.offsetHeight;
			canvasRef.current.style.width = width + 'px';
			canvasRef.current.style.height = height + 'px';

			canvasRef.current.width = Math.floor(width * ratio);
			canvasRef.current.height = Math.floor(height * ratio);
			context.scale(ratio, ratio);
		}
	};

	const findAnchors = () => {
		const width = containerRef.current.offsetWidth;
		const height = containerRef.current.offsetHeight;
		let size = height / 2;

		if (width > height) {
			size = height / 2;
		} else {
			size = width / 2;
		}

		xAnchor = [
			width / 2 - size / 2,
			width / 2 + size / 2,
			0,
			0,
			width,
			width,
			width / 2 - size / 2,
			width / 2 + size / 2,
		];
		yAnchor = [
			0,
			0,
			height / 2 - size / 2,
			height / 2 + size / 2,
			height / 2 - size / 2,
			height / 2 + size / 2,
			height,
			height,
		];
	};

	const animateCrossPosition = () => {
		// adjust speed for higher refresh monitors
		const speed = 0.35;
		const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

		focusPosition.x += (mousePosition.x - focusPosition.x) * dt;
		focusPosition.y += (mousePosition.y - focusPosition.y) * dt;

		drawCross(focusPosition.x, focusPosition.y);
		moveText(focusPosition.x, focusPosition.y);
	};

	const drawCross = (centerX, centerY) => {
		if (cross && canvasRef.current) {
			context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

			for (let i = 0; i < xAnchor.length; i = i + 2) {
				context.beginPath();
				context.moveTo(xAnchor[i], yAnchor[i]);
				context.lineTo(xAnchor[i + 1], yAnchor[i + 1]);
				context.lineTo(centerX, centerY);
				context.lineTo(xAnchor[i], yAnchor[i]);

				context.closePath();
				context.lineWidth = 2;
				context.fillStyle = Colors.yellow;
				context.strokeStyle = Colors.yellow;

				if (isSolid) context.fill();
				context.stroke();
			}
		}
	};

	const recenterCross = () => {
		CustomEvents.dispatch(Events.SHOWMOUSE);
		mousePosition.x = canvasRef.current.offsetWidth / 2;
		mousePosition.y = canvasRef.current.offsetHeight / 2;

		//hideTextAndCross();
	};

	const setFocusPoint = (x, y) => {
		mousePosition.x = x;
		mousePosition.y = y;
	};
	return (
		<Container className={props.className} ref={containerRef} onClick={togglePlaying}>
			<TextContainer ref={textRef}>
				<StyledButtonBig text={text}></StyledButtonBig>
			</TextContainer>
			<Canvas ref={canvasRef}></Canvas>
		</Container>
	);
}
