import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import EventInfoSection from '../modules/EventInfoSection/EventInfoSection';
import MagicModuleSwitcher from '../modules/MagicModuleSwitcher';
import ReadNext from '../modules/ReadNext';
import TitleHero from '../modules/TitleHero';
import { Page, PageContent } from '../style/Shared';
import NavigationMenu from '../modules/SiteNavigation/NavigationMenu';
import { Breakpoints, Index } from '../style/Variables';
import CustomEvents, { Events } from '../core/CustomEvents';
import EventInfoSectionMobile from '../modules/EventInfoSection/EventInfoSectionMobile';

const ContentWrapper = styled(PageContent)``;

const Wrapper = styled.div`
	box-sizing: border-box;
	width: 100%;
`;

const StickyWrapper = styled.div`
	position: sticky;
	top: 100px;
	width: 100%;
	z-index: 0;
`;

const HeroWrapper = styled.div`
	margin-left: calc(-1 * var(--gridMargin));

	position: relative;
	z-index: ${Index.filter};
	-webkit-transform: translate3d(0, 0, 0);
`;

export default function EventPage(props: { content?: any }) {
	const [currentMobile, setCurrentMobile] = useState<boolean>(undefined);

	const stickyRef = useRef<HTMLDivElement>(undefined);
	const wrapperRef = useRef<HTMLDivElement>(undefined);

	useEffect(() => {
		onResize();
		onBreakpointChange();

		console.log(props.content);
		window.addEventListener('resize', onResize);
		CustomEvents.listen(Events.BREAKPOINT, onBreakpointChange);

		return () => {
			CustomEvents.remove(Events.BREAKPOINT, onBreakpointChange);
			window.removeEventListener('resize', onResize);
		};
	}, []);

	const onResize = () => {
		let newHeight = stickyRef.current.offsetHeight;
		// 100 is the margin that makes it fit
		wrapperRef.current.style.height = window.innerHeight + newHeight + 100 + 'px';
	};

	const onBreakpointChange = () => {
		if (window.innerWidth <= parseInt(Breakpoints.mobile)) {
			setCurrentMobile(true);
		} else {
			setCurrentMobile(false);
		}
	};

	return (
		<Page>
			<ContentWrapper id='contentWrapper'>
				<NavigationMenu
					breadcrumb={props.content.title}
					type={props.content.modelId}
					dates={props.content.dates[0]}
					ticketLinkText={props.content.ticketLinkText}
					ticket={props.content.ticketLink}
				/>
				<Wrapper ref={wrapperRef}>
					<HeroWrapper>
						<div style={{ position: 'absolute', top: 0, left: 0, zIndex: 20 }}>
							<TitleHero
								props={{
									title: props.content.title,
									subtitle: props.content.subtitle,
									image: props.content.image,
									fullBleedImage: true,
									date: props.content.dates[0],
									isDarkTheme: props.content.heroTextTheme,
								}}
							/>
						</div>
					</HeroWrapper>
					<StickyWrapper ref={stickyRef}>
						{currentMobile ? (
							<EventInfoSectionMobile
								quote={props.content.quote}
								description={props.content.description}
								location={props.content.location}
								price={props.content.price}
								shareLinks={props.content.shareLinks}
								ticketLink={props.content.ticketLink}
								pressReleaseLink={props.content.pressReleaseLink}
								ticketLinkText={props.content.ticketLinkText}
								pressReleaseLinkText={props.content.pressReleaseLinkText}
							/>
						) : (
							<EventInfoSection
								quote={props.content.quote}
								description={props.content.description}
								location={props.content.location}
								price={props.content.price}
								shareLinks={props.content.shareLinks}
								ticketLink={props.content.ticketLink}
								pressReleaseLink={props.content.pressReleaseLink}
								ticketLinkText={props.content.ticketLinkText}
								pressReleaseLinkText={props.content.pressReleaseLinkText}
							/>
						)}
					</StickyWrapper>
				</Wrapper>
				<MagicModuleSwitcher modules={props.content ? props.content.modules : []} />
				<ReadNext
					type={props.content.modelId === 'exhibitionPage' ? 'exhibitions' : 'events'}
					entityDate={props.content.dates[0].startDate}
				/>
			</ContentWrapper>
		</Page>
	);
}
