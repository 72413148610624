import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Functions from '../style/Functions';
import Variables, { Breakpoints, Index } from '../style/Variables';
//@ts-ignore
import Kunsthal from '../assets/svgs/Kunsthal.svg';
//@ts-ignore
import Spritten from '../assets/svgs/Spritten.svg';
import CustomEvents, { Events } from '../core/CustomEvents';
import gsap, { Power1 } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

const Revealer = styled.div`
	z-index: ${Index.siteLoaderCurtain};
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	width: 100%;
	height: 100vh;

	justify-content: space-between;

	box-sizing: border-box;
	pointer-events: none;
	overflow: hidden;
`;

const Title = styled.div`
	background-color: white;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
`;

const SvgContainer = styled.div`
	height: 85%;

	display: grid;
	align-items: center;
	box-sizing: content-box;
	aspect-ratio: 0.1;
	padding-left: ${parseInt(Variables.gridMargin) / 2}px;
	padding-right: ${parseInt(Variables.gridMargin) / 2}px;
	${Functions.breakpoint(Breakpoints.tablet)} {
		padding-left: ${parseInt(Variables.gridMargin)}px;
		padding-right: ${parseInt(Variables.gridMargin)}px;
	}

	${Functions.breakpoint(Breakpoints.laptop)} {
		padding-left: ${parseInt(Variables.gridMargin) / 1}px;
		padding-right: ${parseInt(Variables.gridMargin) / 1}px;
		/* width: ${Functions.colFixed(1, 0.5)};
		min-width: ${Functions.colFixed(1, 0.5)}; */
	}

	${Functions.breakpoint(Breakpoints.ultra)} {
		/* width: ${Functions.colFixed(1)};
		min-width: ${Functions.colFixed(1)}; */
		padding-left: ${parseInt(Variables.gridMargin) / 1}px;
		padding-right: ${parseInt(Variables.gridMargin) / 1}px;
	}
`;

export const revealKey = 'didReveal';
export const revealValue = 'true';

export function setDidRevealStorage() {
	sessionStorage.setItem(revealKey, revealValue);
}

export default function CurtainRevealer() {
	const firstTitleRef = useRef<HTMLDivElement>();
	const secondTitleRef = useRef<HTMLDivElement>();
	const revealerRef = useRef<HTMLDivElement>();
	const svgRef = useRef<HTMLDivElement>();

	useEffect(() => {
		if (window.innerWidth <= parseInt(Breakpoints.mobile)) return;

		if (sessionStorage.getItem(revealKey) === revealValue) {
			ScrollTrigger.matchMedia({
				'(min-width: 1300px)': function () {
					let revealAnim = gsap.timeline({});
					revealAnim.set([firstTitleRef.current, secondTitleRef.current], {
						width: `${svgRef.current ? svgRef.current.offsetWidth : 0}px`,
					});
					revealAnim.set(revealerRef.current, {
						zIndex: 1,
					});
					return function () {
						revealAnim.kill();
					};
				},
				'(max-width: 1299px) and (min-width: 690px)': function () {
					let revealAnim = gsap.timeline({});
					revealAnim.set([firstTitleRef.current, secondTitleRef.current], {
						width: `${svgRef.current ? svgRef.current.offsetWidth : 0}px`,
					});
					revealAnim.set(revealerRef.current, {
						zIndex: 1,
					});
					return function () {
						revealAnim.kill();
					};
				},
				all: function () {
					let tl = gsap.timeline({
						scrollTrigger: {
							trigger: revealerRef.current,
							start: 'bottom 90%',
							end: 'bottom+=200px 80%',
							scrub: true,
						},
					});
					tl.to(secondTitleRef.current, {
						x: '100%',
					});
					tl.to(
						firstTitleRef.current,
						{
							x: '-100%',
						},
						'<'
					);
					return function () {
						tl.kill();
					};
				},
			});
			CustomEvents.dispatch(Events.REVEALED);
		} else {
			ScrollTrigger.matchMedia({
				'(min-width: 1300px)': function () {
					const newWidth = svgRef.current ? svgRef.current.offsetWidth : 0;
					let revealAnim = gsap.timeline({});
					revealAnim.to([firstTitleRef.current, secondTitleRef.current], {
						duration: 1.5,
						width: () => newWidth,
						delay: 1,
						ease: Power1.easeOut,
						onComplete: () => {
							firstTitleRef.current.style.width = `${svgRef.current ? svgRef.current.offsetWidth : 0}px`;
							secondTitleRef.current.style.width = `${svgRef.current ? svgRef.current.offsetWidth : 0}px`;
							revealerRef.current.style.zIndex = '1';
							setDidRevealStorage();
							CustomEvents.dispatch(Events.REVEALED);
						},
					});
					return function () {
						revealAnim.kill();
					};
				},
				'(max-width: 1299px) and (min-width: 690px)': function () {
					const newWidth = svgRef.current ? svgRef.current.offsetWidth : 0;
					let revealAnim = gsap.timeline({});
					revealAnim.to([firstTitleRef.current, secondTitleRef.current], {
						duration: 1.5,
						width: () => newWidth,
						delay: 1,
						ease: Power1.easeOut,
						onComplete: () => {
							firstTitleRef.current.style.width = `${svgRef.current ? svgRef.current.offsetWidth : 0}px`;
							secondTitleRef.current.style.width = `${svgRef.current ? svgRef.current.offsetWidth : 0}px`;
							revealerRef.current.style.zIndex = '1';
							setDidRevealStorage();
							CustomEvents.dispatch(Events.REVEALED);
						},
					});
					return function () {
						revealAnim.kill();
					};
				},
				all: function () {
					let tl = gsap.timeline({
						scrollTrigger: {
							trigger: revealerRef.current,
							start: 'bottom 90%',
							end: 'bottom+=200px 80%',
							scrub: true,
						},
					});
					tl.to(secondTitleRef.current, {
						x: '100%',
					});
					tl.to(
						firstTitleRef.current,
						{
							x: '-100%',
						},
						'<'
					);
					return function () {
						tl.kill();
					};
				},
			});
		}
	}, []);

	return (
		<Revealer ref={revealerRef}>
			<Title style={{ justifyContent: 'flex-end' }} ref={firstTitleRef}>
				<SvgContainer ref={svgRef}>
					<Kunsthal />
				</SvgContainer>
			</Title>
			<Title ref={secondTitleRef}>
				<SvgContainer>
					<Spritten />
				</SvgContainer>
			</Title>
		</Revealer>
	);
}
