import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import VideoComponent, { VideoData, ImperativeVideo } from './VideoComponent';
import MalteseCross from './MalteseCross';
import gsap from 'gsap';
import Functions from '../style/Functions';
import { Breakpoints, Colors } from '../style/Variables';
import SectionHeader from './SectionHeader';
import CrossAnimation, { ImperativeCrossAnimation } from './AnimationContainers/CrossAnimation';

const Container = styled.div<{ addMarginTop: boolean }>`
	width: 100vw;
	position: relative;
	margin-left: -(var(--gridMargin));
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	max-height: 100vh;
	aspect-ratio: 350/500;
	${Functions.breakpoint(Breakpoints.mobile)} {
		aspect-ratio: 1920/900;
	}

	margin-top: ${props => (props.addMarginTop ? 'var(--contentModuleGap)' : '0')};
`;

const VideoContainer = styled.div`
	width: 100vw;
	height: 100vh;
	z-index: 50;
	position: fixed;
	top: 0;
	left: 0;
	transform: scaleY(0);
	pointer-events: none;
	cursor: default;
	//transition: transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

const ThumbnailVideo = styled.video`
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const PromoVideo = styled(VideoComponent)`
	width: 100%;
	max-height: 100vh;
`;

const TitleContainer = styled.div`
	margin-top: var(--contentModuleGap);
	margin-bottom: 71px;
	width: 100%;
`;

export default function FullSizeVideoPlayer({
	data,
}: {
	data: { title?: string; externalVideo?: VideoData; thumbnailVideo: any; crossFilled: boolean };
}) {
	const [videoPlayerModalOpen, setVideoPlayerModalOpen] = useState(false);
	const videoRef = useRef<HTMLDivElement>();
	const videoPlayerRef = useRef<ImperativeVideo>();
	const ThumbnailVideoRef = useRef<HTMLVideoElement>();
	const [el, setEl] = useState(false);
	let animRef = useRef<ImperativeCrossAnimation>();

	const openModalPlayer = () => {
		ThumbnailVideoRef.current.pause();
		setVideoPlayerModalOpen(true);

		gsap.to(videoRef.current, {
			scaleY: 1,
			pointerEvents: 'auto',
			duration: '0.3',
			onStart: () => {
				animRef.current.triggerTransitionIn();
			},
			onComplete: () => {
				gsap.to('.react-player', {
					opacity: 1,
					duration: 0,
				});
			},
		});
		document.documentElement.style.overflow = 'hidden';
		document.body.style.overflow = 'scroll';
	};

	const closeModalPlayer = () => {
		animRef.current.triggerTransitionOut();
	};

	const onFinish = () => {
		videoPlayerRef.current.play();
		setEl(true);
	};

	const onClose = () => {
		videoPlayerRef.current.pause();
		setVideoPlayerModalOpen(false);

		gsap.to('.react-player', {
			opacity: 0,
			duration: 0,
			onComplete: () => {
				ThumbnailVideoRef.current.play();
				gsap.to(videoRef.current, { pointerEvents: 'none', scaleY: 0, duration: '0.3' });
			},
		});
		document.documentElement.style.removeProperty('overflow');
		document.body.style.removeProperty('overflow');
		var top = parseInt(document.body.style.top);
		if (top && top < 0) window.scrollTo(0, top * -1);
	};

	return (
		<>
			{data.title && (
				<TitleContainer>
					<SectionHeader title={data.title} />
				</TitleContainer>
			)}
			<Container className='fullscreenElement' addMarginTop={data.title ? false : true}>
				<MalteseCross cross={true} isSolid={data.crossFilled} text='play' interaction={openModalPlayer} />
				<ThumbnailVideo ref={ThumbnailVideoRef} src={data.thumbnailVideo.url} autoPlay loop muted playsInline />
				<VideoContainer ref={videoRef}>
					<div
						style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}
					>
						<div style={{ position: 'relative', width: '80%', height: '80%' }}>
							<CrossAnimation
								crossTransformSettings={{ angles: 'plus', center: 'center' }}
								fullscreen={false}
								ref={animRef}
								functions={{
									onCover: onClose,
									onFinish: onFinish,
								}}
								fromColor={Colors.black}
								autoReveal={false}
							/>
						</div>
					</div>
					<PromoVideo
						ref={videoPlayerRef}
						data={data.externalVideo}
						controls={true}
						muted={true}
						autoplay={false}
						closeModal={closeModalPlayer}
						eventListening={el}
					/>
				</VideoContainer>
			</Container>
		</>
	);
}
