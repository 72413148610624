import React, { DetailedHTMLProps } from 'react';
import Link from '../../core/PageSwitch/Link';
import styled from 'styled-components';
import StyledButton from './StyledButton';

interface LinkButtonProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	to?: string;
	data?: any;
}

export default function LinkButton({ to, data, ...props }: LinkButtonProps) {
	return (
		<Link data={data ? data : null} to={data ? null : to}>
			{props.children}
		</Link>
	);
}
