import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Colors, Fonts, Breakpoints } from '../style/Variables';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import Functions from '../style/Functions';

const Container = styled.div`
	//font-size: var(--fontHuge);
	font-size: var(--fontHuge);
	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: var(--fontHuge);
	}
	b {
		font-weight: normal;
		position: relative;
		display: inline-block;
	}
`;

const LineContainer = styled.div`
	overflow-wrap: break-word;
	overflow-x: visible;
	overflow-y: hidden;
	position: relative;
	&:last-child {
		//margin-bottom: 125px;
	}
`;

const LineSpan = styled.div`
	display: block;
	position: relative;
	/* transform: translateY(100%); */
	opacity: 0;
`;

export default function CustomTextReveal(props: { text: Array<any> }) {
	const elementsRef = useRef(props.text.map(() => useRef()));
	const containerRef = useRef();

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);
		const tweens = [];

		elementsRef.current.map((item, index) => {
			var tween = gsap.fromTo(
				item.current,
				{},
				{
					ease: 'ease-in',
					duration: 1,
					delay: 0.5,
					// transform: () => 'translateY(0%);',
					autoAlpha: 1,
					scrollTrigger: {
						trigger: containerRef.current,
						start: 'center-=400px center',
						end: 'center-=400px center',
						scrub: false,
					},
				}
			);
			tweens.push(tween);
		});
	}, []);

	return (
		<Container ref={containerRef} className={'textRevealContainer'}>
			{props.text.map((item, i) => (
				<LineContainer key={i}>
					<LineSpan ref={elementsRef.current[i]}>{item.lineOfText}</LineSpan>
				</LineContainer>
			))}
		</Container>
	);
}
