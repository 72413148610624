import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Colors, Fonts, Breakpoints } from '../style/Variables';
import Functions from '../style/Functions';
import HighlightCustomText from './HighlightCustomText';
import CustomTextReveal from './CustomTextReveal';

import Link from '../core/PageSwitch/Link';

const Container = styled.div`
	width: ${Functions.colFixed(12)};
	padding-top: 254px;
	font-size: var(--fontNormal);
	font-family: ${Fonts.sprittenFamily};
	color: ${Colors.black};
	b {
		font-weight: normal;
		position: relative;
		display: inline-block;
	}
`;

const HeaderContainer = styled.div`
	margin-left: 0px;
	margin-right: 0px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-left: ${Functions.colFixed(1, 1)};
		margin-right: ${Functions.colFixed(3, 1)};
	}
`;

const LinkContainer = styled.div`
	padding-top: 125px;
	padding-left: var(--gridMargin);
	padding-bottom: 147px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-left: ${Functions.colFixed(1, 1)};
	}
	//background-color: blue;
`;

const ReadMoreLink = styled(Link)`
	border: 2px solid ${Colors.yellow};
	font-size: var(--fontNormal);
	font-family: ${Fonts.monoFamily};
	padding: 18px 50px;
	transition: all 0.1s linear 0s;
	display: inline-block;
	${Functions.slideInHover(Colors.yellow)}

	&:focus,
	:hover {
		/* background-color: ${Colors.yellow}; */
		color: ${Colors.black};
	}
`;

const Text = styled.span`
	display: inline-block;
	z-index: 1;
	position: relative;
`;

const TextHighlightContainer = styled(HighlightCustomText)``;

const TextRevealContainer = styled(CustomTextReveal)``;

export default function ContentPageHeader({
	data,
}: {
	data: { text?: string; linesToReveal?: Array<any>; readMoreLink: any; readMoreTitle: string; modelId: string };
}) {
	return (
		<Container>
			<HeaderContainer>
				{data.modelId === 'contentPageHighlightHeader' && <TextHighlightContainer text={data.text} />}
				{data.modelId === 'contentPageRevealHeader' && <TextRevealContainer text={data.linesToReveal} />}
				{data.readMoreLink && (
					<LinkContainer>
						<ReadMoreLink data={data.readMoreLink}>
							<Text>→ {data.readMoreTitle || data.readMoreLink.title}</Text>
						</ReadMoreLink>
					</LinkContainer>
				)}
			</HeaderContainer>
		</Container>
	);
}
