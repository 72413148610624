import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DateFormats } from '../../core/utils/DateFormats';
import Functions from '../../style/Functions';
import { Breakpoints, Colors, Fonts, Index } from '../../style/Variables';
import CalendarView from '../Calendar/CalendarView';
import Checkbox from '../Buttons/Checkbox';
import gsap from 'gsap';
import CustomEvents, { Events } from '../../core/CustomEvents';
import CustomOverDetection from '../../core/utils/CustomOverDetection';

const Container = styled.div`
	padding-left: var(--gridMargin);
	padding-right: var(--gridMargin);
	padding-top: 40px;
	padding-bottom: 40px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-top: 80px;
		padding-bottom: 80px;
	}
`;

const FilterContainer = styled.div`
	font-family: ${Fonts.monoFamily};
	font-size: var(--fontDefaultSize);
	line-height: 120%;
	font-weight: 500;
	display: flex;
	width: 100%;
`;

const TypeContainer = styled.div`
	width: ${Functions.col(6, 12)};
	margin-left: ${Functions.col(1, 12, 1)};
`;

const DateContainer = styled.div`
	margin-left: var(--gridGutter);
	display: flex;
	align-items: center;

	gap: 6px;
`;

const CalendarViewPlacer = styled.div`
	position: fixed;
	/* transform: scaleY(0); */
	/* margin-bottom: auto; */
	overflow: hidden;
	opacity: 0;
	max-height: 0px;

	border: 1px solid ${Colors.black};
	background-color: ${Colors.white};

	// This is to avoid wrongly placed mouse over detection
	top: -10000;
`;
const ArrowIcon = styled.span`
	width: 0.5em;
	height: 0.5em;
	border-bottom: 1px solid black;
	border-left: 1px solid black;
	transform: rotate(-45deg);
	display: inline-block;
	margin-top: auto;
	margin-bottom: auto;
`;

export type FilterGroupObject = { id: string; name: string };

export interface FilterProps {
	filterArray: FilterGroupObject[];
	selectedFilters: string[];
	changeHandler: (id: string, checked: boolean) => void;
	selectedDateFilter?: Date;
	dateChangeHandler?: (date: Date) => void;
}

export default function FilterSelection({
	filterArray,
	selectedFilters,
	changeHandler,
	selectedDateFilter,
	dateChangeHandler,
}: FilterProps) {
	const calendarButtonRef = useRef<HTMLDivElement>(undefined);
	const calendarPlacerRef = useRef<HTMLDivElement>(undefined);
	const arrowRef = useRef<HTMLDivElement>(undefined);

	const [calendarOpen, setCalendarOpen] = useState<boolean>(false);

	const didMountRef = useRef(false);

	useEffect(() => {
		if (!selectedDateFilter) return;
		// setCalendarPlacement();

		window.addEventListener('resize', setCalendarPlacement);
		window.addEventListener('keydown', handleEscape);

		CustomOverDetection.Instance.add(calendarButtonRef.current, mouseOver, mouseOut, () => {}, Index.scrolledNavMenu);

		return () => {
			CustomOverDetection.Instance.remove(calendarButtonRef.current);
			window.removeEventListener('keydown', handleEscape);
			window.removeEventListener('resize', setCalendarPlacement);
		};
	}, []);

	useEffect(() => {
		if (!selectedDateFilter) return;
		if (didMountRef.current) {
			if (calendarOpen) {
				openCalendar();
				gsap.to(arrowRef.current, {
					rotate: '135deg',
					duration: 0.2,
				});
			} else {
				closeCalendar();

				gsap.to(arrowRef.current, {
					rotate: '-45deg',
					duration: 0.2,
				});
			}
		}
		didMountRef.current = true;
	}, [calendarOpen]);

	const mouseOver = () => {
		CustomEvents.dispatch(Events.HIGHLIGHTCURSOR, {});
	};

	const mouseOut = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR);
	};

	const toggleCalendarOpen = () => {
		setCalendarOpen(!calendarOpen);
	};

	const closeCalendar = () => {
		let tl = gsap.timeline();

		tl.to(calendarPlacerRef.current, {
			maxHeight: '0px',
		});
		tl.to(calendarPlacerRef.current, {
			duration: 0.2 + 's',
			autoAlpha: 0,
		});
		tl.set(calendarPlacerRef.current, {
			top: -10000,
		});
	};
	const openCalendar = () => {
		let tl = gsap.timeline();

		setCalendarPlacement();

		tl.to(calendarPlacerRef.current, {
			duration: 0.2 + 's',
			autoAlpha: 1,
		});
		tl.to(calendarPlacerRef.current, {
			maxHeight: '500px',
		});
	};

	const setCalendarPlacement = () => {
		if (!calendarOpen) return;
		if (window.innerWidth <= parseInt(Breakpoints.mobile)) {
			calendarPlacerRef.current.style.left =
				calendarButtonRef.current.offsetLeft -
				calendarPlacerRef.current.offsetWidth +
				calendarButtonRef.current.offsetWidth +
				'px';
		} else {
			calendarPlacerRef.current.style.left = calendarButtonRef.current.offsetLeft + 'px';
		}

		calendarPlacerRef.current.style.top =
			calendarButtonRef.current.offsetTop + calendarButtonRef.current.offsetHeight + 'px';
	};

	const handleEscape = event => {
		if (event.key === 'Escape') {
			setCalendarOpen(false);
		}
	};

	return (
		<Container>
			<FilterContainer>
				<TypeContainer>
					{filterArray.map(filter => (
						<Checkbox
							key={filter.id}
							label={filter.name}
							checked={selectedFilters.includes(filter.id)}
							onChange={checked => changeHandler(filter.id, checked)}
						></Checkbox>
					))}
				</TypeContainer>
				{selectedDateFilter && (
					<>
						<DateContainer ref={calendarButtonRef} onClick={toggleCalendarOpen}>
							{DateFormats.Short(selectedDateFilter)} <ArrowIcon ref={arrowRef} />
						</DateContainer>
						<CalendarViewPlacer ref={calendarPlacerRef}>
							<CalendarView handleDateChange={dateChangeHandler} selectedDateFilter={selectedDateFilter}></CalendarView>
						</CalendarViewPlacer>
					</>
				)}
			</FilterContainer>
		</Container>
	);
}
