import React from 'react';
import styled from 'styled-components';
import HTML from '../core/utils/HTML';
import Functions from '../style/Functions';
import { Breakpoints, Colors, Fonts } from '../style/Variables';
import Image, { ImageData } from './Image';
import ImageWithDescription from './ImageWithDescription';

const Container = styled.div`
	margin-top: var(--contentModuleGap);
`;

const ContentWrapper = styled.div`
	width: 100%;
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-left: ${Functions.col(1, 12, 1)};
		width: ${Functions.col(6, 12)};
	}
`;

const ImageTextContainer = styled.div`
	width: 100%;
	margin-top: 20px;
	border-top: 1px solid ${Colors.black};

	font-family: ${Fonts.monoFamily};
	font-size: var(--fontSmall);
	line-height: 140%;
`;

export default function ImageModule({ data }: { data: { image: ImageData; text: string } }) {
	return (
		<Container>
			<ContentWrapper>
				<ImageWithDescription image={data.image} text={data.text} />
			</ContentWrapper>
		</Container>
	);
}
