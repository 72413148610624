import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Link from '../core/PageSwitch/Link';
import Variables, { Breakpoints, Colors, Fonts } from '../style/Variables';
import OnHoverImage from './OnHoverImage';
import gsap, { Power1 } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Functions from '../style/Functions';
import Image from './Image';
import CustomEvents, { Events } from '../core/CustomEvents';

const Container = styled.div`
	flex-direction: column;
	aspect-ratio: 0.7;
	gap: initial;
	margin-top: var(--contentModuleGap);
	display: flex;
	width: 100%;
	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: var(--fontBigger);
		flex-direction: row;
		aspect-ratio: 5;
		justify-content: space-between;
		gap: ${Variables.gridGutter};
	}
`;

const LinkContainer = styled(Link)`
	box-sizing: border-box;
	position: relative;
	width: calc(100vw + var(--gridMargin) * 2) + 'px';
	margin-left: calc(var(--gridMargin) * -1);
	margin-right: calc(var(--gridMargin) * -1);
	box-sizing: border-box;
	height: auto;
	border-bottom: 1px solid ${Colors.yellow};
	padding: var(--gridMargin);

	display: flex;
	:first-child {
		border-top: 1px solid ${Colors.yellow};
		${Functions.breakpoint(Breakpoints.mobile)} {
			border-top: 2px solid ${Colors.yellow};
		}
	}

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding: 0px;

		border: 2px solid ${Colors.yellow};

		width: 100%;
		margin-left: initial;
		margin-right: initial;
	}
`;

const OHImage = styled(OnHoverImage)`
	display: none;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: initial;
	}
`;

const MobileImage = styled(Image)`
	height: 160px;
	max-height: 100%;
	max-width: 120px;
	img {
		max-width: 120px;
		height: 160px;
		width: auto;
		aspect-ratio: 120/160;
	}
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	}
`;

const InfoContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 14px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding: 30px;
		display: block;
		flex-direction: initial;
		justify-content: initial;
	}
	overflow: hidden;
	position: relative;

	:before {
		transition: 0.4s transform ease, 0.5s opacity;

		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background-position: bottom left;
		background-image: linear-gradient(${Colors.yellow} 50%, ${Colors.yellow} 50%);
		background-size: 150% 150%;

		width: 150%;
		height: 100%;
		opacity: 0;

		transform-origin: 0% 100%;
		transform: rotate(-5deg) translateY(-100%);
	}
	> * {
		z-index: 2;
	}
	:hover {
		:before {
			transform: rotate(0) translateY(0%);
			opacity: 1;
		}
	}
`;

const Header = styled.h2`
	position: relative;

	margin: 0;
	font-family: ${Fonts.sprittenFamily};
	font-size: var(--fontBigger);
	/* font-weight: 700; */
	${Functions.limitText(2, '1.20em')}

	${Functions.breakpoint(Breakpoints.ultra)} {
		font-size: var(--fontBigger);
	}
`;

const SubHeader = styled.h3`
	position: relative;

	margin: 0;
	font-weight: 400;
	font-size: var(--fontNotQuiteMedium);
	font-family: ${Fonts.monoFamilyLight};
	margin-top: 11px;

	${Functions.breakpoint(Breakpoints.ultra)} {
		font-size: var(--fontNormal);
	}
`;

export default function FeaturedLinksModule({ data }: { data: { links: any[] } }) {
	const containerRef = useRef<HTMLDivElement>();
	const elementsRef = useRef(data.links.map(() => useRef()));
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		onBreakpointChange();

		CustomEvents.listen(Events.BREAKPOINT, onBreakpointChange);

		return function () {
			CustomEvents.remove(Events.BREAKPOINT, onBreakpointChange);
		};
	}, []);

	const onBreakpointChange = () => {
		if (window.innerWidth <= parseInt(Breakpoints.mobile)) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);

		ScrollTrigger.saveStyles('.mobile, .desktop');
		let tl;
		let tlarray = [];
		ScrollTrigger.matchMedia({
			'(min-width: 800px)': function () {},
			'(max-width: 799px)': function () {
				elementsRef.current.forEach((item, i) => {
					let tl = gsap.timeline({
						scrollTrigger: {
							trigger: document.getElementsByClassName('animationBox')[i],
							start: '0% 50%',
							end: '100% 50%',
							toggleActions: 'play reverse play reverse',
						},
					});
					tl.to(document.getElementsByClassName('animationBox')[i], {
						backgroundColor: Colors.yellow,
						duration: 0.5,
					});
					tlarray.push(tl);
				});
			},
		});

		return () => {
			if (tl) {
				tl.kill();
				tl = undefined;
			}
			tlarray.forEach(item => {
				item.kill();
				item = undefined;
			});
		};
	}, []);

	return (
		<Container ref={containerRef}>
			{data.links.map((link, i) => {
				return (
					<LinkContainer key={i} data={link} className='animationBox'>
						{!isMobile && <OHImage imageUrl={link.image?.url}></OHImage>}

						{isMobile && <MobileImage data={link.image}></MobileImage>}
						<InfoContainer>
							<Header>{link.title}</Header>
							{link.subtitle && <SubHeader>{link.subtitle}</SubHeader>}
						</InfoContainer>
					</LinkContainer>
				);
			})}
		</Container>
	);
}
