import { ImageData } from '../../modules/Image';
import Mode from '../interface/Mode';
import Theme from '../interface/Theme';
import { defaultLang } from '../utils/Language';

const DefaultState = {
	server: {
		mode: process.env.NODE_ENV === 'production' ? Mode.PROD : Mode.DEV,
		uid: '1234',
		bugsnagKey: null,
	},
	language: defaultLang,
	global: {} as GlobalData,
	footer: {} as FooterData,
	topMenu: {} as any,
	theme: Theme.LIGHT,
};

export default DefaultState;

export interface GlobalData {
	site: string;
	seo?: any;
	facebook?: string;
	youtube?: string;
	instagram?: string;
	linkedin?: string;
	cookiePolicy?: any;
	newsletterTitle: string;
	newsletterDescription: string;
	newsletterImage: ImageData;
}

export interface FooterData {
	newsletterTitle: string;
	newsletterDescription?: string;
	items: Array<{ title: string; text: string; buttonUrl: string; buttonLink: any }>;
	rights: string;
	address: string;
	openingHours: string;
	contact: string;
}
