import React, { useEffect, useState } from 'react';
import ContentPage from './pages/ContentPage';
import ArticlePage from './pages/ArticlePage';
import SiteLoader from './modules/SiteLoader';
import PageSwitch from './core/PageSwitch/PageSwitch';
import Page from './core/PageSwitch/Page';
import CustomEvents, { Events } from './core/CustomEvents';
import ErrorPage from './pages/ErrorPage';
import { SiteContent } from './style/Shared';
import Footer from './modules/Footer/Footer';
import EventPage from './pages/EventPage';
import ListPage from './pages/ListPage';

export default function RouterSwitch({ data }: { data?: any }) {
	const [content, setContent] = useState<any>(data ? data : {});

	const newPage = async (e: CustomEvent) => {
		setContent(e.detail.content);
	};

	useEffect(() => {
		CustomEvents.listen(Events.NEWPAGE, newPage);

		return function () {
			CustomEvents.remove(Events.NEWPAGE, newPage);
		};
	}, []);

	return (
		<>
			<SiteLoader />
			<SiteContent>
				<PageSwitch content={content} pageType={content ? content.modelId : 'not_found'} fallback={ErrorPage}>
					<Page type={'landingPage'} component={ContentPage} />
					<Page type={'contentPage'} component={ContentPage} />
					<Page type={'articlePage'} component={ArticlePage} />
					<Page type={'eventPage'} component={EventPage} />
					<Page type={'articleListPage'} component={ListPage} />
					<Page type={'listPage'} component={ListPage} />
					<Page type={'exhibitionPage'} component={EventPage} />
				</PageSwitch>
				<Footer />
			</SiteContent>
		</>
	);
}
