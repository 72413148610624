import React, { useEffect, useRef, useState } from 'react';
import CustomEvents, { Events } from '../core/CustomEvents';
import BrowserDetect from '../core/utils/BrowserDetect';
import CrossAnimation, { ImperativeCrossAnimation } from './AnimationContainers/CrossAnimation';

export const revealKey = 'didReveal';
export const revealValue = 'true';

export default function SiteLoader() {
	let animRef = useRef<ImperativeCrossAnimation>();
	let eventRef = useRef<CustomEvent>();
	const [isMouseSupported, setIsMouseSupported] = useState(true);

	useEffect(() => {
		if (BrowserDetect.isMobile()) {
			setIsMouseSupported(false);
		}

		if (BrowserDetect.isTablet()) {
			setIsMouseSupported(false);
		}

		CustomEvents.listen(Events.LOADINGPAGE, loadingPage);
		CustomEvents.listen(Events.NEWPAGE, newPage);
		newPage(undefined);

		return function () {
			CustomEvents.remove(Events.LOADINGPAGE, loadingPage);
			CustomEvents.remove(Events.NEWPAGE, newPage);
		};
	}, []);

	const loadingPage = () => {
		animRef.current.triggerTransitionOut();
	};

	const newPage = (e: CustomEvent) => {
		eventRef.current = e?.detail;
		if (location.pathname === '/') {
			if (sessionStorage.getItem(revealKey) === revealValue) {
				animRef.current.triggerTransitionIn(e);
			} else {
				animRef.current.fadeOut(e);
			}
		} else {
			animRef.current.triggerTransitionIn(e);
		}
	};

	const onCover = () => {
		CustomEvents.dispatch(Events.TRANSITIONOUT);
	};

	const onFinish = () => {
		CustomEvents.dispatch(Events.TRANSITIONDONE, eventRef.current);
	};

	const onReveal = () => {
		CustomEvents.dispatch(Events.TRANSITIONIN);
	};

	return (
		<CrossAnimation
			crossTransformSettings={{ angles: 'symmetric', center: isMouseSupported ? 'mouse' : 'center' }}
			fullscreen={true}
			functions={{ onCover: onCover, onFinish: onFinish, onShow: onReveal }}
			autoReveal={false}
			revealDelay={0.7}
			ref={animRef}
		></CrossAnimation>
	);
}
