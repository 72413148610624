import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import CustomEvents, { Events } from '../../core/CustomEvents';
import CustomOverDetection from '../../core/utils/CustomOverDetection';
import { Colors, Fonts, Index } from '../../style/Variables';

const DayItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	align-self: center;
	font-family: ${Fonts.monoFamily};

	font-size: var(--fontDefaultSize);
	line-height: 120%;
	text-align: center;
	color: ${Colors.black};
	pointer-events: none;

	width: 2.5em;
	height: 2.5em;

	text-transform: capitalize;
`;

const DayItemClickable = styled(DayItem).attrs({ as: 'button' })<{
	selected: boolean;
	isBeforeCurrentDay: boolean;
	hasNoNumber: boolean;
}>`
	cursor: none;
	${props => (props.selected ? `background-color: ${Colors.black}; color: ${Colors.white};` : ``)}
	${props => (props.isBeforeCurrentDay ? `opacity: 0.5; pointer-events: none;` : `pointer-events: auto;`)}
    ${props => (props.hasNoNumber ? `pointer-events: none;` : ``)}
`;

export default function DayItemButton({
	selected,
	isBeforeCurrentDay,
	hasNoNumber,
	day,
	onClick,
	isButton = true,
}: {
	selected?: boolean;
	isBeforeCurrentDay?: boolean;
	hasNoNumber?: boolean;
	day?: Number | string;
	onClick?: () => void;
	isButton?: boolean;
}) {
	const buttonRef = useRef<HTMLDivElement>();

	useEffect(() => {
		if (isButton) {
			CustomOverDetection.Instance.add(buttonRef.current, mouseOver, mouseOut, () => {}, Index.scrolledNavMenu);
		}

		return () => {
			CustomOverDetection.Instance.remove(buttonRef.current);
		};
	}, []);

	const mouseOver = () => {
		CustomEvents.dispatch(Events.HIGHLIGHTCURSOR, {});
	};

	const mouseOut = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR);
	};

	return isButton ? (
		<DayItemClickable
			ref={buttonRef}
			hasNoNumber={hasNoNumber}
			selected={selected}
			isBeforeCurrentDay={isBeforeCurrentDay}
			onClick={onClick}
		>
			{day}
		</DayItemClickable>
	) : (
		<DayItem>{day}</DayItem>
	);
}
