import React, { useContext, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Fonts, Colors, Breakpoints } from '../style/Variables';
import gsap from 'gsap';
import MalteseCross from './MalteseCross';
import Functions from '../style/Functions';

const Container = styled.div`
	width: 100vw;
	position: relative;
	margin-left: -(var(--gridMargin));
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	max-height: 100vh;
	background-color: #cccccc;
	margin-top: var(--contentModuleGap);

	aspect-ratio: 350/500;
	${Functions.breakpoint(Breakpoints.mobile)} {
		aspect-ratio: 1920/900;
	}
`;

const Cross = styled(MalteseCross)`
	div div {
		display: inline-block;
		white-space: pre-line;
		text-align: center;
		${Functions.breakpoint(Breakpoints.mobile)} {
			display: block;
			white-space: initial;
		}
	}
`;

export default function FullScreenDownload({ data }: { data: { text: string; downloadableContent: any } }) {
	const downloadContent = () => {
		window.open(data.downloadableContent.url);
	};

	return (
		<Container className='fullscreenElement'>
			<Cross isSolid={true} text={data.text} cross={true} interaction={downloadContent} />
		</Container>
	);
}
