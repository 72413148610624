import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DateFormats } from '../../core/utils/DateFormats';
import { Colors } from '../../style/Variables';
import CalendarDayComponent from './CalendarDayContainer';

const Container = styled.div`
	padding: 30px;
	box-sizing: border-box;
`;

const YearSelecter = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
`;

const MonthContainer = styled.div`
	grid-column: 3 / span 3;
	text-align: center;
`;

const ForwardArrow = styled.button``;

const BackArrow = styled.button`
	grid-column: 2;
`;

export default function CalendarView({
	handleDateChange,
	selectedDateFilter,
}: {
	handleDateChange: (date: Date) => void;
	selectedDateFilter: Date;
}) {
	const [selectedMonth, setSelectedMonth] = useState<number>(selectedDateFilter.getMonth());
	const [selectedYear, setSelectedYear] = useState<number>(selectedDateFilter.getFullYear());

	const didMountRef = useRef(false);

	useEffect(() => {
		if (didMountRef.current) {
			const newDate = new Date(selectedYear, selectedMonth);

			handleDateChange(newDate);
		}

		didMountRef.current = true;
	}, [selectedMonth]);

	const incrementMonth = () => {
		if (selectedMonth >= 11) {
			setSelectedMonth(0);
			setSelectedYear(selectedYear + 1);
		} else {
			setSelectedMonth(selectedMonth + 1);
		}
	};

	const decrementMonth = () => {
		if (selectedYear === new Date().getFullYear() && selectedMonth === new Date().getMonth()) {
			return;
		}

		if (selectedMonth <= 0) {
			setSelectedMonth(11);
			setSelectedYear(selectedYear - 1);
		} else {
			setSelectedMonth(selectedMonth - 1);
		}
	};

	return (
		<Container>
			<YearSelecter>
				<BackArrow onClick={decrementMonth}>←</BackArrow>
				<MonthContainer>
					{DateFormats.Month(new Date(selectedYear, selectedMonth, 0), 'short') + ' ' + selectedYear}
				</MonthContainer>
				<ForwardArrow onClick={incrementMonth}>→</ForwardArrow>
			</YearSelecter>
			<CalendarDayComponent
				currentActiveDay={selectedDateFilter.getDate()}
				year={selectedYear}
				month={selectedMonth}
				handleDateChange={handleDateChange}
			></CalendarDayComponent>
		</Container>
	);
}
