import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Colors, Fonts, Breakpoints } from '../style/Variables';
import Functions from '../style/Functions';
import HighlightCustomText from './HighlightCustomText';

const Container = styled.div`
	margin-top: var(--contentModuleGap);
	width: ${Functions.colFixed(12)};
	font-family: ${Fonts.sprittenFamily};
	color: ${Colors.black};
	b {
		font-weight: normal;
		position: relative;
		display: inline-block;
	}
`;

const TextContainer = styled.div`
	position: relative;
	width: 100%;
	font-size: var(--fontMedium);
	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: var(--fontMediumBig);
		width: ${Functions.colFixed(8)};
	}
`;

const TextHighlightContainer = styled(HighlightCustomText)`
	background-color: yellow;
`;

export default function HighlightedTextModule({ data }: { data: { text: string } }) {
	return (
		<Container>
			<TextContainer>{data.text && <TextHighlightContainer text={data.text} />}</TextContainer>
		</Container>
	);
}
