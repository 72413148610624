import React from 'react';
import styled from 'styled-components';
import Link from '../core/PageSwitch/Link';
import { DateFormats } from '../core/utils/DateFormats';
import { getWord } from '../core/utils/Language';
import Functions from '../style/Functions';
import Variables, { Breakpoints, Colors, Fonts } from '../style/Variables';
import CommonWords from '../types/CommonWords';
import Image from './Image';
import TypeTag from './TypeTag';

const HoverContainer = styled.div``;

const Container = styled(Link)`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: var(--gridGutter);

	h2 {
		overflow: hidden;
		position: relative;
		:before {
			transition: 0.4s transform ease, 0.5s opacity;

			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			background-position: bottom left;
			background-image: linear-gradient(${Colors.yellow} 50%, ${Colors.yellow} 50%);
			background-size: 150% 150%;

			width: 150%;
			height: 100%;
			opacity: 0;

			transform-origin: 0% 100%;
			transform: rotate(-5deg) translateY(-100%);
		}
		> * {
			z-index: 2;
		}
	}

	:hover {
		h2 {
			:before {
				transform: rotate(0) translateY(0%);
				opacity: 1;
			}
		}
	}

	${Functions.breakpoint(Breakpoints.mobile)} {
		flex-direction: row;
	}
`;

const ImageContainer = styled(Image)`
	width: 100%;
	aspect-ratio: 1.7/1;
	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${Functions.col(7)};
	}
`;

const InfoContainer = styled.div`
	padding-top: 0;
	width: 100%;
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-top: 60px;
		width: ${Functions.col(4)};
	}
`;

const DateContainer = styled.p`
	margin: 0;
	font-family: ${Fonts.monoFamily};
	font-size: var(--fontDefaultSize);
	line-height: 120%;
	font-weight: 400;
`;

const Title = styled.h2`
	transition: background-color 0.25s;

	margin: 0;
	font-family: ${Fonts.sprittenFamily};
	/* font-weight: 700; */
	font-size: var(--fontBigger);
	line-height: 120%;
	margin-top: 20px;
	margin-bottom: 20px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-bottom: 40px;
	}
`;

export default function DetailedThumbnailLink({ data }) {
	return (
		<Container data={data}>
			<ImageContainer src={data.image.url} />
			<InfoContainer>
				<DateContainer>
					{DateFormats.Short(new Date(data.dates[0].startDate))}-{DateFormats.Short(new Date(data.dates[0].endDate))}
				</DateContainer>

				<Title>
					<span style={{ position: 'relative' }}>{data.title}</span>
				</Title>

				{data.tag && <TypeTag tag={data.tag.name} type={getWord(CommonWords.exhibition)} />}
			</InfoContainer>
		</Container>
	);
}
