import React from 'react';
import ModuleSwitcherCore, { Module } from '../core/ModuleSwitcherCore';
import RevealHero from './RevealHero';
import BigTextWithReveal from './BigTextWithReveal';
import FeaturedLinksModule from './FeaturedLinksModule';
import HistoryCarousel from './HistoryCarousel';
import EntityList from './EntityList';
import DetailedLinksSection from './DetailedLinksSection';
import FullSizeVideoPlayer from './FullSizeVideoPlayer';
import ImageModule from './ImageModule';
import ImageCarousel from './ImageCarousel';
import ImageWithStory from './ImageWithStory';
import ImageFlow from './ImageFlow/ImageFlow';
import FullscreenImage from './FullscreenImage';
import FullScreenDownload from './FullScreenDownload';
import DownloadableList from './DownloadableList';
import ExpandableList from './ExpandableList';
import EmployeeList from './EmployeeList';
import ContentPageHeader from './ContentPageHeader';
import HighlightedTextModule from './HighlightedTextModule';
import TextModule from './TextModule';

export default function MagicModuleSwitcher(props: { modules: Array<any> }) {
	return (
		<ModuleSwitcherCore modules={props.modules}>
			{<Module modelId={'revealHero'} component={RevealHero} />}
			{<Module modelId={'bigTextWithReveal'} component={BigTextWithReveal} />}
			{<Module modelId={'featuredLinksModule'} component={FeaturedLinksModule} />}
			{<Module modelId={'historyCarousel'} component={HistoryCarousel} />}
			{<Module modelId={'listOfEntity'} component={EntityList} />}
			{<Module modelId={'detailedLinksModule'} component={DetailedLinksSection} />}
			{<Module modelId={'videoPlayer'} component={FullSizeVideoPlayer} />}
			{<Module modelId={'image'} component={ImageModule} />}
			{<Module modelId={'imageCarousel'} component={ImageCarousel} />}
			{<Module modelId={'imageWithStory'} component={ImageWithStory} />}
			{<Module modelId={'imageFlow'} component={ImageFlow} />}
			{<Module modelId={'fullscreenImage'} component={FullscreenImage} />}
			{<Module modelId={'fullScreenDownload'} component={FullScreenDownload} />}
			{<Module modelId={'downloadableList'} component={DownloadableList} />}
			{<Module modelId={'expandableList'} component={ExpandableList} />}
			{<Module modelId={'employeeList'} component={EmployeeList} />}
			{<Module modelId={'contentPageRevealHeader'} component={ContentPageHeader} />}
			{<Module modelId={'contentPageHighlightHeader'} component={ContentPageHeader} />}
			{<Module modelId={'highlightTextModule'} component={HighlightedTextModule} />}
			{<Module modelId={'textModule'} component={TextModule} />}
		</ModuleSwitcherCore>
	);
}
