import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CustomEvents, { Events } from '../core/CustomEvents';
import CustomOverDetection from '../core/utils/CustomOverDetection';
import HTML from '../core/utils/HTML';
import Functions from '../style/Functions';
import { Breakpoints, Fonts } from '../style/Variables';
import Carousel, { ImperativeCarousel } from './Carousel/Carousel';
import Image, { ImageData } from './Image';
import RichTextModule from './RichTextModule';
import gsap from 'gsap';
import DraggableCarousel from './Carousel/DraggableCarousel';

const Container = styled.div`
	margin-top: var(--contentModuleGap);
`;

const PointerCatcher = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0.5;

	display: flex;
`;

const PointerCatcherItem = styled.div`
	flex: 1 0;
`;

const StoryContainer = styled.div`
	margin-top: 70px;

	width: 100%;
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-left: ${Functions.col(1, 12, 1)};
		width: ${Functions.col(5, 12)};
		margin-top: 150px;
	}
`;

const StyledCarousel = styled(Carousel)``;

const CarouselWrapper = styled.div`
	position: relative;
`;

const CarouselEntry = styled(Image)`
	flex: 1 0 ${Functions.col(9, 12)};
`;

const InfoContainer = styled.div`
	margin-top: 30px;

	display: flex;
	justify-content: space-between;
	width: 100%;
	/* margin-left: ${Functions.col(1, 12, 1)}; */
	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${Functions.col(6, 12)};
		margin-left: ${Functions.col(1, 12, 1)};
	}
`;

const IndicatorContainer = styled.div`
	position: relative;
	display: flex;
	font-family: ${Fonts.monoFamilyLight};
	font-size: 12px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: var(--fontNotQuiteMedium);

		font-family: ${Fonts.sprittenFamily};
	}
`;

const ImageText = styled.p`
	margin: 0;
	font-family: ${Fonts.monoFamilyLight};
	font-size: var(--fontSmall);

	line-height: 140%;
	p {
		margin: 0;
	}
`;

export default function ImageCarousel({
	data,
}: {
	data: { images: { image: ImageData; text: string }[]; text: string };
}) {
	const carouselRef = useRef<ImperativeCarousel>();
	const leftTriggerRef = useRef<HTMLDivElement>();
	const rightTriggerRef = useRef<HTMLDivElement>();
	const triggerRef = useRef<HTMLDivElement>();
	const indexRef = useRef<HTMLSpanElement | HTMLParagraphElement>();
	const textRef = useRef<HTMLDivElement>();

	const [activeIndex, setActiveIndex] = useState(0);
	const [visibleIndex, setVisibleIndex] = useState(0);

	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		onBreakpointChange();
		CustomOverDetection.Instance.add(leftTriggerRef.current, mouseOverLeft, mouseOut, () => {}, 1);
		CustomOverDetection.Instance.add(rightTriggerRef.current, mouseOverRight, mouseOut, () => {}, 1);
		CustomEvents.listen(Events.BREAKPOINT, onBreakpointChange);

		return () => {
			CustomOverDetection.Instance.remove(leftTriggerRef.current);
			CustomOverDetection.Instance.remove(rightTriggerRef.current);
			CustomEvents.remove(Events.BREAKPOINT, onBreakpointChange);
		};
	}, []);

	useEffect(() => {
		activeIndex > visibleIndex ? nextAnim() : prevAnim();
	}, [activeIndex]);

	const onBreakpointChange = () => {
		if (window.innerWidth <= parseInt(Breakpoints.mobile)) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	const incrementActiveIndex = () => {
		if (activeIndex < data.images.length - 1) {
			setActiveIndex(activeIndex + 1);
		} else {
			setActiveIndex(0);
		}
	};

	const decrementActiveIndex = () => {
		if (activeIndex > 0) {
			setActiveIndex(activeIndex - 1);
		} else {
			setActiveIndex(data.images.length - 1);
		}
	};

	const nextAnim = () => {
		let tl = gsap.timeline();
		tl.to([indexRef.current, textRef.current], {
			overwrite: true,
			duration: 0.2,
			y: 5,
			autoAlpha: 0,
			onComplete: () => {
				setVisibleIndex(activeIndex);
			},
		});
		tl.set([indexRef.current, textRef.current], {
			y: -5,
		});
		tl.to([indexRef.current, textRef.current], {
			duration: 0.2,
			y: 0,
			autoAlpha: 1,
		});
	};

	const prevAnim = () => {
		let tl = gsap.timeline();
		tl.to([indexRef.current, textRef.current], {
			overwrite: true,
			duration: 0.2,
			y: -5,
			autoAlpha: 0,
			onComplete: () => {
				setVisibleIndex(activeIndex);
			},
		});
		tl.set([indexRef.current, textRef.current], {
			y: 5,
		});
		tl.to([indexRef.current, textRef.current], {
			duration: 0.2,
			y: 0,
			autoAlpha: 1,
		});
	};

	const mouseOverLeft = () => {
		CustomEvents.dispatch(Events.HIGHLIGHTCURSOR, { text: '←', fontSize: 32 });
	};

	const mouseOverRight = () => {
		CustomEvents.dispatch(Events.HIGHLIGHTCURSOR, { text: '→', fontSize: 32 });
	};

	const mouseOut = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR);
	};

	return (
		<Container>
			{isMobile ? (
				<CarouselWrapper>
					<DraggableCarousel activeIndex={activeIndex} dragToNewIndex={setActiveIndex}>
						{data.images.map((image, index) => (
							<CarouselEntry key={index} data={image.image} />
						))}
					</DraggableCarousel>
				</CarouselWrapper>
			) : (
				<CarouselWrapper>
					<StyledCarousel ref={carouselRef} activeIndex={activeIndex}>
						{data.images.map((image, index) => (
							<CarouselEntry key={index} data={image.image} />
						))}
					</StyledCarousel>
					<PointerCatcher ref={triggerRef}>
						<PointerCatcherItem ref={leftTriggerRef} onClick={decrementActiveIndex} />
						<PointerCatcherItem ref={rightTriggerRef} onClick={incrementActiveIndex} />
					</PointerCatcher>
				</CarouselWrapper>
			)}

			<InfoContainer>
				<IndicatorContainer>
					<div ref={indexRef}>
						{visibleIndex < 9 ? 0 : ''}
						{visibleIndex + 1}
					</div>{' '}
					&nbsp;/ {data.images.length < 9 ? 0 : ''}
					{data.images.length}
				</IndicatorContainer>
				<ImageText
					ref={textRef}
					dangerouslySetInnerHTML={{ __html: HTML.clean(data.images[visibleIndex].text) }}
				></ImageText>
			</InfoContainer>
			<StoryContainer>
				<RichTextModule text={data.text} size={14} />
			</StoryContainer>
		</Container>
	);
}
