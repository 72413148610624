import React, { useContext, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Colors, Fonts, Index, Breakpoints } from '../../style/Variables';
import FacebookIcon from '../../assets/icons/FacebookIcon';
import YoutubeIcon from '../../assets/icons/YoutubeIcon';
import InstagramIcon from '../../assets/icons/InstagramIcon';
import context from '../../core/context';
import Link from '../../core/PageSwitch/Link';
import Loader from '../../core/Loader';
import FooterInformation from './FooterInformation';
import CustomEvents, { Events } from '../../core/CustomEvents';
import CustomOverDetection from '../../core/utils/CustomOverDetection';
import Functions from '../../style/Functions';
import { Language } from '../../core/utils/Language';
import FullSizeVideoPlayer from '../FullSizeVideoPlayer';

const FooterContainer = styled.div<{ useMargin: boolean }>`
	background-color: ${Colors.black};
	width: 100%;
	//This is just for now, to make the site big enough to make animations trigger.
	//height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: stretch;
	position: relative;
	z-index: ${Index.footer};
	overflow: hidden;
	color: white;
	font-size: var(--fontHuge);
	height: fit-content;
	margin-top: ${props => (props.useMargin ? 'var(--contentModuleGap)' : '-1px')};

	${Functions.breakpoint(Breakpoints.mobile)} {
		height: fit-content;
	}
`;

const MailSubscribe = styled.form`
	padding-top: 124px;
	font-family: ${Fonts.sprittenFamily};
	text-align: center;

	margin-bottom: 104px;

	display: flex;
	flex-direction: column;

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-bottom: 208px;
	}
`;

const SignUp = styled.div`
	font-size: var(--fontNormal);
	padding-top: 54px;
	font-family: ${Fonts.monoFamily};
`;

const SignUpForm = styled.input<{ align: Boolean }>`
	-webkit-appearance: none;
	border-radius: 0;
	background-color: ${Colors.black};
	border: 0px;
	border-bottom: 1px solid #ffffff;
	color: ${Colors.white};
	font-size: ${Fonts.small};

	font-family: ${Fonts.monoFamily};
	padding-left: calc(var(--gridMargin) * -1);
	padding-right: calc(var(--gridMargin) * -1);
	text-align: ${props => (props.align ? 'center' : 'left')};
	width: 100%;

	:focus {
		outline: none;
	}

	padding-top: 0;
	::placeholder {
		/* color: transparent; */
	}
	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: var(--fontNormal);

		padding-left: initial;
		padding-right: initial;
		padding: 0px 5px 10px 5px;
		::placeholder {
			color: ${Colors.white};
		}
	}
`;

const SignUpButton = styled.button<{ shouldDisplay: boolean }>`
	color: ${Colors.white};
	font-family: ${Fonts.monoFamily};
	font-size: var(--fontNormal);

	display: ${props => (props.shouldDisplay === true ? 'initial' : 'none')};

	transition: all 0.2s ease-in-out 0s;
	:hover {
		color: ${Colors.darkGrey};
		cursor: none;
	}

	position: absolute;
	top: 0;
	right: 5px;
	padding-left: 10px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		background-color: ${Colors.black};
	}
`;

const SignUpContainer = styled.div`
	margin-top: 93px;
	align-self: center;
	display: flex;
	position: relative;
	width: calc(100% - var(--gridMargin) * 2);

	${Functions.breakpoint(Breakpoints.mobile)} {
		width: 33%;
		margin-top: 120px;
	}
`;

export default function Footer() {
	const { state } = useContext(context);
	const [enteredEmail, setEnteredEmail] = useState(String);
	const [hasSubbed, setHasSubbed] = useState(false);
	const SignUpField = useRef<HTMLInputElement>();
	const SignUpButtonRef = useRef();
	const containerRef = useRef<HTMLDivElement>();
	const [noMargin, setNoMargin] = useState<boolean>(true);

	function updateEnteredEmail(e) {
		setEnteredEmail(e.target.value);
	}

	async function signUpActivation(e) {
		e.preventDefault();
		var event = await Loader.get(`/api/newsletter?email=${e.target[0].value}`);
		var json = JSON.parse(event);
		if (json.data.success) {
			setHasSubbed(true);
			SignUpField.current.value = 'Thank you for signing up.';
		} else if (json.data.e) {
			SignUpField.current.value = json.data.e;
		}
	}

	useEffect(() => {
		CustomOverDetection.Instance.add(SignUpButtonRef.current, mouseOver, mouseOut, () => {}, Index.signUpButton);
		CustomOverDetection.Instance.add(SignUpField.current, mouseOver, mouseOut, () => {}, Index.signUpButton);

		// Checks if the last content element is a fullscreenElement, if so no margin should be allowed
		CustomEvents.listen(Events.TRANSITIONDONE, handleFooterMargin);
		handleFooterMargin();

		return () => {
			CustomOverDetection.Instance.remove(SignUpButtonRef.current);
			CustomOverDetection.Instance.remove(SignUpField.current);
			CustomEvents.remove(Events.TRANSITIONDONE, handleFooterMargin);
		};
	}, []);

	const handleFooterMargin = () => {
		setNoMargin(document.getElementById('contentWrapper')?.lastElementChild.className.includes('fullscreenElement'));
	};

	const mouseOver = () => {
		CustomEvents.dispatch(Events.HIGHLIGHTCURSOR, {});
	};

	const mouseOut = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR);
	};

	const signUpCheck = () => {
		//console.log(enteredEmail.length > 0 && !hasSubbed);
		return enteredEmail.length > 0 && !hasSubbed;
	};

	return (
		<FooterContainer ref={containerRef} useMargin={!noMargin}>
			<MailSubscribe onSubmit={signUpActivation}>
				{Language.getInstance().isDanish() ? (
					<>
						Tilmeld dig <br /> vores nyhedsbrev
					</>
				) : (
					<>
						Subscribe to <br /> our newsletter
					</>
				)}
				<SignUpContainer>
					<SignUpForm
						placeholder='Email'
						align={hasSubbed}
						ref={SignUpField}
						disabled={hasSubbed}
						type='email'
						onChange={e => updateEnteredEmail(e)}
					></SignUpForm>
					<SignUpButton shouldDisplay={signUpCheck()} ref={SignUpButtonRef} disabled={hasSubbed} type='submit'>
						Submit
					</SignUpButton>
				</SignUpContainer>
			</MailSubscribe>
			<FooterInformation longFormat={true} />
		</FooterContainer>
	);
}
