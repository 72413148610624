import React, { useState, useEffect } from 'react';
import styled, { withTheme } from 'styled-components';
import Functions from '../style/Functions';
import { Fonts } from '../style/Variables';
import { DateFormats } from '../core/utils/DateFormats';
import { Colors } from '../style/Variables';
import OnHoverImage from './OnHoverImage';
import Link from '../core/PageSwitch/Link';
import TypeTag from './TypeTag';
import { loadArchivedExhibitions, loadArticles, loadEvents, loadExhibitions } from '../core/utils/EntityLoader';
import SectionHeader from './SectionHeader';
import { getWord } from '../core/utils/Language';
import CommonWords from '../types/CommonWords';
import { FilterProps } from './SiteNavigation/FilterSelection';
import Image from './Image';
import { Breakpoints } from '../style/Variables';

const Container = styled.div`
	margin-top: var(--contentModuleGap);
	width: 100vw;
	position: relative;
	font-family: ${Fonts.sprittenFamily};
`;

const TitleContainer = styled.div`
	margin: 0px var(--gridMargin);
	padding-bottom: 60px;
`;

const ListContainer = styled.div`
	width: 100%;
`;

const DecorationTag = styled.div`
	display: flex;
	align-content: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;

	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${Functions.colFixed(3, 0)};
	}
`;

const EntityContainer = styled(Link)`
	width: 100%;
	position: relative;
	min-height: auto;
	display: flex;

	border-bottom: 1px solid black;
	transition: 0.2s all ease 0s;
	padding: var(--gridMargin);

	:first-child {
		border-top: 1px solid black;
	}

	${Functions.breakpoint(Breakpoints.mobile)} {
		min-height: 420px;
		padding: 0px;
	}

	:hover {
		${DecorationTag} {
			> div {
				border-color: white;
			}
		}
		color: ${Colors.white};
	}
`;

const EntityTitle = styled.div`
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	// exception with the font size
	font-size: ${Fonts.notQuiteMediuem};

	${Functions.breakpoint(Breakpoints.mobile, 'max')} {
		${Functions.limitText(2)};
		margin-bottom: 20px;
	}

	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${Functions.colFixed(6, 0)};
		margin-right: var(--gridGutter);
		font-size: var(--fontHuge);
		margin-left: ${Functions.colFixed(1, 2)};
	}

	${Functions.limitText(2)};
	align-self: center;
`;

const EntityDate = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	font-size: ${Fonts.smaller};
	white-space: pre-wrap;
	font-family: ${Fonts.monoFamilyLight};
	text-transform: lowercase;
	padding-bottom: 15px;
	:first-letter {
		text-transform: uppercase;
	}

	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${Functions.colFixed(3, 0)};
		margin-left: var(--gridMargin);
		font-family: ${Fonts.sprittenFamily};
		text-transform: capitalize;
		padding-bottom: 0px;
		font-size: var(--fontBig);
	}
`;

const HoverImage = styled(OnHoverImage)`
	display: none;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: block;
	}
`;

const EntitySubTitle = styled.div`
	font-size: var(--fontNormal);

	font-family: ${Fonts.monoFamily};
	padding-top: 15px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-top: 0px;
	}
`;

const MobileImage = styled(Image)`
	max-height: 100%;
	width: auto;
	img {
		max-width: 120px;
		height: 160px;
		width: auto;
		aspect-ratio: 120/160;
	}
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	}
`;

const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 14px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		display: flex;
		flex-direction: initial;
		justify-content: initial;
		padding-left: 0px;
	}
`;

const HoverContainer = styled.div`
	width: 100%;
	height: auto;
	overflow: hidden;
	position: relative;
	display: flex;
	align-items: center;

	:before {
		transition: 0.4s transform ease, 0.5s opacity;

		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background-position: bottom left;
		background-image: linear-gradient(${Colors.black} 50%, ${Colors.black} 50%);
		background-size: 150% 150%;

		width: 150%;
		height: 100%;
		opacity: 0;

		transform-origin: 0% 100%;
		transform: rotate(-5deg) translateY(-100%);
	}
	> * {
		z-index: 2;
	}
	:hover {
		:before {
			transform: rotate(0) translateY(0%);
			opacity: 1;
		}
	}
`;

export default function EntityList({
	data,
}: {
	data: {
		numberOfEntitiesShown: number;
		selectedEntities: 'news' | 'exhibitions';
		archivedEntities: boolean;
		title: string;
		shouldShowAmounts?: boolean;
		selectedFilterProps?: FilterProps;
		headerLink?: any;
	};
}) {
	const [entityList, setEntities] = useState([]);
	const [isMobile, setIsMobile] = useState(false);
	const [amount, setAmount] = useState(0);

	useEffect(() => {
		loadData();
		if (window.innerWidth <= parseInt(Breakpoints.mobile)) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, []);

	useEffect(() => {
		loadData();
	}, [data.selectedFilterProps]);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [entityList]);

	async function loadData() {
		const dateFilter = data.selectedFilterProps ? data.selectedFilterProps.selectedDateFilter : undefined;
		const typeFilter = data.selectedFilterProps ? data.selectedFilterProps.selectedFilters : undefined;

		if (data.selectedEntities === 'news') {
			var json = await loadArticles(data.numberOfEntitiesShown, typeFilter);

			if (json.data?.results) {
				setEntities(json.data?.results);
			}
		} else if (data.selectedEntities === 'exhibitions') {
			var json = data.archivedEntities
				? await loadArchivedExhibitions(data.numberOfEntitiesShown)
				: await loadExhibitions(data.numberOfEntitiesShown);
			if (json.data?.results) {
				setEntities(json.data?.results);
				if (data.archivedEntities) setAmount(json.data?.amount);
			}
		}
	}

	const handleScroll = async () => {
		if (window.innerHeight + document.documentElement.scrollTop <= document.documentElement.offsetHeight - 1200) return;
		if (!data.archivedEntities) return;

		if (data.selectedEntities === 'exhibitions') {
			let json = await loadArchivedExhibitions(data.numberOfEntitiesShown, undefined, entityList.length);

			if (json.data?.results) {
				setEntities(entityList.concat(json.data?.results));
			}
		}
	};

	const ShowEventDate = entity => {
		return (
			<EntityDate>
				{entity.dates[0].endDate
					? DateFormats.Date(new Date(entity.dates[0].startDate)) +
					  '-' +
					  DateFormats.Date(new Date(entity.dates[0].endDate))
					: DateFormats.Date(new Date(entity.dates[0].startDate))}
			</EntityDate>
		);
	};

	const ShowArticleDate = entity => {
		return <EntityDate>{DateFormats.Date(new Date(entity.date))}</EntityDate>;
	};

	return (
		<Container>
			<TitleContainer>
				<SectionHeader
					headerLink={data.headerLink}
					title={data.title}
					numberOfItems={data.archivedEntities ? amount : data.shouldShowAmounts ? entityList.length : undefined}
				></SectionHeader>
			</TitleContainer>
			<ListContainer>
				{entityList.map((entity, index) => (
					<EntityContainer data={entity} key={index}>
						<MobileImage data={entity.image}></MobileImage>
						{!isMobile && <HoverImage imageUrl={entity.image.url}></HoverImage>}
						<HoverContainer>
							<InfoContainer>
								{data.selectedEntities === 'exhibitions' ? ShowEventDate(entity) : null}
								{data.selectedEntities === 'news' ? ShowArticleDate(entity) : null}
								<EntityTitle>
									{entity.title}
									<EntitySubTitle>{entity.subtitle}</EntitySubTitle>
								</EntityTitle>
								{data.selectedEntities === 'exhibitions' && entity.tag && (
									<DecorationTag>
										<TypeTag tag={entity.tag.name} type={getWord(CommonWords.exhibition)} />
									</DecorationTag>
								)}
							</InfoContainer>
						</HoverContainer>
					</EntityContainer>
				))}
			</ListContainer>
		</Container>
	);
}
