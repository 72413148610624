import React, { DetailedHTMLProps, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Image from './Image';
import gsap, { Power1 } from 'gsap';
import { Breakpoints, Colors, Index } from '../style/Variables';
import Functions from '../style/Functions';
import CustomOverDetection from '../core/utils/CustomOverDetection';
import CustomEvents, { Events } from '../core/CustomEvents';

interface HoverProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	imageUrl: string;
	hoverColor?: string;
	borderColor?: string;
	borderWidth?: string;
	className?: string;
}

const HoverContainer = styled.div<{ color?: string; borderColor?: string; borderWidth?: string }>`
	transition: background-color 0.25s;
	position: absolute;
	border: ${props => (props.borderColor ? '2px solid' + props.borderColor : 'none')};
	width: 100%;
	height: 100%;
	:hover {
		background-color: ${props => (props.color ? props.color : 'transparent')};
	}
	padding: ${props => (props.borderWidth ? props.borderWidth : '30px')};
	box-sizing: border-box;
`;

const FollowImage = styled.div`
	position: absolute;
	aspect-ratio: 3/4;
	width: 275px;

	transform-origin: left center;
	transform: scale(0);

	pointer-events: none;

	visibility: hidden;

	${Functions.breakpoint(Breakpoints.ultra)} {
		width: 350px;
	}
	z-index: ${Index.overlay};
`;
export default function OnHoverImage({
	imageUrl,
	hoverColor,
	borderColor,
	borderWidth,
	className,
	...props
}: HoverProps) {
	const imgRef = useRef<HTMLImageElement>();
	const containerRef = useRef<HTMLDivElement>();

	useEffect(() => {
		CustomOverDetection.Instance.add(containerRef.current, onMouseOver, onMouseOut, imageFollow, 10);
		return () => {
			CustomOverDetection.Instance.remove(containerRef.current);
		};
	}, []);

	const imageFollow = (localX: number, localY: number) => {
		let x = localX; // - imgRef.current.offsetWidth / 2;
		let y = localY - imgRef.current.offsetHeight;

		let skewDifference = (x - parseInt(imgRef.current.style.left)) * -1;

		let xC = x - parseInt(imgRef.current.style.left);
		let yC = y - parseInt(imgRef.current.style.top);

		let gap = Math.sqrt(xC * xC + yC * yC);
		gsap.to(imgRef.current, {
			left: x,
			top: y,
			duration: 0.3,
			transform: 'rotateZ(' + skewDifference / 20 + 'deg)',
			filter: 'brightness(' + Number(100 + Math.floor(gap / 20)) + '%) blur(' + Number(Math.floor(gap / 200)) + 'px)',
		});
	};

	const onMouseOver = () => {
		showImage();
		CustomEvents.dispatch(Events.HIGHLIGHTCURSOR, {});
	};

	const onMouseOut = () => {
		hideImage();
		CustomEvents.dispatch(Events.STANDARDCURSOR);
	};

	const showImage = () => {
		gsap.to(imgRef.current, { autoAlpha: 1, duration: 0.125, ease: Power1.easeIn, scale: 1 });
	};

	const hideImage = () => {
		gsap.to(imgRef.current, { autoAlpha: 0, duration: 0.125, ease: Power1.easeIn, scale: 0 });
	};

	return (
		<HoverContainer
			color={hoverColor}
			borderColor={borderColor}
			borderWidth={borderWidth}
			ref={containerRef}
			className={className}
		>
			{props.children}
			<FollowImage ref={imgRef}>
				<Image src={imageUrl}></Image>
			</FollowImage>
		</HoverContainer>
	);
}
