import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getWord } from '../../core/utils/Language';
import { Colors, Fonts } from '../../style/Variables';
import CommonWords from '../../types/CommonWords';
import DayItemButton from './DayItem';

const Container = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
`;

export default function CalendarDayComponent(props: {
	year: number;
	month: number;
	handleDateChange: (date: Date) => void;
	currentActiveDay: number;
}) {
	const didMountRef = useRef(false);

	const [weekDays, setWeekdays] = useState([]);
	const [selectedDay, setSelectedDay] = useState<number>(props.currentActiveDay);
	const [numberOfDaysArray, setNumberOfDaysArray] = useState<number[]>([]);

	let currentDate = new Date();

	useEffect(() => {
		initWeekdays();
	}, []);

	useEffect(() => {
		findDaysInMonth(props.month);
		if (didMountRef.current) {
			if (props.month === currentDate.getMonth() && props.year === currentDate.getFullYear()) {
				setSelectedDay(currentDate.getDate());
			} else {
				setSelectedDay(1);
			}
		}
		didMountRef.current = true;
	}, [props.month, props.year]);

	useEffect(() => {
		const newDate = new Date(props.year, props.month, selectedDay);

		props.handleDateChange(newDate);
	}, [selectedDay]);

	function findDaysInMonth(monthNumber?: number) {
		// monthNumber + 1 is to get next month, then 0 in days will provide the last day in previous month. Which also descriped number of days in the month.
		let tempDate = new Date(props.year, monthNumber + 1, 0);
		handleNumberOfDaysView(tempDate.getDate());
	}

	function handleNumberOfDaysView(numberOfDays: number) {
		let tempArray = [];

		let tempDate = new Date(props.year, props.month, 1);
		// // Sunday - Saturday : 0 - 6 --> We want Mondays first in the list
		let dayNumber = tempDate.getDay();
		let hej = dayNumber - 1;
		if (hej === -1) {
			hej = 6;
		}

		for (let index = 0; index < hej; index++) {
			tempArray.push(' ');
		}

		for (let index = 0; index < numberOfDays; index++) {
			tempArray.push(index + 1);
		}
		//console.log(tempArray);
		setNumberOfDaysArray(tempArray);
	}

	function initWeekdays() {
		let array = [];

		// TODO: pull this out to a methods -> And base it on Language / CommonWords
		array.push(getWord(CommonWords.weekday.monday).substring(0, 1));
		array.push(getWord(CommonWords.weekday.tuesday).substring(0, 1));
		array.push(getWord(CommonWords.weekday.wednesday).substring(0, 1));
		array.push(getWord(CommonWords.weekday.thursday).substring(0, 1));
		array.push(getWord(CommonWords.weekday.friday).substring(0, 1));
		array.push(getWord(CommonWords.weekday.saturday).substring(0, 1));
		array.push(getWord(CommonWords.weekday.sunday).substring(0, 1));

		setWeekdays(array);
	}

	return (
		<Container>
			{weekDays.map((day, index) => (
				<DayItemButton key={index} isButton={false} day={day}></DayItemButton>
			))}
			{numberOfDaysArray.map((day, index) => (
				<DayItemButton
					hasNoNumber={typeof day === 'string'}
					selected={day === selectedDay}
					isBeforeCurrentDay={
						day < currentDate.getDate() &&
						props.year === currentDate.getFullYear() &&
						props.month === currentDate.getMonth()
					}
					onClick={() => setSelectedDay(day)}
					key={index}
					day={day}
				></DayItemButton>
			))}
		</Container>
	);
}
