import React, { useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import { Colors, Fonts, Breakpoints } from '../../style/Variables';
import FacebookIcon from '../../assets/icons/FacebookIcon';
import YoutubeIcon from '../../assets/icons/YoutubeIcon';
import InstagramIcon from '../../assets/icons/InstagramIcon';
import context from '../../core/context';
import Link from '../../core/PageSwitch/Link';
import HTML from '../../core/utils/HTML';
import { getWord } from '../../core/utils/Language';
import CommonWords from '../../types/CommonWords';
import Functions from '../../style/Functions';

const SocialLink = styled(Link)`
	display: inline-block;
	margin: 0px 5px;
	z-index: 1;
	position: relative;
	:first-of-type {
		margin-left: 0px;
	}
	:last-of-type {
		margin-right: 0px;
	}
	${Functions.slideInHover(Colors.yellow)}

	&:focus,
	:hover {
		/* background-color: ${Colors.yellow}; */
		color: ${Colors.black};
	}
`;

const FooterBanner = styled.div`
	height: auto;
	font-family: ${Fonts.monoFamilyLight};
	font-size: ${Fonts.small};

	display: flex;
	gap: 55px;
	white-space: pre-line;
	justify-content: space-between;
	padding-bottom: 40px;
	flex-direction: column;
	${Functions.breakpoint(Breakpoints.tablet)} {
		gap: 20px;
		font-size: var(--fontDefaultSize);

		flex-direction: row;
		padding-bottom: 71px;
	}
`;

const Location = styled.div<{ shown: Boolean }>`
	margin: 0px 40px;
	text-transform: capitalize;
	align-self: center;
	text-align: center;
	line-height: 25.6px;
	display: ${props => (props.shown ? 'block' : 'none')};
	p {
		margin: 0;
	}
	${Functions.breakpoint(Breakpoints.tablet)} {
		align-self: flex-end;
		text-align: left;

		display: block;
	}
`;

const OpeningHours = styled.div<{ shown: Boolean }>`
	margin: 0px 40px;
	align-self: initial;
	text-align: center;
	line-height: 22px;
	display: ${props => (props.shown ? 'block' : 'none')};
	${Functions.breakpoint(Breakpoints.tablet)} {
		display: block;
		align-self: flex-end;
		text-align: initial;
		line-height: 25.6px;
	}
	span {
		text-transform: capitalize;
		text-decoration: underline;
		${Functions.breakpoint(Breakpoints.tablet)} {
			text-decoration: initial;
		}
	}
`;

const Contact = styled.div<{ shown: Boolean }>`
	margin: 0px 40px;
	align-self: initial;
	text-align: center;
	line-height: 22px;
	display: ${props => (props.shown ? 'block' : 'none')};
	${Functions.breakpoint(Breakpoints.tablet)} {
		display: block;
		align-self: flex-end;
		text-align: initial;
		line-height: 25.6px;
	}
	span {
		text-transform: capitalize;
		text-decoration: underline;
		${Functions.breakpoint(Breakpoints.tablet)} {
			text-decoration: initial;
		}
	}
	overflow: hidden;
	a {
		margin-left: 0px;
		margin-right: 0px;
		display: inline-block;
		width: fit-content;
		${Functions.slideInHover(Colors.yellow)}
	}
	&:focus,
	:hover {
		/* background-color: ${Colors.yellow}; */
		color: ${Colors.black};
	}
`;

const Socials = styled.div`
	display: block;
	margin: 0px 40px;
	align-self: initial;
	text-align: center;
	line-height: 25.6px;
	${Functions.breakpoint(Breakpoints.tablet)} {
		align-self: flex-end;
		text-align: initial;
	}
	span {
		text-transform: capitalize;
		/* display: none; */
		${Functions.breakpoint(Breakpoints.tablet)} {
			/* display: initial; */
		}
	}
`;

const SocialMedias = styled.div`
	margin: 0px;
	display: flex;
	justify-content: center;
	${Functions.breakpoint(Breakpoints.tablet)} {
		display: flex;
		justify-content: initial;
	}
`;

const Slash = styled.span`
	display: initial;

	/* display: none;
	${Functions.breakpoint(Breakpoints.tablet)} {
		display: initial;
	} */
`;

const Copyright = styled.div`
	position: relative;
	margin-left: 0px;
	font-size: var(--fontUltraSmall);
	font-family: ${Fonts.defaultFamily};
	font-weight: 400;
	bottom: 0px;
	text-align: center;
	${Functions.breakpoint(Breakpoints.tablet)} {
		text-align: initial;
		margin-left: 40px;
		position: absolute;
		margin-bottom: 38px;
	}
`;

const Elaborated = styled.div`
	display: flex;
`;

const Text = styled.div``;

export default function FooterInformation(props: { longFormat?: boolean; className?: string; copyright?: boolean }) {
	const { state } = useContext(context);
	return (
		<FooterBanner className={props.className}>
			<Location
				shown={props.longFormat}
				dangerouslySetInnerHTML={{ __html: HTML.clean(state.footer.address) }}
			></Location>
			<OpeningHours shown={props.longFormat}>
				<SocialLink data={state.global.cookiePolicy}>
					<Text>{state.global.cookiePolicy.title}</Text>
				</SocialLink>
			</OpeningHours>
			<Contact shown={props.longFormat}>
				<a href={`mailto:${state.footer.contact}`}>
					<Text>{state.footer.contact}</Text>
				</a>
			</Contact>
			<Socials>
				<SocialMedias>
					<SocialLink to={state.global.linkedin}>
						<Text>LinkedIn</Text>
					</SocialLink>
					<Slash>/</Slash>
					<SocialLink to={state.global.facebook}>
						<Text>Facebook</Text>
					</SocialLink>
					<Slash>/</Slash>
					<SocialLink to={state.global.instagram}>
						<Text>Instagram</Text>
					</SocialLink>
				</SocialMedias>
			</Socials>
			{props.copyright === true && <Copyright>© Kunsthal Spritten 2022. All Rights Reserved</Copyright>}
		</FooterBanner>
	);
}
