import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Functions from '../style/Functions';
import Variables, { Breakpoints, Index } from '../style/Variables';
//@ts-ignore
import Kunsthal from '../assets/svgs/Kunsthal-horizontal.svg';
//@ts-ignore
import Spritten from '../assets/svgs/Spritten-horizontal.svg';
import gsap, { Power1 } from 'gsap';
import ResizeHandler from '../core/utils/ResizeHandler';
import { revealKey, revealValue, setDidRevealStorage } from './CurtainRevealer';

const Revealer = styled.div`
	z-index: ${Index.siteLoaderCurtain};
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 85vh;

	justify-content: space-between;

	box-sizing: border-box;

	pointer-events: none;

	overflow-x: hidden;
`;

const Title = styled.div`
	background-color: white;
	height: 100%;
	width: 100%;
	display: flex;
	:first-of-type {
		position: relative;
	}
`;

const SvgContainer = styled.div`
	width: 100%;
	height: fit-content;
	display: grid;
	align-items: start;
	padding: var(--gridGutter);
`;

const IOSdragConcealer = styled.div`
	position: absolute;
	background-color: white;
	top: 0;
	left: 0;
	height: 50px;
	width: 100vw;
	z-index: 1;
`;

export default function CurtainRevealMobile() {
	const firstTitleRef = useRef<HTMLDivElement>();
	const secondTitleRef = useRef<HTMLDivElement>();
	const revealerRef = useRef<HTMLDivElement>();
	const svgRef = useRef<HTMLDivElement>();

	useEffect(() => {
		if (sessionStorage.getItem(revealKey) === revealValue) {
			gsap.set([firstTitleRef.current, secondTitleRef.current], {
				height: `${svgRef.current.offsetHeight}px`,
			});
			gsap.set(firstTitleRef.current, {
				y: '50px',
			});
			gsap.set(revealerRef.current, {
				zIndex: 1,
			});
		} else {
			gsap.to(firstTitleRef.current, {
				height: `${svgRef.current.offsetHeight}px`,
				duration: 1.5,
				ease: Power1.easeOut,
				delay: 1,
			});
			gsap.to(firstTitleRef.current, {
				y: '50px',
				duration: 0.5,
				ease: Power1.easeOut,
				delay: 2,
			});
			gsap.to(secondTitleRef.current, {
				height: `${svgRef.current.offsetHeight}px`,
				duration: 1.5,
				ease: Power1.easeOut,
				delay: 1,
				onComplete: () => {
					setDidRevealStorage();
				},
			});
			gsap.set(revealerRef.current, {
				zIndex: 1,
			});
		}
		gsap.to(secondTitleRef.current, {
			y: '100%',
			scrollTrigger: {
				trigger: revealerRef.current,
				start: 'top top',
				end: `+=${svgRef.current.offsetHeight + 50}px`,
				scrub: true,
			},
		});
	}, []);
	return (
		<>
			<IOSdragConcealer></IOSdragConcealer>
			<Revealer ref={revealerRef}>
				<Title ref={firstTitleRef}>
					<SvgContainer ref={svgRef} style={{ alignItems: 'flex-end', alignSelf: 'flex-end' }}>
						<Kunsthal />
					</SvgContainer>
				</Title>
				<Title ref={secondTitleRef}>
					<SvgContainer>
						<Spritten />
					</SvgContainer>
				</Title>
			</Revealer>
		</>
	);
}
