import React, { useContext, useReducer } from 'react';

export default class ContextStore {
	public store: React.Context<any>;
	public defaultState: any;

	private reducerNames: string[];
	private reducerFunctions: any[];

	constructor(defaultState: any, reducers: any) {
		this.defaultState = defaultState;

		this.store = React.createContext({
			state: defaultState,
			dispatch: (action: { type: string; value: any }) => {},
		});

		this.reducerNames = Object.keys(reducers);
		this.reducerFunctions = Object.values(reducers);
	}

	public reducer = (state: any, action: { type: string; value: any }) => {
		var index = this.reducerNames.indexOf(action.type);
		if (index >= 0) {
			return this.reducerFunctions[index](state, action.value as never);
		} else {
			throw new Error(`Unknown Action: ${action.type}`);
		}
	};

	public Provider = (): React.Provider<any> => {
		return this.store.Provider;
	};

	public use = (): { state: any; dispatch: (action: { type: string; value: any }) => void } => {
		const context = useContext(this.store);
		if (context === undefined) {
			throw new Error('useContext must be used within a Provider');
		}
		return context;
	};
}
