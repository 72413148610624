import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import CustomEvents, { Events } from '../../core/CustomEvents';
import ResizeHandler from '../../core/utils/ResizeHandler';
import { Colors, Index } from '../../style/Variables';
import { gsap, Sine, Power2 } from 'gsap';

const AnimationCanvas = styled.canvas<{ fromColor: string }>`
	width: 100%;
	height: 100%;
	background-color: ${Colors.black};
	background-color: ${props => props.fromColor};
`;

const Background = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	min-height: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	overflow: hidden;
	z-index: ${Index.siteLoader};
`;

const Container = styled.div<{ fullscreen: boolean }>`
	position: ${props => (props.fullscreen ? 'fixed' : 'absolute')};
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: ${Index.siteLoader};
	/* display: none; */
	/* pointer-events: none; */
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	overflow: hidden;
	pointer-events: auto;
	overscroll-behavior: contain;
`;

export interface ImperativeCrossAnimation {
	triggerTransitionOut: () => void;
	triggerTransitionIn: (e?: CustomEvent) => void;
	fadeOut: (e?: CustomEvent) => void;
}

export interface CrossAnimationProps {
	crossTransformSettings: CrossAnimationAngleAndCenter;
	fullscreen?: boolean;
	functions?: CrossAnimationFunctions;
	toColor?: string;
	fromColor?: string;
	autoReveal?: boolean;
	revealDelay?: number;
}

export interface CrossAnimationAngleAndCenter {
	angles: 'maltese' | 'plus' | 'symmetric';
	center: 'center' | 'random' | 'mouse';
}

export interface CrossAnimationFunctions {
	onCover?: () => void;
	onFinish?: () => void;
	onShow?: () => void;
}

export default forwardRef(function CrossAnimation(
	{
		crossTransformSettings,
		fullscreen = false,
		functions,
		toColor,
		fromColor = Colors.yellow,
		autoReveal = true,
		revealDelay = 0,
	}: CrossAnimationProps,
	ref
) {
	let loader = useRef<HTMLDivElement>();
	let container = useRef<HTMLDivElement>();
	let canvasRef = useRef<HTMLCanvasElement>();

	const duration = 0.7;
	const RAD = Math.PI / 180;

	let radius;
	let centerX;
	let centerY;

	// let mouseX;
	// let mouseY;
	let mouseX = useRef<number>();
	let mouseY = useRef<number>();

	let fullSpin = false;

	let wings: { first; second; third; fourth } = { first: 0, second: 0, third: 0, fourth: 0 };

	let wingRotation: { first; second; third; fourth } = { first: 0, second: 0, third: 0, fourth: 0 };

	useEffect(() => {
		if (crossTransformSettings.center === 'mouse') {
			console.log('adding mouse move listener');

			window.addEventListener('mousemove', e => {
				mouseX.current = e.clientX;
				mouseY.current = e.clientY;
			});
		}
		window.addEventListener('keyup', e => {
			if (e.key === '0') {
				loadingPage();
			}
		});

		return () => {
			console.log('removing mouse move listener');

			window.removeEventListener('mousemove', e => {
				mouseX.current = e.clientX;
				mouseY.current = e.clientY;
			});
		};
	}, []);

	useImperativeHandle(ref, () => ({
		triggerTransitionOut() {
			loadingPage();
		},
		triggerTransitionIn(e?: CustomEvent) {
			newPage(e);
		},
		fadeOut(e?: CustomEvent) {
			dontShow(e);
		},
	}));

	const getAngle = (xPoint, yPoint) => {
		let angle = Math.atan2(yPoint - centerY, xPoint - centerX) * (180 / Math.PI);
		return angle;
	};

	const getAngleDiff = (target, source) => {
		let diff = target - source;
		diff += diff > 180 ? 0 : diff < -180 ? 360 : 0;
		diff += diff > 0 ? 0 : diff > -180 ? 360 : 0;
		return diff;
	};

	const setSizeOfCanvas = () => {
		canvasRef.current.width = container.current.clientWidth;
		canvasRef.current.height = container.current.clientHeight;
	};

	const setWingsLocation = () => {
		crossTransformSettings.center === 'center'
			? midCenter()
			: crossTransformSettings.center === 'random'
			? randomCenter()
			: mouseCenter();

		crossTransformSettings.angles === 'maltese'
			? getMalteseCrossAngles()
			: crossTransformSettings.angles === 'plus'
			? getPlusCrossAngles()
			: getSymmetricCross();

		radius = canvasRef.current.width + canvasRef.current.height;
	};

	const getMalteseCrossAngles = () => {
		wings.first = getAngle(container.current.clientWidth / 3, 0);
		wings.second = getAngle(container.current.clientWidth, container.current.clientHeight / 3);
		wings.third = getAngle((container.current.clientWidth / 3) * 2, container.current.clientHeight);
		wings.fourth = getAngle(0, (container.current.clientHeight / 3) * 2);
	};

	const getPlusCrossAngles = () => {
		wings.first = getAngle(container.current.clientWidth / 2, 0);
		wings.second = getAngle(container.current.clientWidth, container.current.clientHeight / 2);
		wings.third = getAngle(container.current.clientWidth / 2, container.current.clientHeight);
		wings.fourth = getAngle(0, container.current.clientHeight / 2);
	};

	const getSymmetricCross = () => {
		wings.first = -90;
		wings.second = 0;
		wings.third = 90;
		wings.fourth = 180;
	};

	const mouseCenter = () => {
		console.log('mouseCenter: ', mouseX.current);
		if (mouseX.current) {
			centerX = mouseX.current;
			centerY = mouseY.current;
		} else {
			midCenter();
		}
	};

	const randomCenter = () => {
		centerX =
			container.current.clientWidth / 2 +
			(Math.floor((Math.random() * container.current.clientWidth) / 2) -
				(Math.random() * container.current.clientWidth) / 2);
		centerY =
			container.current.clientHeight / 2 +
			(Math.floor((Math.random() * container.current.clientHeight) / 2) -
				(Math.random() * container.current.clientHeight) / 2);
	};

	const midCenter = () => {
		centerX = container.current.clientWidth / 2;
		centerY = container.current.clientHeight / 2;
	};

	const covering = () => {
		fullSpin = true;
		if (functions?.onCover) functions?.onCover();
		if (autoReveal) newPage(undefined);
	};

	const finished = () => {
		if (functions?.onFinish) functions?.onFinish();
		ResizeHandler.getInstance().checkSize();
	};

	const revealing = () => {
		if (functions?.onShow) functions?.onShow();
	};

	const drawWings = () => {
		let context = canvasRef.current.getContext('2d');
		context.beginPath();
		context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
		context.fill();
		context.closePath();
		context.fillStyle = fromColor;

		Object.keys(wings).forEach((item, index) => {
			if (!fullSpin) {
				context.beginPath();
				context.arc(centerX, centerY, radius, wings[item] * RAD, (wings[item] + wingRotation[item]) * RAD, false);
				context.lineTo(centerX, centerY);
				context.fill();
			} else {
				context.beginPath();
				context.arc(
					centerX,
					centerY,
					radius,
					(wings[Object.keys(wings)[(index + 1) % 4]] - wingRotation[item]) * RAD,
					wings[Object.keys(wings)[(index + 1) % 4]] * RAD,
					false
				);
				context.lineTo(centerX, centerY);
				context.fill();
			}
		});
	};

	const loadingPage = () => {
		gsap.set(container.current, {
			pointerEvents: 'auto',
			display: 'block',
			onComplete: () => {
				setSizeOfCanvas();
				setWingsLocation();
			},
		});

		gsap.set(loader.current, {
			opacity: 1,
			backgroundColor: 'transparent',
		});

		gsap.set(wingRotation, {
			first: 0,
			second: 0,
			third: 0,
			fourth: 0,
		});

		fullSpin = false;

		gsap.to(wingRotation, {
			overwrite: true,
			first: getAngleDiff(wings.second, wings.first) + 1,
			second: getAngleDiff(wings.third, wings.second) + 1,

			third: getAngleDiff(wings.fourth, wings.third) + 1,
			fourth: getAngleDiff(wings.first, wings.fourth) + 1,
			duration: duration,
			onUpdate: drawWings,
			ease: Power2.easeIn,
			onComplete: () => {
				covering();
			},
		});
	};

	const normalTransitionOut = e => {
		if (fullSpin) {
			gsap.set(wingRotation, {
				first: getAngleDiff(wings.second, wings.first) + 1,
				second: getAngleDiff(wings.third, wings.second) + 1,

				third: getAngleDiff(wings.fourth, wings.third) + 1,
				fourth: getAngleDiff(wings.first, wings.fourth) + 1,
			});

			drawWings();
			gsap.to(wingRotation, {
				overwrite: true,
				first: 0,
				second: 0,
				third: 0,
				fourth: 0,
				duration: duration,
				delay: revealDelay,
				onUpdate: drawWings,
				onComplete: () => {
					gsap.set(loader.current, {
						opacity: 0,
					});
					gsap.set(container.current, {
						pointerEvents: 'none',
						display: 'none',
					});

					finished();
				},
			});
		} else {
			setWingsLocation();
			fullSpin = true;

			gsap.set(canvasRef.current, {
				backgroundColor: fromColor,
			});
			gsap.to(canvasRef.current, {
				overwrite: true,
				duration: duration,
				ease: Power2.easeOut,
				onStart: () => {
					revealing();
				},
				onComplete: () => {
					wingRotation.first = getAngleDiff(wings.second, wings.first) + 1;
					wingRotation.second = getAngleDiff(wings.third, wings.second) + 1;

					wingRotation.third = getAngleDiff(wings.fourth, wings.third) + 1;
					wingRotation.fourth = getAngleDiff(wings.first, wings.fourth) + 1;
					drawWings();
					gsap.set(canvasRef.current, {
						backgroundColor: 'transparent',
					});
					gsap.to(wingRotation, {
						overwrite: true,
						first: 0,
						second: 0,
						third: 0,
						fourth: 0,
						duration: duration,
						onUpdate: drawWings,
						onComplete: () => {
							gsap.set(loader.current, {
								opacity: 0,
							});
							gsap.set(container.current, {
								pointerEvents: 'none',
								display: 'none',
							});

							finished();
						},
					});
				},
			});
		}
	};

	const newPage = (e: CustomEvent) => {
		setSizeOfCanvas();
		normalTransitionOut(e);
	};

	const dontShow = (e: CustomEvent) => {
		gsap.set(canvasRef.current, {
			backgroundColor: fromColor,
		});
		gsap.to(canvasRef.current, {
			overwrite: true,
			backgroundColor: 'transparent',
			duration: duration,
			ease: Power2.easeOut,
			onStart: () => {
				revealing();
			},
			onComplete: () => {
				wingRotation.first = 0;
				wingRotation.second = 0;

				wingRotation.third = 0;
				wingRotation.fourth = 0;
				gsap.set(loader.current, {
					opacity: 0,
				});
				gsap.set(container.current, {
					pointerEvents: 'none',
					display: 'none',
				});

				finished();
			},
		});
	};

	return (
		<Container fullscreen={fullscreen} ref={container}>
			<Background ref={loader}>
				<AnimationCanvas ref={canvasRef} fromColor={fromColor}></AnimationCanvas>
			</Background>
		</Container>
	);
});
