import { createGlobalStyle } from 'styled-components';
import Variables, { Breakpoints, Colors, Fonts } from './Variables';
import Functions from './Functions';

const Global = createGlobalStyle`
* {
	box-sizing: border-box;
	cursor: none;
}
html {
	overflow-x: hidden;
	overflow-y: scroll;


	overflow-y: overlay;
	scrollbar-width: thin;
	scrollbar-color: black;
	
	::-webkit-scrollbar {
  		width:0.6em;
	}
	::-webkit-scrollbar-track {
		background: transparent;

		&:hover {
				background: ${Colors.grey};
				background-clip: padding-box;
	
				border: 1px solid transparent;
				border-radius: 1em;
			}

	}
	::-webkit-scrollbar-thumb {
 		background-color: rgba(0,0,0,0.3);
		border-radius: 1em;
	}
}
body {
	background-color: var(--background);
	color: var(--text);
	font-family: 'Spezia Web', Tahoma, Helvetica, Arial, Roboto, sans-serif;
	font-size: var(--fontDefaultSize);
	transition: color ${Colors.transition}s, background-color ${Colors.transition}s;
	-webkit-font-smoothing: antialiased;
	font-smooth: always;
	-moz-osx-font-smoothing: auto;
}
a {
	color: var(--text);
	transition: color ${Colors.transition}s;
}
#app {
	min-height:  100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
}

button {
	border: none;
	background-color: inherit;
	padding: 0;
	:hover {
		cursor: pointer;
	}
}

.fade-enter {
  opacity: 0;
	transform: translateX(500px);
	position: absolute;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
	transform: translateX(0px);
  transition: opacity 200ms ease-in-out, transform 200ms ease-out;
}

.fade-exit {
  opacity: 1;
	transform: translateX(0px);
}
.fade-exit-active {
  opacity: 0;
	transform: translateX(-500px);
  transition: opacity 200ms ease-in, transform 200ms ease-in;
}

:root {
	--gridMargin: ${Variables.gridMarginMobile};
	--gridGutter: ${Variables.gridGutterMobile};
	--textModuleGap: 4rem;
	--contentModuleGap: 8rem;

	--background: ${Colors.white};

	--fontUltraSmall: ${Fonts.ultraSmall};
	--fontSmall: ${Fonts.smaller};
	--fontDefaultSize: ${Fonts.smaller};
	--fontNormal: ${Fonts.small};
	--fontNotQuiteMedium: ${Fonts.small};
	--fontMedium: ${Fonts.smaller};
	--fontNotQuiteBig: ${Fonts.notQuiteBig};
	--fontBig: ${Fonts.defaultSize};
	--fontMediumBig: ${Fonts.mediumBig};
	--fontBigger: ${Fonts.medium};
	--fontBiggest: ${Fonts.notQuiteBig};
	--fontHuge: ${Fonts.notQuiteBig};
	--fontMassive: ${Fonts.massive};
}

${Functions.breakpoint(Breakpoints.mobile)} {
	:root {
		--contentModuleGap: 14rem;

		--gridMargin: ${Variables.gridMargin};
		--gridGutter: ${Variables.gridGutter};

		--fontSmall: ${Fonts.small};
		--fontDefaultSize: ${Fonts.small};
		--fontNormal: ${Fonts.smaller};
		--fontNotQuiteMedium: ${Fonts.small};

		--fontMedium: ${Fonts.medium};
		--fontNotQuiteBig: ${Fonts.notQuiteBig};
		--fontBig: ${Fonts.defaultSize};
		--fontBigger: ${Fonts.medium};
		--fontBiggest: ${Fonts.notQuiteBig};
		--fontHuge: ${Fonts.notQuiteBig};
	}
}

${Functions.breakpoint(Breakpoints.laptop)} {
	:root {
		--gridMargin: ${Variables.gridMargin};
		--gridGutter: ${Variables.gridGutter};

		--fontSmall: ${Fonts.small};
		--fontDefaultSize: ${Fonts.small};
		--fontNormal: ${Fonts.normal};
		--fontNotQuiteMedium: ${Fonts.notQuiteMediuem};

		--fontMedium: ${Fonts.medium};
		--fontNotQuiteBig: ${Fonts.notQuiteBig};
		--fontBig: ${Fonts.medium};
		--fontBigger: ${Fonts.big};
		--fontBiggest: ${Fonts.biggest};
		--fontHuge: ${Fonts.bigger};
	}
}

${Functions.breakpoint(Breakpoints.desktop)} {
	:root {
		--gridMargin: ${Variables.gridMarginUltra};
		--gridGutter: ${Variables.gridGutterUltra};

		--fontSmall: ${Fonts.small};
		--fontDefaultSize: ${Fonts.defaultSize};
		--fontNormal: ${Fonts.normal};
		--fontNotQuiteMedium: ${Fonts.notQuiteMediuem};
		--fontMedium: ${Fonts.medium};
		--fontNotQuiteBig: ${Fonts.notQuiteBig};
		--fontBig: ${Fonts.big};
		--fontBigger: ${Fonts.bigger};
		--fontBiggest: ${Fonts.biggest};
		--fontHuge: ${Fonts.huge};
	}
}
`;

export default Global;
