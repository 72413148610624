import React from 'react';
import styled from 'styled-components';
import Functions from '../../style/Functions';
import { Breakpoints, Fonts } from '../../style/Variables';
import AppearByFade from '../AnimationContainers/AppearByFade';
import { ImageData } from '../Image';
import ImageWithDescription from '../ImageWithDescription';
import RichTextModule from '../RichTextModule';

const Container = styled.div`
	margin-top: var(--contentModuleGap);
	width: 100%;

	${Functions.breakpoint(Breakpoints.mobile, 'max')} {
		> :not(:first-child) {
			margin-top: 70px;
		}
	}
`;

const TextItem = styled.div<{ placeRight: boolean; index: number }>`
	font-family: ${Fonts.monoFamilyLight};
	font-size: ${Fonts.normal};
	line-height: 140%;

	width: 100%;

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: var(--fontNormal);

		width: ${Functions.col(5, 12)};
		margin-left: ${props => (props.placeRight ? `${Functions.col(6, 12, 1)}` : `${Functions.col(1, 12, 1)}`)};
	}
`;

const ImageItem = styled.div<{ placeRight: boolean; isPortrait: boolean; shouldIndent: boolean; index: number }>`
	width: 100%;

	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${props => (props.isPortrait ? `${Functions.col(4, 12)}` : `${Functions.col(6, 12)}`)};

		margin-left: ${props =>
			props.placeRight
				? props.isPortrait
					? props.shouldIndent
						? `${Functions.col(7, 12, 1)}`
						: `${Functions.col(6, 12, 1)}`
					: `${Functions.col(6, 12, 1)}`
				: props.isPortrait
				? props.shouldIndent
					? `${Functions.col(1, 12, 1)}`
					: '0'
				: '0'};

		margin-top: -5%;
	}
`;

export default function ImageFlow({
	data,
}: {
	data: { items: { modelId: string; image?: ImageData; text: string }[] };
}) {
	const isPortrait = (image: ImageData) => {
		const ratio = image.width / image.height;
		if (ratio >= 1) return false;
		return true;
	};

	const placeRight = (value: number) => {
		return value % 2 === 0;
	};

	return (
		<Container>
			{data.items.map((item, i) =>
				item.modelId === 'image' ? (
					<AppearByFade key={i} direction={placeRight(i) ? 'left' : 'right'}>
						<ImageItem
							placeRight={placeRight(i)}
							isPortrait={isPortrait(item.image)}
							shouldIndent={i % 3 < 1}
							index={i}
						>
							<ImageWithDescription image={item.image} text={item.text} />
						</ImageItem>
					</AppearByFade>
				) : (
					<AppearByFade key={i} direction={placeRight(i) ? 'left' : 'right'}>
						<TextItem placeRight={placeRight(i)} index={i}>
							<RichTextModule text={item.text} />
						</TextItem>
					</AppearByFade>
				)
			)}
		</Container>
	);
}
