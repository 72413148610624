import React from 'react';
import styled from 'styled-components';
import Functions from '../../style/Functions';
import { Colors, Fonts } from '../../style/Variables';

const Container = styled.div`
	font-family: ${Fonts.sprittenFamily};
	font-size: var(--fontHuge);

	line-height: 1em;
	letter-spacing: 0em;
	padding-top: 5px;
	padding-bottom: 5px;
	color: ${Colors.yellow};
	background-color: ${Colors.black};
	white-space: nowrap;

	transition: color 0.4s linear 0s;

	${Functions.slideInHover(Colors.yellow)}
	&:hover,
	&:focus {
		/* background-color: ${Colors.yellow}; */
		color: ${Colors.black};
	}
`;

export default function StyledButtonBig({ text }: { text: string }) {
	return (
		<Container>
			<span>{text}</span>
		</Container>
	);
}
