export enum Lang {
	DA = 'da',
	EN = 'en',
}

export const defaultLang = Lang.DA;

export const languages = [Lang.DA, Lang.EN];

export class Language {
	private static _instance: Language = null;
	private _currentLang: Lang = defaultLang;

	public static getInstance(): Language {
		if (this._instance === null) {
			this._instance = new Language();
		}

		return this._instance;
	}

	constructor() {
		this._currentLang = this.getLang();
	}

	private getLang(): Lang {
		if (typeof document === 'undefined') return defaultLang;
		let doc = document.documentElement.lang;
		let lang = languages.find(l => l === doc);
		if (!lang) return defaultLang;
		return lang;
	}

	public getCurrentLanguage(): Lang {
		return this._currentLang;
	}

	public isDanish(): boolean {
		return this._currentLang == Lang.DA;
	}

	public getWord = (word: { da: string; en: string }): string => {
		return this.getCurrentLanguage() === Lang.DA ? word.da : word.en;
	};
}

export const getWord = Language.getInstance().getWord;
