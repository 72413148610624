import HTML from './HTML';
import { Language } from './Language';

export const getMeta = (): { title: string; seoTitle: string; description: string; image: string } => {
	var ogTitle = document.querySelector<HTMLMetaElement>('meta[name="title"]');
	var ogDescription = document.querySelector<HTMLMetaElement>('meta[name="description"]');
	var ogImage = document.querySelector<HTMLMetaElement>('meta[name="image"]');

	return {
		title: document.title,
		seoTitle: ogTitle ? ogTitle.content : null,
		description: ogDescription ? ogDescription.content : null,
		image: ogImage ? ogImage.content : null,
	};
};

class Meta {
	private isDanish: boolean;
	private content: any;
	private global: any;

	private title: string;
	private seoTitle: string;
	private descriptionFull: string;
	private description: string;
	private descriptionLength: number = 160;
	private image: string;

	constructor(content: any, global: any, isDanish: boolean = Language.getInstance().isDanish()) {
		this.isDanish = isDanish;
		this.content = content;
		this.global = global;

		var globalSeo = this.isDanish ? this.global.seo.da : this.global.seo.en;

		var title = globalSeo.fallbackSeo.title;
		if (this.content.title || this.content.name) {
			title = (this.content.title || this.content.name) + ' ' + globalSeo.titleSuffix;
		}

		this.title = title;
		this.seoTitle = this.content.seo?.title || title;

		var description = globalSeo.fallbackSeo.description;

		if (this.content.seo?.description) description = this.content.seo.description;
		else if (this.content.description) description = this.richText(this.content.description);
		else if (this.content.groupDescription) description = this.content.groupDescription;
		else if (this.content.modules) {
			var textModules = this.content.modules.filter(
				(m: any) =>
					m.modelId === 'textSection' ||
					m.modelId === 'richText' ||
					m.modelId === 'infoTextSection' ||
					m.modelId === 'historySection'
			);
			if (textModules.length > 0) {
				var description = textModules
					.map((m: any) => {
						if (m.modelId === 'textSection' || m.modelId === 'historySection') {
							return m.text;
						} else if (m.modelId === 'richText' || m.modelId === 'infoTextSection') {
							return this.richText(m.text);
						} else {
							return '';
						}
					})
					.join('\n');
			}
		}

		this.descriptionFull = description;
		this.description = this.summarize(description, this.descriptionLength);

		var image = globalSeo.fallbackSeo.image.url;
		// var image = 'https://www.datocms-assets.com/73777/1653981609-kunstal-test.png';
		if (this.content.image) image = this.content.image.url;
		else if (this.content.modules) {
			var header = this.content.modules.find(
				(m: any) => m.modelId === 'basicHeader' || m.modelId === 'hero' || m.modelId === 'revealHero'
			);
			if (header && header.image) image = header.image.url;
			if (header && header.imageGallery.length > 0) {
				image = header.imageGallery[0].url;
			}
		}
		try {
			var url = new URL(image);
			url.searchParams.set('q', '70');
			url.searchParams.set('fm', 'jpg');
			url.searchParams.set('h', '500');
			this.image = url.toString();
		} catch (e) {
			this.image = image;
		}
	}

	private richText(text: string): string {
		return HTML.simple(text)
			.replace(/<br[^>]*?>/gi, '\n')
			.trim();
	}

	private summarize(text: string, length: number): string {
		if (text.length > length) return text.substring(0, length - 3).trim() + '...';
		else return text;
	}

	public getTitle(): string {
		return this.title;
	}

	public getSeoTitle(): string {
		return this.seoTitle;
	}

	public getDescription(length = this.descriptionLength): string {
		if (length !== this.descriptionLength) return this.summarize(this.descriptionFull, length);
		else return this.description;
	}

	public getImage(): string {
		return this.image;
	}

	public setMeta() {
		document.title = this.title;

		var ogTitle = document.querySelector<HTMLMetaElement>('meta[name="title"]');
		var ogDescription = document.querySelector<HTMLMetaElement>('meta[name="description"]');
		var ogImage = document.querySelector<HTMLMetaElement>('meta[name="image"]');

		if (ogTitle) ogTitle.content = this.seoTitle;
		if (ogDescription) ogDescription.content = this.description;
		if (ogImage) ogImage.content = this.image;
	}
}

export default Meta;
