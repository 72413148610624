import React, { useContext, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Fonts, Colors, Index, Breakpoints } from '../style/Variables';
import Functions from '../style/Functions';
import gsap from 'gsap';
import MalteseCross from './MalteseCross';
import SectionHeader from './SectionHeader';
import CustomEvents, { Events } from './../core/CustomEvents';
import CustomOverDetection from './../core/utils/CustomOverDetection';

const Container = styled.div`
	width: 100vw;
	position: relative;
	margin-left: -(var(--gridMargin));
	padding: 0;
	display: flex;
	/* justify-content: center;
	align-items: center;
	overflow: hidden;
	max-height: 100vh;
	aspect-ratio: 1920/900;
	background-color: #cccccc; */
	flex-direction: column;
	font-family: ${Fonts.monoFamily};
	margin-top: var(--contentModuleGap);
`;

const ItemContainer = styled.a`
	display: flex;
	position: relative;
	border-top: 1px solid ${Colors.black};
	padding-left: var(--gridMargin);
	padding-top: 30px;
	padding-bottom: 30px;
	font-size: var(--fontNormal);
	overflow: hidden;
	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: var(--fontDefaultSize);
		padding-left: ${Functions.colFixed(2, 2)};
	}
	:last-child {
		border-bottom: 1px solid ${Colors.black};
	}
	:before {
		transition: 0.4s transform ease, 0.5s opacity;

		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background-position: bottom left;
		background-image: linear-gradient(${Colors.black} 50%, ${Colors.black} 50%);
		background-size: 150% 150%;

		width: 150%;
		height: 150%;

		opacity: 0;

		transform-origin: 0% 100%;
		transform: rotate(-2deg) translateY(-100%);
	}
	> * {
		z-index: 2;
	}
	:hover {
		color: ${Colors.white};

		:before {
			transform: rotate(0) translateY(0%);
			opacity: 1;
		}
	}
`;

const MovedSectionHeader = styled.div`
	padding-left: ${Functions.colFixed(0, 2)};
	padding-bottom: 20px;
`;

const TitleContainer = styled.div`
	width: ${Functions.colFixed(9, 0)};

	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${Functions.colFixed(5, 1)};
	}
`;

const DownloadLink = styled.div`
	width: ${Functions.colFixed(3, 1)};
	text-align: right;
	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${Functions.colFixed(5, 1)};
		text-align: initial;
	}
`;

export default function DownlodableList({ data }: { data: { headline: string; assets: any } }) {
	const getFileTitle = file => {
		if (file.defaultFieldMetadata?.title) {
			return file.defaultFieldMetadata.title;
		} else {
			const fileSplit = file.url.split('/');
			return fileSplit[fileSplit.length - 1];
		}
	};
	const elementsRef = useRef(data.assets.map(() => useRef()));

	useEffect(() => {
		elementsRef.current.forEach(item => {
			CustomOverDetection.Instance.add(item.current, mouseOver, mouseOut, () => {}, Index.link);
		});
		return () => {
			elementsRef.current.forEach(item => {
				CustomOverDetection.Instance.remove(item.current);
			});
		};
	}, []);

	const mouseOver = () => {
		CustomEvents.dispatch(Events.HIGHLIGHTCURSOR, {});
	};

	const mouseOut = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR);
	};

	return (
		<Container>
			<MovedSectionHeader>
				<SectionHeader title={data.headline}></SectionHeader>
			</MovedSectionHeader>
			{data.assets.map((item, i) => (
				<ItemContainer ref={elementsRef.current[i]} key={i} download target='_blank' href={item.url}>
					<TitleContainer>{getFileTitle(item)}</TitleContainer> <DownloadLink>Download</DownloadLink>
				</ItemContainer>
			))}
		</Container>
	);
}
