// import { CookieInformationHelper } from 'ts/cookieInformation/CookieInformationHelper';
// import { v4 as uuidv4 } from 'uuid';

import Meta from './Meta';

export default class GoogleAnalytics {
	public static ANALYTICS_KEY: string = '';
	private hasConsent = localStorage.getItem('allow-cookies') === 'true';

	private static _instance: GoogleAnalytics = null;
	public static getInstance(): GoogleAnalytics {
		if (this._instance === null) this._instance = new GoogleAnalytics();

		return this._instance;
	}

	constructor() {
		if (typeof gtag == 'undefined') return;

		// console.log('GoogleAnalytics.constructor();');
		this.setup();
	}

	private setup() {
		gtag('config', GoogleAnalytics.ANALYTICS_KEY, {
			send_page_view: false,
		});
	}

	public pageView(meta?: Meta): void {
		if (!this.useStatistical()) return;

		let payload: any = {};

		payload.page_title = meta.getTitle();
		payload.page_location = window.location.href;
		// payload.page_path = window.location.pathname;

		this.applyNeededData(payload);

		// console.log('GoogleAnalytics.pageView();');
		// console.dir(payload);

		gtag('event', 'page_view', {
			payload,
		});
	}

	private useStatistical(): boolean {
		this.checkConsent();
		return this.hasConsent && typeof gtag !== 'undefined';
	}

	private applyNeededData(payload: any): void {
		if (!payload.send_to) payload.send_to = [GoogleAnalytics.ANALYTICS_KEY];

		if (payload.event) payload.event = payload.event.toLowerCase();
	}

	public checkConsent(): boolean {
		var storage = localStorage.getItem('allow-cookies') === 'true';
		if (storage !== this.hasConsent) {
			if (storage) this.setup();
			this.hasConsent = storage;
		}
		return this.hasConsent;
	}

	public setConsent(newConsent: boolean) {
		if (newConsent && !this.hasConsent) this.setup();
		localStorage.setItem('allow-cookies', newConsent ? 'true' : 'false');
		this.hasConsent = newConsent;
	}
}
