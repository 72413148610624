import React, { useContext, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Fonts, Colors, Index, Breakpoints } from '../../style/Variables';
import context from '../../core/context';
import gsap, { Power1 } from 'gsap';
import Link from '../../core/PageSwitch/Link';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import CustomEvents, { Events } from '../../core/CustomEvents';
import Functions from '../../style/Functions';
import ResizeHandler from '../../core/utils/ResizeHandler';

const Container = styled.div``;

const MainPageTopMenu = styled.div`
	position: fixed;
	padding-top: 33px;
	z-index: ${Index.subMenu};
	height: 50px;
	width: ${Functions.col(8, 12, 0)};
	margin-left: ${Functions.col(2, 12, 1)};
	margin-right: ${Functions.col(2, 12, 1)};
	left: 0px;
	font-size: var(--fontMediumBig);
	display: flex;
	align-items: center;
	flex-direction: row;

	justify-content: space-between;
`;

const MainPageTopMenuItem = styled.div`
	margin-bottom: auto;
	font-size: var(--fontNotQuiteMedium);
	font-family: ${Fonts.sprittenFamily};
	display: flex;
	flex-direction: column;
	height: 20px;
	padding: 5px 0px;
	flex-grow: 1;
	flex-basis: 80px;
	box-sizing: border-box;
	max-width: fit-content;
	position: relative;
	${Functions.breakpoint(Breakpoints.laptop)} {
		height: 25px;
	}

	a {
		pointer-events: none;
	}
	a:first-of-type {
		pointer-events: auto;
	}

	:hover a {
		pointer-events: auto;
	}
	:hover span {
		transform: scaleY(-1);
		border-color: ${Colors.darkGrey};
	}
`;

const FrontpageLink = styled(Link)<{ shouldbelight: string }>`
	color: ${props => (props.shouldbelight === 'true' ? Colors.white : Colors.black)};

	transition: all 0.2s ease 0s;
	:hover {
		color: ${Colors.darkGrey};
	}
`;

const ArrowIcon = styled.span`
	display: inline-block;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 6px;
	transition: all 0.2s ease 0s;
`;

const DropDownHolder = styled.div`
	position: absolute;
	width: 100%;
	height: fit-content;
	top: 0;
	left: 0;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;

	overflow: hidden;
`;

const DropdownView = styled.div`
	padding-top: 15px;

	position: absolute;
	top: 0;
	left: 0;
	width: fit-content;
	height: fit-content;
	display: flex;
	flex-direction: column;
	gap: 15px;
`;

export default function FrontpageNavigation(fullPage?: any) {
	const { state } = useContext(context);
	const MainPageTopMenuRef = useRef<HTMLDivElement>();
	const frontpageLinkRefs = useRef(state.topMenu.links.map(() => useRef()));
	const subMenuRefs = useRef(state.topMenu.links.map(() => useRef()));
	const dropdownViewRef = useRef(state.topMenu.links.map(() => useRef()));

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);
		readjustMenu();
		let tween = gsap
			.timeline({
				scrollTrigger: {
					trigger: fullPage.fullPage.current,
					start: '100%-=45px',
					scrub: false,
					toggleActions: 'play complete reverse reverse',
				},
			})
			.to(MainPageTopMenuRef.current, {
				opacity: 0,
				display: 'none',
				y: 50,
				duration: 0.2,
			});
		const adjust = () => {
			try {
				// @ts-ignore
				tween.scrollTrigger.refresh();
			} catch (e) {
				console.log('we got issues', e);
				//Bugsnag.notify(`Failed to refresh tweens ScrollTrigger : ` + e);
			}
		};

		setSubmenuPlacement();

		CustomEvents.listen(Events.BREAKPOINT, readjustMenu);
		CustomEvents.listen(Events.TRANSITIONIN, adjust);
		window.addEventListener('resize', setSubmenuPlacement);
		return function () {
			if (tween) tween.kill();
			tween = null;
			CustomEvents.remove(Events.TRANSITIONIN, adjust);
			CustomEvents.remove(Events.BREAKPOINT, readjustMenu);
			window.removeEventListener('resize', setSubmenuPlacement);
		};
	}, []);

	const readjustMenu = () => {
		if (window.innerWidth > parseInt(Breakpoints.laptop)) {
			frontpageLinkRefs.current.forEach((item, index) => {
				if (state.topMenu.links[index].subLinks.length > 0) {
					gsap.set(item.current, {
						height: '25px',
					});
					gsap.set(subMenuRefs.current[index].current, {
						width: dropdownViewRef.current[index].current.offsetWidth + 'px',
					});
				} else {
					gsap.set(item.current, {
						height: subMenuRefs.current[index].current.offsetHeight,
					});
					gsap.set(subMenuRefs.current[index].current, {
						width: dropdownViewRef.current[index].current.offsetWidth + 'px',
					});
				}
			});
		} else {
			frontpageLinkRefs.current.forEach((item, index) => {
				if (state.topMenu.links[index].subLinks.length > 0) {
					gsap.set(item.current, {
						height: '20px',
					});
				} else {
					gsap.set(item.current, {
						height: subMenuRefs.current[index].current.offsetHeight,
					});
				}
			});
		}
	};

	const setSubmenuPlacement = () => {
		subMenuRefs.current.map((item, index) => {
			item.current.style.top = frontpageLinkRefs.current[index].current.offsetHeight + 'px';
			item.current.style.offsetWith = dropdownViewRef.current[index].current.offsetWidth + 'px';
		});
	};

	const showFrontpageSubMenu = index => {
		if (state.topMenu.links[index].subLinks.length > 0) {
			let newHeight = dropdownViewRef.current[index].current.offsetHeight + 'px';
			gsap.to(frontpageLinkRefs.current[index].current, { height: newHeight, duration: '0.2', ease: Power1.easeIn });
			gsap.to(subMenuRefs.current[index].current, { height: newHeight, duration: '0.2', ease: Power1.easeIn });
		}
	};

	const hideFrontpageSubMenu = index => {
		if (state.topMenu.links[index].subLinks.length > 0) {
			gsap.to(frontpageLinkRefs.current[index].current, {
				height: window.innerWidth > parseInt(Breakpoints.laptop) ? '25px' : '20px',
				duration: '0.2',
				ease: Power1.easeIn,
			});
			gsap.to(subMenuRefs.current[index].current, { height: 0, duration: '0.2', ease: Power1.easeIn });
		}
	};

	return (
		<Container>
			<MainPageTopMenu ref={MainPageTopMenuRef}>
				{state.topMenu.links.map((link, i) => (
					<MainPageTopMenuItem
						onMouseEnter={e => showFrontpageSubMenu(i)}
						onMouseLeave={e => hideFrontpageSubMenu(i)}
						ref={frontpageLinkRefs.current[i]}
						key={i}
					>
						<FrontpageLink data={link.primaryLink} shouldbelight={state.topMenu.textColor.toString()}>
							{link.primaryLink.title}
							{link.subLinks.length > 0 && (
								<ArrowIcon>
									<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
										<path
											d='M7.72772 0.625L5.33663 7.60853H5.0396H4.9604H4.66337L2.27228 0.625H0L3.32673 9.625H6.67327L10 0.625H7.72772Z'
											fill={state.topMenu.textColor ? Colors.white : Colors.black}
										/>
									</svg>
								</ArrowIcon>
							)}
						</FrontpageLink>
						<DropDownHolder ref={subMenuRefs.current[i]}>
							<DropdownView ref={dropdownViewRef.current[i]}>
								{link.subLinks.map((subLink, io) => (
									<FrontpageLink data={subLink} key={io} shouldbelight={state.topMenu.textColor.toString()}>
										{subLink.title}
									</FrontpageLink>
								))}
							</DropdownView>
						</DropDownHolder>
					</MainPageTopMenuItem>
				))}
			</MainPageTopMenu>
		</Container>
	);
}
