import React, { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import CustomEvents, { Events } from '../../core/CustomEvents';
import CustomOverDetection from '../../core/utils/CustomOverDetection';
import { Colors, Fonts, Index } from '../../style/Variables';
import HighlightMouseContainer from '../HighlightMouseContainer';

const Input = styled.input.attrs({ type: 'checkbox' })`
	height: 0;
	width: 0;
	opacity: 0;
	z-index: -1;
	box-sizing: border-box;
`;

const Label = styled.label<{ disabled: boolean }>`
	position: relative;
	display: inline-block;
	/* cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')}; */
	margin: 0.6em 2.3em;
`;

const rotate = keyframes`
 from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const Indicator = styled.div`
	width: 1.2em;
	height: 1.2em;
	background: ${Colors.white};
	position: absolute;
	top: 0.095em;
	left: -2.3em;
	border: 1px solid ${Colors.black};

	box-sizing: border-box;

	${Input}:not(:disabled):checked & {
		background: ${Colors.black};
	}

	${Label}:hover & {
		background: #d1d1d1;
	}

	&::after {
		content: '';
		position: absolute;
		display: none;
	}

	${Input}:checked + &::after {
		display: block;
		/* top: -7em;
		left: 0.29em; */

		width: 100%;
		height: 100%;
		background-color: ${Colors.black};
		/* border-width: 0 0.2em 0.2em 0; */
		animation-name: ${rotate};
		animation-duration: 0.3s;
		animation-fill-mode: forwards;
	}

	&::disabled {
		cursor: not-allowed;
	}
`;

export default function Checkbox(props: {
	checked: boolean;
	onChange: (value: boolean) => void;
	name?: string;
	id?: string;
	label: string;
	disabled?: boolean;
}) {
	const containerRef = useRef<HTMLLabelElement>();
	const boxRef = useRef<HTMLDivElement>();

	useEffect(() => {
		CustomOverDetection.Instance.add(containerRef.current, mouseOver, mouseOut, () => {}, Index.scrolledNavMenu);
		CustomOverDetection.Instance.add(boxRef.current, mouseOver, mouseOut, () => {}, Index.scrolledNavMenu);

		return () => {
			CustomOverDetection.Instance.remove(containerRef.current);
			CustomOverDetection.Instance.remove(boxRef.current);
		};
	}, []);

	const mouseOver = () => {
		CustomEvents.dispatch(Events.HIGHLIGHTCURSOR);
	};

	const mouseOut = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR);
	};
	return (
		<Label htmlFor={props.id} disabled={props.disabled} ref={containerRef}>
			{props.label}

			<Input
				id={props.id}
				name={props.name}
				disabled={props.disabled}
				checked={props.checked}
				onChange={event => props.onChange(event.target.checked)}
			/>

			<Indicator ref={boxRef} />
		</Label>
	);
}
