import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { takeCoverage } from 'v8';
import CustomEvents, { Events } from '../../core/CustomEvents';
import Link from '../../core/PageSwitch/Link';
import CustomOverDetection from '../../core/utils/CustomOverDetection';
import { DateFormats } from '../../core/utils/DateFormats';
import { Colors, Fonts } from '../../style/Variables';
import Image, { ImageData } from '../Image';
import TypeTag from '../TypeTag';
import Functions from '../../style/Functions';
import { Breakpoints } from '../../style/Variables';

const Container = styled(Link)`
	position: relative;
	top: 50%;

	.markedHover {
		${Functions.slideInHover(Colors.yellow)}
	}
`;

const InfoContainer = styled.div`
	font-family: ${Fonts.monoFamily};
	font-size: var(--fontDefaultSize);
	line-height: 150%;
	transition: background-color 0.25s;
	padding-bottom: 20px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-bottom: 0px;
	}
`;

const EventsDetailsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-top: 30px;
		flex-direction: row;
	}
`;

const TitleContainer = styled.h3`
	margin: 0;
	font-size: var(--fontMedium);
	font-family: ${Fonts.sprittenFamily};

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-family: ${Fonts.monoFamily};
		font-size: var(--fontDefaultSize);
		font-weight: 600;
	}
`;

const DateContainer = styled.p`
	margin: 0;
	font-family: ${Fonts.monoFamily};
	font-size: var(--fontDefaultSize);
	font-weight: 400;
	margin-top: 0.2em;
	display: none;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: block;
	}
`;

const DateContainerMobile = styled.p`
	display: block;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	}
`;

const RefContainer = styled.div<{ minHeight: number }>`
	height: fit-content;
	position: relative;
	min-height: ${props => props.minHeight + 'px'};
`;

export default function EventCard({
	data,
}: {
	data: {
		image: ImageData;
		tag: { name: string };
		dates: { startDate: string; endDate?: string }[];
		title: string;
		path?: string;
		slug: string;
		_prefix: string;
	};
}) {
	return (
		<RefContainer minHeight={300}>
			<Container data={data}>
				<Image data={data.image} />
				<EventsDetailsContainer>
					<InfoContainer className='markedHover'>
						<DateContainerMobile>
							{DateFormats.Short(new Date(data.dates[0].startDate))}
							{data.dates[0].endDate ? '-' + DateFormats.Short(new Date(data.dates[0].endDate)) : ''}
						</DateContainerMobile>
						<TitleContainer>{data.title}</TitleContainer>
						<DateContainer>
							{DateFormats.Short(new Date(data.dates[0].startDate))}
							{data.dates[0].endDate ? '-' + DateFormats.Short(new Date(data.dates[0].endDate)) : ''}
						</DateContainer>
					</InfoContainer>
					{data.tag && <TypeTag tag={data.tag.name} type={'event'} />}
				</EventsDetailsContainer>
			</Container>
		</RefContainer>
	);
}
