import { Context, useReducer } from 'react';
import ContextStore from './store/ContextStore';
import DefaultState from './store/DefaultState';
import Reducers from './store/Reducers';

export interface IStoreType {
	state: typeof DefaultState;
	dispatch: React.Dispatch<{
		type: string;
		value: any;
	}>;
}

export const Store = new ContextStore(DefaultState, Reducers);
export const Provider = Store.store.Provider as React.Provider<IStoreType>;

const context = Store.store as Context<IStoreType>;
export default context;
