import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import styled from 'styled-components';
import Functions from '../../style/Functions';
import gsap from 'gsap';

export type CarouselSettingsObject = {
	id: string;
	link: any;
	linkText: string;
	maxEvents: number;
	modelId: string;
	title: string;
	type: string;
};

const Container = styled.div`
	height: fit-content;
	width: 100%;
	position: relative;
	overflow-x: hidden;

	${Functions.breakpoint('tablet')} {
		overflow-x: visible;
	}
`;

const Inner = styled.div`
	position: relative;
	left: 0;
	display: flex;
	gap: var(--gridGutter);
`;

export interface ImperativeCarousel {
	triggerHandleResize: () => void;
}

function Carousel(
	{ children, className, activeIndex }: { children: Array<any>; className?: string; activeIndex: number },
	ref: React.ForwardedRef<ImperativeCarousel>
) {
	var carouselContainer = React.useRef<HTMLDivElement>();

	useImperativeHandle(ref, () => ({
		triggerHandleResize() {
			handleResize();
		},
	}));

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		moveToIndex(activeIndex, 0.5);
	}, [activeIndex]);

	function handleResize() {
		moveToIndex(activeIndex, 0.5);
	}

	function moveToIndex(index: number, duration: number) {
		var root = document.querySelector(':root');
		var rootStyled = getComputedStyle(root);
		gsap.to(carouselContainer.current, {
			left:
				-(carouselContainer.current.children[0].clientWidth + parseInt(rootStyled.getPropertyValue('--gridGutter'))) *
				index,
			duration: duration,
		});
	}

	return (
		<Container className={className}>
			<Inner ref={carouselContainer}>
				{React.Children.map(children, (child, index) => {
					return React.cloneElement(child, {});
				})}
			</Inner>
		</Container>
	);
}

export default forwardRef(Carousel);
