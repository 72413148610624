import React, { useState } from 'react';
import styled from 'styled-components';
import { ArrayUtils } from '../core/utils/ArrayUtils';
import ArticleList from '../modules/ListPages/ArticlesList';
import EventList from '../modules/ListPages/EventList';
import ExhibitionList from '../modules/ListPages/ExhibitionList';
import MagicModuleSwitcher from '../modules/MagicModuleSwitcher';
import { FilterGroupObject } from '../modules/SiteNavigation/FilterSelection';
import { Page, PageContent } from '../style/Shared';
import { Colors } from '../style/Variables';

const ContentWrapper = styled(PageContent)``;

// This has to reflect the types from the CMS 'list_type' under 'list_page
type listType = 'exhibitions' | 'articles' | 'events';

export default function ListPage(props: { content?: any }) {
	const [selectedFilter, setSelectedFilter] = useState<string[]>([]);
	const [selectedDateFilter, setSelectedDateFilter] = useState<Date>(new Date());

	const onFilterChange = (id: string, checked: boolean) => {
		if (checked) {
			const copy = [...selectedFilter, id];
			setSelectedFilter(copy);
		} else {
			const copy = [...selectedFilter];

			ArrayUtils.removeElement(copy, id);
			setSelectedFilter(copy);
		}
	};

	const onDateChange = (newDate: Date) => {
		setSelectedDateFilter(newDate);
	};

	const resetFilters = () => {
		setSelectedDateFilter(new Date());
		setSelectedFilter([]);
	};

	const identifyList = (type: listType) => {
		if (type === 'exhibitions') {
			return (
				<ExhibitionList
					infoText={props.content.infoText}
					menuProps={{ breadcrumb: props.content.title, type: props.content.modelId }}
					selectedFilterProps={{
						filterArray: props.content._eventsExhibitionsTags,
						selectedFilters: selectedFilter,
						selectedDateFilter: selectedDateFilter,
						changeHandler: onFilterChange,
						dateChangeHandler: onDateChange,
					}}
				/>
			);
		} else if (type === 'articles') {
			return (
				<ArticleList
					menuProps={{ breadcrumb: props.content.title, type: props.content.modelId }}
					selectedFilterProps={{
						filterArray: props.content._newsTags,
						selectedFilters: selectedFilter,
						selectedDateFilter: selectedDateFilter,
						changeHandler: onFilterChange,
						dateChangeHandler: onDateChange,
					}}
				/>
			);
		} else if (type === 'events') {
			return (
				<EventList
					infoText={props.content.infoText}
					menuProps={{ breadcrumb: props.content.title, type: props.content.modelId }}
					selectedFilterProps={{
						filterArray: props.content._eventsExhibitionsTags,
						selectedFilters: selectedFilter,
						selectedDateFilter: selectedDateFilter,
						changeHandler: onFilterChange,
						dateChangeHandler: onDateChange,
					}}
				/>
			);
		}
	};

	return (
		<Page>
			<ContentWrapper>{identifyList(props.content.listType)}</ContentWrapper>
		</Page>
	);
}
