import React, { useContext, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Fonts, Colors, Index, Breakpoints } from '../../style/Variables';
import context from '../../core/context';
import gsap, { Power0 } from 'gsap';
import Link from '../../core/PageSwitch/Link';
import LinkTools from '../../core/utils/LinkTools';
import Functions from '../../style/Functions';
import FooterInformation from '../Footer/FooterInformation';
import CustomEvents, { Events } from '../../core/CustomEvents';
import CustomOverDetection from '../../core/utils/CustomOverDetection';
import { Language, Lang, getWord } from '../../core/utils/Language';
import CommonWords from '../../types/CommonWords';

const MenuContainer = styled.div`
	position: fixed;
	box-sizing: border-box;
	top: 0px;
	left: 0px;
	/* width: 100vw; */
	width: 100%;
	height: 0vh;
	z-index: ${Index.fullScreenMenu};
	opacity: 0;
	background-color: white;
	overflow-y: overlay;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	::-webkit-scrollbar {
		width: 0.6em;
	}
	::-webkit-scrollbar-track {
		background: transparent;

		&:hover {
			background: ${Colors.grey};
			background-clip: padding-box;

			border: 1px solid transparent;
			border-radius: 1em;
		}
	}
	::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.3);
		border-radius: 1em;
	}
`;

const MenuTop = styled.div`
	background-color: ${Colors.black};
	color: ${Colors.white};
	height: 50px;
	min-height: 50px;
	display: flex;
	align-items: center;
	padding: 0px var(--gridMargin);
	${Functions.breakpoint(Breakpoints.mobile)} {
		height: 91px;
		background-color: ${Colors.white};
		color: ${Colors.black};
		border-bottom: 1px solid ${Colors.black};
	}
`;

const CloseMenu = styled.div`
	font-family: ${Fonts.sprittenFamily};
	font-size: var(--fontNotQuiteMedium);
	margin-left: auto;
	${Functions.breakpoint(Breakpoints.mobile)} {
		${Functions.slideInHover(Colors.yellow)}

		&:focus,
		:hover {
			/* background-color: ${Colors.yellow}; */
			color: ${Colors.black};
		}
	}
`;

const CloseText = styled.span`
	display: inline-block;
	z-index: 1;
	position: relative;
	margin: 15px 0px;
	transition: background-color 0.4s ease 0s;
	:hover {
		background-color: ${Colors.yellow};
	}
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin: 0;
	}
`;

const NavContainer = styled.div<{ submenu: number }>`
	color: ${Colors.white};
	font-family: ${Fonts.sprittenFamily};
	font-size: var(--fontHuge);
	/* font-weight: 700; */
	transition: background-color 0.25s;
	display: flex;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: var(--gridMargin);
	justify-content: space-between;

	text-transform: capitalize;
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-bottom: ${props => (props.submenu > 0 ? '35px' : '0px')};
		justify-content: initial;
		padding-left: ${Functions.col(1, 12, 2)};
	}
`;

const LinkContainer = styled(Link)<{ submenu: number }>`
	font-family: ${Fonts.sprittenFamily};
	font-size: var(--fontHuge);
	/* font-weight: 700; */
	transition: background-color 0.25s;
	display: flex;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: var(--gridMargin);
	justify-content: space-between;

	text-transform: capitalize;
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-bottom: 35px;
		padding-top: 35px;
		justify-content: initial;
		padding-left: ${Functions.col(1, 12, 2)};
	}
`;

const MenuItem = styled.div<{ submenu: number }>`
	position: relative;
	left: -100vw;
	// Uses local mix-blend-mode difference, so color should be white;
	color: ${Colors.white};
	border-top: 1px solid ${Colors.black};
	box-sizing: border-box;
	transition: 0.2s all ease 0s;
	overflow: hidden;
	${Functions.breakpoint(Breakpoints.mobile)} {
		:nth-child(1) {
			border-top: 0px solid ${Colors.black};
		}
	}

	:nth-last-child(1) {
		border-bottom: 1px solid ${Colors.black};
		${Functions.breakpoint(Breakpoints.mobile)} {
			margin-bottom: 64px;
		}
	}

	${LinkContainer} {
		position: relative;

		:before {
			transition: 0.4s transform ease, 0.5s opacity;

			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			background-position: bottom left;
			background-image: linear-gradient(${Colors.black} 50%, ${Colors.black} 50%);
			background-size: 150% 150%;

			width: 150%;
			height: 100%;
			opacity: 0;

			transform-origin: 0% 100%;
			transform: rotate(-5deg) translateY(-100%);
		}
		> * {
			z-index: 2;
		}
	}

	:hover {
		${LinkContainer} {
			/* color: ${Colors.white}; */
			/* background-color: ${Colors.black}; */
			:before {
				transform: rotate(0) translateY(0%);
				opacity: 1;
			}
		}
	}
`;

const SubLinkContainer = styled.div<{ shown: boolean }>`
	padding-left: var(--gridMargin);
	/* background-color: ${Colors.white}; */
	width: 100vw;
	overflow: hidden;
	height: 0px;
	display: ${props => (props.shown ? 'block' : 'none')};
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-left: ${Functions.col(1, 12, 2)};
	}
`;

const SubLink = styled(Link)`
	font-family: ${Fonts.monoFamily};
	font-size: var(--fontDefaultSize);
	font-weight: 400;
	color: ${Colors.black};
	transition: background-color 0.25s;
	margin-left: 0px;
	margin-bottom: 26px;
	margin-top: 29px;
	display: block;
	:hover {
		background-color: ${Colors.yellow};
	}
	:first-child {
		margin-left: 0px;
	}
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-left: 100px;
		display: inline-block;
	}
`;

const ShowCross = styled.div`
	padding-right: 15px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	}
`;

const LogoContainer = styled.div`
	font-family: ${Fonts.sprittenFamily};
	font-size: var(--fontNotQuiteMedium);
	overflow: hidden;
	position: relative;
	display: inline-block;
	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${Functions.col(3, 12)};
	}
`;

const LogoLink = styled(Link)`
	transition: background-color 0.1s linear 0s;
	top: 3px;
	display: inline-block;
	${Functions.breakpoint(Breakpoints.mobile)} {
		${Functions.slideInHover(Colors.yellow)}

		&:focus,
	:hover {
			/* background-color: ${Colors.yellow}; */
			color: ${Colors.black};
		}
	}
`;

const LanguageNav = styled.div`
	position: relative;
	display: none;
	margin-left: var(--gridGutter);

	// Commented out until they need this functionality
	/* ${Functions.breakpoint(Breakpoints.mobile)} {
		display: inline-block;
	} */
	display: none;
`;

const LanguageNavMobile = styled.div`
	text-align: center;
	padding: 20px 0px;
	font-size: ${Fonts.defaultSize};
	button {
		color: ${Colors.black};
		margin: 0px 24px;
	}
	// Commented out until they need this functionality
	/* ${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	} */
	display: none;
`;

const LangButton = styled.button<{ hl: boolean }>`
	background-color: ${props => (props.hl ? Colors.yellow : 'transparent')};
	:hover {
		cursor: none;
	}
`;

const Footer = styled(FooterInformation)`
	height: auto;
	margin-top: auto;

	z-index: ${Index.topMenu};
`;

const ScrollableDiv = styled.div`
	overflow-x: hidden;
	overflow-y: auto;

	${Functions.breakpoint(Breakpoints.mobile, 'max')} {
		background:
		/* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
			linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
			/* Shadows */ radial-gradient(farthest-side at 0% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
			radial-gradient(farthest-side at 0% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
		background-repeat: no-repeat;
		background-color: white;
		background-size: 300% 40px, 300% 40px, 300% 14px, 300% 14px;

		/* Opera doesn't support this in the shorthand */
		background-attachment: local, local, scroll, scroll;
	}
`;

const SpanContainer = styled.span`
	padding: 15px 0px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding: 0;
	}
`;

export interface MenuProps {
	toggleMenu: boolean;
	updateParentToggle: Function;
}

export default function FullScreenMenu(props: MenuProps) {
	const { state } = useContext(context);
	const [navigationToggle, setNavigationToggle] = useState(false);
	const MenuContainerRef = useRef<HTMLDivElement>();
	const primaryLinkRefs = useRef(state.topMenu.links.map(() => useRef()));
	const subMenuRefs = useRef(state.topMenu.links.map(() => useRef()));
	const CloseMenuRef = useRef<HTMLDivElement>();
	const LangENRef = useRef<HTMLButtonElement>();
	const LangDARef = useRef<HTMLButtonElement>();
	const [isMobile, setIsMobile] = useState(false);
	const [localLang, setLocalLang] = useState();

	const onBreakpointChange = () => {
		// console.log('breakpoint change!');
		if (window.innerWidth <= parseInt(Breakpoints.mobile)) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	useEffect(() => {
		// console.log('ON OUR WAY');
	}, [localLang]);

	useEffect(() => {
		// console.log('breakpoint', isMobile);
	}, [isMobile]);

	useEffect(() => {
		// console.log(Language.getInstance().getCurrentLanguage());
		onBreakpointChange();
		CustomOverDetection.Instance.add(MenuContainerRef.current, mouseOverStandard, mouseOut, () => {}, Index.link);
		CustomOverDetection.Instance.add(LangENRef.current, mouseOver, mouseOut, () => {}, Index.fullScreenMenu);
		CustomOverDetection.Instance.add(LangDARef.current, mouseOver, mouseOut, () => {}, Index.fullScreenMenu);
		CustomEvents.listen(Events.RESIZE, onBreakpointChange);
		CustomEvents.listen(Events.SAMEPAGE, closeMenu);

		window.addEventListener('keydown', handleEscape);

		return () => {
			CustomOverDetection.Instance.remove(MenuContainerRef.current);
			CustomOverDetection.Instance.remove(CloseMenuRef.current);
			CustomOverDetection.Instance.remove(LangENRef.current);
			CustomOverDetection.Instance.remove(LangDARef.current);
			CustomEvents.remove(Events.RESIZE, onBreakpointChange);
			CustomEvents.remove(Events.SAMEPAGE, closeMenu);

			window.removeEventListener('keydown', handleEscape);
		};
	}, []);

	const closeMenu = () => {
		setNavigationToggle(false);
	};

	const mouseOver = () => {
		CustomEvents.dispatch(Events.HIGHLIGHTCURSOR, {});
	};

	const mouseOverStandard = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR, {});
	};

	const mouseOut = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR);
	};

	useEffect(() => {
		if (props.toggleMenu) {
			setNavigationToggle(props.toggleMenu);
		}
	}, [props.toggleMenu]);

	useEffect(() => {
		toggleNavigationMenu();
		if (!navigationToggle) {
			props.updateParentToggle(navigationToggle);
		}
	}, [navigationToggle]);

	const showSubMenu = (index, exists) => {
		if (exists) {
			gsap.to(subMenuRefs.current[index].current, {
				duration: '0.2',
				height: 'auto',
			});
		}
	};

	const hideSubMenu = (index, exists) => {
		if (exists) {
			gsap.to(subMenuRefs.current[index].current, {
				duration: '0.2',
				height: '0px',
			});
		}
	};

	const toggleSubMenu = i => {
		if (subMenuRefs.current[i].current.clientHeight === 0) {
			showSubMenu(i, true);
			gsap.to(`#container_${i}`, {
				duration: '0.1',

				// transform: 'rotate(5deg) translateY(100%)',

				// color: Colors.white,
				backgroundColor: Colors.black,
			});
			primaryLinkRefs.current[i].current.getElementsByClassName('cross')[0].innerHTML = '-';
		} else {
			hideSubMenu(i, true);
			gsap.to(`#container_${i}`, {
				duration: '0.1',
				// transform: 'rotate(-5deg) translateY(-100%)',

				// color: Colors.black,
				backgroundColor: Colors.white,
			});
			primaryLinkRefs.current[i].current.getElementsByClassName('cross')[0].innerHTML = '+';
		}
	};

	const toggleNavigationMenu = () => {
		if (navigationToggle) {
			gsap.set(MenuContainerRef.current, { height: '100%', duration: 0, zIndex: Index.openFullScreenMenu });
			document.documentElement.style.overflow = 'hidden';
			gsap.to(MenuContainerRef.current, {
				duration: '0.5',
				opacity: 1,
				onComplete: () => {
					CustomOverDetection.Instance.add(CloseMenuRef.current, mouseOver, mouseOut, () => {}, Index.fullScreenMenu);
				},
			});
			primaryLinkRefs.current.forEach((item, index) => {
				gsap.to(item.current, { delay: 0.05 * index, duration: '0.2', left: '0vw', ease: Power0.easeNone });
			});
		} else {
			document.documentElement.style.removeProperty('overflow');
			var top = parseInt(document.body.style.top);
			if (top && top < 0) window.scrollTo(0, top * -1);
			primaryLinkRefs.current.forEach((item, index) => {
				gsap.to(item.current, {
					delay: 0.1 * index,
					duration: '0.2',
					left: '-100vw',
					onComplete: () => {
						if (index === primaryLinkRefs.current.length - 1) {
							gsap.to(MenuContainerRef.current, {
								duration: '0.2',
								opacity: 0,
								onComplete: () => {
									CustomOverDetection.Instance.remove(CloseMenuRef.current);

									gsap.to(MenuContainerRef.current, { height: '0vh', duration: 0, zIndex: Index.fullScreenMenu });
								},
							});
						}
					},
				});
			});
		}
	};

	const handleEscape = event => {
		if (event.key === 'Escape') {
			setNavigationToggle(false);
		}
	};

	const setLang = (newLang: Lang) => {
		// console.log('SETTING LANG', newLang, Language.getInstance().getCurrentLanguage());
		if (typeof window === 'undefined') return;
		if (newLang === Language.getInstance().getCurrentLanguage()) return;
		let link = LinkTools.getLink(window.location.href, newLang);
		let newUrl = link.url.origin + `/${newLang}` + link.pathWithoutLang.replace(/\/$/, '');
		window.location.href = newUrl + link.url.search + link.url.hash;
	};

	return (
		<MenuContainer ref={MenuContainerRef}>
			<MenuTop>
				<LogoContainer>
					<LogoLink to='/'>
						<SpanContainer>Kunsthal Spritten</SpanContainer>
					</LogoLink>
				</LogoContainer>
				<LanguageNav>
					<LangButton
						hl={Language.getInstance().getCurrentLanguage() === 'en'}
						ref={LangENRef}
						onClick={e => {
							setLang(Lang.EN);
						}}
					>
						EN
					</LangButton>
					/
					<LangButton
						hl={Language.getInstance().getCurrentLanguage() === 'da'}
						ref={LangDARef}
						onClick={e => {
							setLang(Lang.DA);
						}}
					>
						DA
					</LangButton>
				</LanguageNav>
				<CloseMenu ref={CloseMenuRef} onClick={e => setNavigationToggle(false)}>
					<CloseText>{getWord(CommonWords.close)}</CloseText>
				</CloseMenu>
			</MenuTop>
			<LanguageNavMobile>
				<LangButton
					hl={Language.getInstance().getCurrentLanguage() === 'en'}
					ref={LangENRef}
					onClick={e => {
						setLang(Lang.EN);
					}}
				>
					EN
				</LangButton>
				/
				<LangButton
					hl={Language.getInstance().getCurrentLanguage() === 'da'}
					ref={LangDARef}
					onClick={e => {
						setLang(Lang.DA);
					}}
				>
					DA
				</LangButton>
			</LanguageNavMobile>
			<ScrollableDiv>
				{state.topMenu.links.map((link, i) => (
					<MenuItem
						onMouseEnter={e => showSubMenu(i, link.subLinks.length > 0)}
						onMouseLeave={e => hideSubMenu(i, link.subLinks.length > 0)}
						submenu={link.subLinks.length}
						ref={primaryLinkRefs.current[i]}
						key={i}
					>
						{isMobile && link.subLinks.length > 0 ? (
							<NavContainer onClick={e => toggleSubMenu(i)} submenu={link.subLinks.length} id={'container_' + i}>
								<div
									style={{
										mixBlendMode: 'difference',
										display: 'flex',
										justifyContent: 'space-between',
										width: '100%',
									}}
								>
									{link.primaryLink.title}
									<ShowCross className={'cross'}>+</ShowCross>
								</div>
							</NavContainer>
						) : (
							<LinkContainer submenu={link.subLinks.length} data={link.primaryLink} id={'container_' + i}>
								<div style={{ mixBlendMode: 'difference' }}>{link.primaryLink.title}</div>
							</LinkContainer>
						)}
						<SubLinkContainer shown={link.subLinks.length > 0} ref={subMenuRefs.current[i]}>
							{isMobile && (
								<SubLink data={link.primaryLink} key={'0'}>
									{' '}
									{link.primaryLink.title}{' '}
								</SubLink>
							)}
							{link.subLinks.map((subLink, io) => (
								<SubLink data={subLink} key={io}>
									{subLink.title}
								</SubLink>
							))}
						</SubLinkContainer>
					</MenuItem>
				))}
			</ScrollableDiv>
			<Footer longFormat={false} copyright={true} />
		</MenuContainer>
	);
}
