import React from 'react';
import styled from 'styled-components';
import HTML from '../core/utils/HTML';
import { getWord } from '../core/utils/Language';
import Functions from '../style/Functions';
import { Breakpoints, Fonts } from '../style/Variables';
import CommonWords from '../types/CommonWords';

const Container = styled.div``;

const Label = styled.h3`
	margin: 0;
	font-family: ${Fonts.monoFamily};
	font-size: var(--fontSmall);
	line-height: 120%;
	font-weight: 400;

	text-transform: capitalize;
	margin-top: 23px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-top: 50px;
	}

	:first-child {
		margin-top: 0;
	}
`;
const Info = styled.p`
	margin: 4px 0;
	font-family: ${Fonts.sprittenFamily};
	font-size: var(--fontBig);
	/* font-weight: 700; */
	line-height: 120%;

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin: 8px 0;
	}

	p {
		margin: 0;
	}
`;

export default function TicketInfo({
	price,
	openingHours,
	location,
}: {
	price: string;
	openingHours: string;
	location: string;
}) {
	return (
		<Container>
			<Label>{getWord(CommonWords.entry)}</Label>
			<Info dangerouslySetInnerHTML={{ __html: HTML.clean(price) }} />
			<Label>{getWord(CommonWords.openingHours)}</Label>
			<Info dangerouslySetInnerHTML={{ __html: HTML.clean(openingHours) }} />
			<Label>{getWord(CommonWords.location)}</Label>
			<Info dangerouslySetInnerHTML={{ __html: HTML.clean(location) }} />
		</Container>
	);
}
