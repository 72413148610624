import React from 'react';
import styled from 'styled-components';
import HTML from '../core/utils/HTML';
import Functions from '../style/Functions';
import { Breakpoints, Fonts } from '../style/Variables';

const Description = styled.div<{ size: number }>`
	font-family: ${Fonts.monoFamilyLight};
	font-size: ${props => (props.size ? props.size + 'px' : Fonts.normal)};
	/* text-indent: 2em; */
	font-weight: 400;
	line-height: 140%;

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: var(--fontDefaultSize);
	}
`;
export default function RichTextModule({ text, size }: { text: string; size?: number }) {
	return <Description size={size} dangerouslySetInnerHTML={{ __html: HTML.clean(text) }} />;
}
