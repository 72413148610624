import React, { useContext } from 'react';
import styled from 'styled-components';
import context from '../core/context';
import { Lang } from '../core/utils/Language';
import MalteseCross from '../modules/MalteseCross';
import NavigationMenu from '../modules/SiteNavigation/NavigationMenu';
import Functions from '../style/Functions';
import { Page, PageContent } from '../style/Shared';
import { Breakpoints, Colors, Fonts, Index } from '../style/Variables';

const Container = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: ${Colors.darkGrey};
`;

const TextContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	font-size: 100px;
	font-family: ${Fonts.sprittenFamily};

	display: flex;
	justify-content: center;
	align-items: center;

	z-index: ${Index.overlay};

	mix-blend-mode: exclusion;
	color: ${Colors.white};

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 400px;
	}
`;

export default function ErrorPage() {
	const { state } = useContext(context);

	return (
		<Page>
			<NavigationMenu startMenuColor={Colors.black} type={'errorPage'} />
			<PageContent id='contentWrapper'>
				<Container className={'fullscreenElement'}>
					<MalteseCross cross={true} isSolid={true} text={''} />
					<TextContainer>404</TextContainer>
				</Container>
			</PageContent>
		</Page>
	);
}
