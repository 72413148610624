import { Lang, Language } from './Language';

export class DateFormats {
	private static _now: Date;
	public static now(): Date {
		if (!this._now) this._now = new Date();
		return this._now;
	}

	public static Weekday(datetime: Date, locale = this.Locale): string {
		var options: any = {
			month: '2-digit',
			day: '2-digit',
			timeZone: 'Europe/Copenhagen',
		};
		if (
			datetime.getFullYear() < this.now().getFullYear() ||
			(datetime.getFullYear() !== this.now().getFullYear() &&
				this.getMonthNum(datetime) - this.getMonthNum(this.now()) > 6)
		) {
			options = {
				...options,
				year: 'numeric',
			};
		} else {
			options = {
				...options,
				weekday: 'short',
			};
		}
		let string = datetime.toLocaleString(locale, options);

		return this.capitalize(string.replace(/\.(?=\d{1,2})/, '/').replace(/\.(?=\d{4})/, ' '));
	}

	public static Time(datetime: Date, locale = this.Locale): string {
		let string = datetime.toLocaleString(locale, {
			hour: '2-digit',
			minute: '2-digit',
			timeZone: 'Europe/Copenhagen',
		});

		// return string.replace(/.*(?=\d{2}[\.\:]\d{2}$)/, '');
		return string;
	}

	public static Date(datetime: Date, locale = this.Locale): string {
		let month = datetime.toLocaleString(locale, {
			month: 'short',
			timeZone: 'Europe/Copenhagen',
		});

		let day = datetime.toLocaleString(locale, {
			day: '2-digit',
			timeZone: 'Europe/Copenhagen',
		});

		let year = datetime.toLocaleString(locale, {
			year: 'numeric',
			timeZone: 'Europe/Copenhagen',
		});

		return month.replace('maj', 'maj.').replace('may', 'may.').replace(/\./g, ' ') + day + year;
	}

	public static Short(datetime: Date, locale = this.Locale): string {
		let string = datetime.toLocaleString(locale, {
			month: '2-digit',
			day: '2-digit',
			year: 'numeric',
			timeZone: 'Europe/Copenhagen',
		});

		return string.replace(/\.(?=\d{1,2})/, '.').replace(/\.(?=\d{4})/, ' ');
	}

	public static Month(datetime: Date, type: 'long' | 'short' | 'narrow' = 'long', locale = this.Locale): string {
		let string = datetime.toLocaleString(locale, {
			month: type,
			timeZone: 'Europe/Copenhagen',
		});

		return this.capitalize(string);
	}

	public static get Locale(): string {
		return Language.getInstance().isDanish() ? 'da-DK' : 'en-UK';
	}

	private static capitalize(str: string): string {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	private static getMonthNum(datetime: Date): number {
		return datetime.getFullYear() * 12 + datetime.getMonth();
	}
}

export interface DateType {
	startDate: string;
	endDate?: string;
}
