import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Colors, Fonts } from '../style/Variables';
import HTML from '../core/utils/HTML';
import gsap from 'gsap';

const Container = styled.div`
	b {
		font-weight: normal;
		position: relative;
		display: inline-block;
	}
`;

export default function TextWithHighlight(props: { text: string }) {
	const [toggleArray, setToggleArray] = useState([]);
	const finalString = props.text;
	const containerRef = useRef();

	useEffect(() => {
		//@ts-ignore
		setToggleArray(Array.from(containerRef.current.getElementsByTagName('b')));
	}, []);

	useEffect(() => {
		toggleArray.forEach(item => {
			gsap.set(item, {
				backgroundPosition: 'center 0%',
				backgroundImage:
					'linear-gradient(180deg, rgba(209,245,4, 0) 0%,rgba(209,245,4, 0) 33%,rgba(209,245,4, 1) 66%,rgba(209,245,4, 1) 100%)',
				backgroundSize: '100% 300%',
			});
		});

		const tweens = [];

		toggleArray.forEach((item, index) => {
			var tween = gsap.fromTo(
				item,
				{},
				{
					ease: 'linear',
					duration: '0.35',
					delay: 0.5,
					backgroundPosition: () => 'center 100%',
					scrollTrigger: {
						trigger: containerRef.current,
						start: 'center-=400px center',
						end: 'center-=400px center',
						scrub: false,
					},
				}
			);
			tweens.push(tween);
		});
	}, [toggleArray]);

	return (
		<Container ref={containerRef} className={'textHighlightContainer'}>
			<div dangerouslySetInnerHTML={{ __html: HTML.clean(finalString) }} />
		</Container>
	);
}
