import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef, useState } from 'react';
import styled from 'styled-components';
import { Index, Fonts, Colors } from '../style/Variables';
import Image from './Image';
import ReactPlayer from 'react-player';
import MalteseCross from './MalteseCross';
import CustomEvents, { Events } from '../core/CustomEvents';
import CustomOverDetection from '../core/utils/CustomOverDetection';
import { getWord } from '../core/utils/Language';
import CommonWords from '../types/CommonWords';

export enum VideoProvider {
	YOUTUBE = 'youtube',
	VIMEO = 'vimeo',
	FACEBOOK = 'facebook',
}

export interface ImperativeVideo {
	play: () => void;
	pause: () => void;
	isPlaying: () => boolean;
}

export interface VideoData {
	height: number;
	width: number;
	url: string;
	provider: VideoProvider;
	thumbnailUrl: string;
	providerUid: string;
	title: string;
}

export interface VideoProps {
	data: VideoData;
	autoplay?: boolean;
	controls?: boolean;
	muted?: boolean;
	loop?: boolean;
	className?: string;
	ratio?: number;
	closeModal?: Function;
	updateContainerOnPlay?: Function;
	eventListening?: boolean;
}

export const VideoWrapper = styled.div`
	display: block;
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: ${Colors.black};
	z-index: 60;
	top: 0px;
	left: 0px;
	display: flex;
	cursor: default;

	.react-player {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		width: 80%;
		height: 80%;
		transform: translate(-50%, -50%);
		opacity: 0;

		/* &:after {
			content: ' 🦄';
			width: 100%;
			height: 100%;
			position: relative;
			display: block;
			opacity: 0.3;
			background-color: yellow;
			transform: translateY(-100%);
		} */
	}
`;

const CloseButton = styled.button`
	position: absolute;
	top: 30px;
	right: 30px;
	z-index: 65;
	font-family: ${Fonts.sprittenFamily};
	font-size: var(--fontNotQuiteMedium);
	color: ${Colors.white};
`;

/* const Thumbnail = styled.div<{ isStarted: boolean }>`
	display: block;
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 2;
	opacity: ${({ isStarted }) => (isStarted ? '0' : '100')};
	pointer-events: ${({ isStarted }) => (isStarted ? 'none' : 'all')};
	transition: opacity 0.4s;
	cursor: pointer;

	svg {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 100px;
		width: 100px;
		max-height: 40%;
		max-width: 40%;
		transition: opacity 0.2s;
	}

	&:hover svg {
		opacity: 0.7;
	}
`;
 */
export default forwardRef(function VideoComponent(
	{
		data,
		autoplay = false,
		muted = false,
		loop = false,
		controls = true,
		className = null,
		ratio = null,
		closeModal,
		updateContainerOnPlay = null,
		eventListening = false,
	}: VideoProps,
	ref
) {
	const [isStarted, setIsStarted] = useState(false);
	const [isPlaying, setIsPlaying] = useState(autoplay);
	const wrapperRef = useRef();
	const closeButtonRef = useRef();

	useEffect(() => {}, [eventListening]);

	useEffect(() => {
		CustomOverDetection.Instance.add(wrapperRef.current, mouseOver, mouseOut, () => {}, Index.FullScreenvideoComponent);

		return () => {
			CustomOverDetection.Instance.remove(wrapperRef.current);
		};
	}, []);

	const mouseOver = () => {
		CustomEvents.dispatch(Events.HIDEMOUSE, {});
	};

	const mouseOut = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR);
	};

	const localCloseModal = () => {
		closeModal();
	};

	useImperativeHandle(ref, () => ({
		play() {
			setIsPlaying(true);
		},
		pause() {
			setIsPlaying(false);
		},
		isPlaying() {
			return isPlaying;
		},
	}));

	if (
		data.provider !== VideoProvider.YOUTUBE &&
		data.provider !== VideoProvider.VIMEO &&
		data.provider !== VideoProvider.FACEBOOK
	) {
		return <></>;
	}

	return (
		<VideoWrapper ref={wrapperRef} className={className}>
			<CloseButton ref={closeButtonRef} onClick={localCloseModal}>
				{getWord(CommonWords.close)}
			</CloseButton>
			{/* <Thumbnail
				isStarted={isStarted}
				ref={thumbnail}
				onClick={() => {
					if (!isStarted) setIsPlaying(true);
				}}
			>
				<Image src={data.thumbnailUrl} />
			</Thumbnail> */}
			<ReactPlayer
				className='react-player'
				height='80%'
				width='80%'
				playing={isPlaying}
				autoPlay={autoplay}
				muted={muted}
				loop={loop}
				pip={true}
				onError={err => {
					console.log(err);
				}}
				onEnded={() => {
					setIsPlaying(false);
					setIsStarted(false);
					if (updateContainerOnPlay) {
						updateContainerOnPlay(false);
					}
				}}
				onPlay={() => {
					if (updateContainerOnPlay) {
						updateContainerOnPlay(true);
					}
					setIsStarted(true);
				}}
				onPause={() => {
					if (updateContainerOnPlay) {
						updateContainerOnPlay(false);
					}
					setIsPlaying(false);
				}}
				controls={controls}
				config={{
					youtube: {
						playerVars: {
							modestBranding: 1,
							rel: 0,
						},
					},
					vimeo: {
						playerOptions: {
							byline: false,
							dnt: true,
							title: false,
							pip: true,
						},
					},
				}}
				url={data.url}
			/>
		</VideoWrapper>
	);
});
