import React, { useEffect, useState, useRef, createRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { Colors, Fonts, Breakpoints } from '../style/Variables';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';
import Functions from '../style/Functions';

const Container = styled.div`
	margin-top: var(--contentModuleGap);
	width: 100vw;
	position: relative;
	margin-left: -(var(--gridMargin));
	padding: 0;
	overflow: hidden;
`;

/* const Curtain = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
	transform-origin: bottom left;
	background-position: bottom left;
	background-image: linear-gradient(${Colors.white} 50%, ${Colors.white} 50%);
	background-size: 150% 150%;
	transform: rotate(0) translateY(0%);
	width: 150%;
	height: 100%;
	opacity: 1;
`; */

const TextContainer = styled.div`
	position: relative;
	white-space: initial;
	font-size: var(--fontHuge);
	width: 100%;
	font-family: ${Fonts.sprittenFamily};
	padding-left: var(--gridMargin);
	padding-right: var(--gridMargin);

	word-spacing: 100vw;
	&:nth-child(odd) {
		text-align: center;
	}

	&:nth-child(even) {
		text-align: center;
	}
	&:last-child {
		//margin-bottom: 125px;
	}
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-left: 0px;
		padding-right: 0px;
		font-size: var(--fontHuge);
		word-spacing: initial;
		text-align: center;
		font-variant-numeric: tabular-nums;
		overflow: visible;
		text-overflow: ellipsis;
		white-space: nowrap;
		&:nth-child(odd) {
			text-align: center;
		}

		&:nth-child(even) {
			text-align: center;
		}
	}
`;

export default function BigTextWithReveal({ data }: { data: { linesOfText: Array<any> } }) {
	const innerRef = React.useRef<HTMLDivElement>();
	const curtainRef = React.useRef<HTMLDivElement>();
	const elementsRef = useRef(data.linesOfText.map(() => useRef()));

	/* useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);

		const tweens = [];

		elementsRef.current.map((item, index) => {
			var tween = gsap.fromTo(
				item.current,
				{
					opacity: 0,
					//x: index % 2 == 0 ? '-100vw' : '100vw',
				},
				{
					ease: 'ease-in',
					duration: 2,
					opacity: () => 1,
					//x: () => '0vw',
					scrollTrigger: {
						trigger: innerRef.current,
						start: 'bottom-=30% bottom',
						end: 'bottom 50%+=100px',
						scrub: false,
					},
				}
			);
			tweens.push(tween);
		});

		var tween2 = gsap.fromTo(
			curtainRef.current,
			{
				transform: 'rotate(0) translateY(0%)',
			},
			{
				ease: 'ease-in',
				duration: 2,
				top: '100%',
				transform: 'rotate(25deg)',
				scrollTrigger: {
					trigger: innerRef.current,
					start: 'bottom-=30% bottom',
					end: 'bottom 50%+=100px',
					scrub: false,
				},
			}
		);

		return function () {
			tweens.forEach(tween => {
				if (tween) tween.kill();
				tween = null;
			});
			if (tween2) tween2.kill();
			tween2 = null;
		};
	}, []); */
	return (
		<Container ref={innerRef}>
			{/* 			<Curtain ref={curtainRef}></Curtain>
			 */}
			{data.linesOfText.map((line, index) => (
				<TextContainer ref={elementsRef.current[index]} key={index}>
					{line.lineOfText}
				</TextContainer>
			))}
		</Container>
	);
}
