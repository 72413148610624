import Bugsnag from '@bugsnag/js';
import CustomEvents, { Events } from '../CustomEvents';
import Loader from '../Loader';
import { SnailLog } from '../SnailLog';
import GoogleAnalytics from '../utils/GoogleAnalytics';
import Meta from '../utils/Meta';

export enum HistoryState {
	PUSH = 'push',
	REPLACE = 'replace',
}

export default class PageHandler {
	private _currentLink: string;
	private _uid: string;
	public get currentUrl() {
		return this._currentLink;
	}
	private _isLoadingPage: boolean;
	public get isLoadingPage() {
		return this._isLoadingPage;
	}

	private static _instance: PageHandler = null;
	public static getInstance(global?: any, uid?: string): PageHandler {
		if (this._instance === null) this._instance = new PageHandler(global, uid);

		return this._instance;
	}

	private _siteSwapQueue = [];
	private _gotoLock = false;

	private global: any;
	private _data: any;
	private _isTransitioned = false;
	private _hasConcealed = false;

	private _dataHasBeenLoaded = false;

	constructor(global: any, uid?: string) {
		this.global = global;

		if (!uid) {
			var now = new Date();
			now.setSeconds(0, 0);
			this._uid = now.getTime().toString(36);
		} else {
			this._uid = uid;
		}

		history.scrollRestoration = 'manual';

		this._currentLink = window.location.href;
		history.replaceState({}, this._currentLink, this._currentLink);

		window.addEventListener('popstate', this.onHashChanged);
	}

	public unmount = () => {
		window.removeEventListener('popstate', this.onHashChanged);
	};

	private onHashChanged = (e: PopStateEvent) => {
		this.goto(window.location.href, HistoryState.REPLACE);
	};

	public async goto(link: string, historyState: HistoryState = HistoryState.PUSH, historyData: any = {}) {
		this._dataHasBeenLoaded = false;
		if (this._currentLink === link) {
			CustomEvents.dispatch(Events.SAMEPAGE, link);
			return;
		} else {
			sessionStorage.setItem('scrollPosition_' + this._currentLink, window.scrollY.toString());
		}

		this._currentLink = link;
		this._isLoadingPage = true;
		CustomEvents.dispatch(Events.LOADINGCURSOR);

		if (!this._gotoLock) this.startLoadAnim();
		this._gotoLock = true;

		if (historyState === HistoryState.PUSH) {
			history.pushState(historyData || {}, link, link);
		} else if (historyState === HistoryState.REPLACE) {
			history.replaceState(historyData || {}, link, link);
		}

		var url = new URL(link);
		// const hej = await this.resolveAfter2Seconds();
		// console.log(hej);
		var data = await Loader.get(`/api/content?path=${encodeURIComponent(url.pathname)}&simple=1&uid=${this._uid}`);

		try {
			let json = JSON.parse(data);
			// if (historyData == {}) history.replaceState(json.content, link, link);
			this.handlePageLoaded(json, historyState === 'replace');

			this._isLoadingPage = false;
		} catch (e) {
			Bugsnag.notify(`Failed to parse new page (${url.pathname}) : ` + e);
			SnailLog.error(e);
		}
	}

	private handlePageLoaded = ({ data }: any, replace?: boolean) => {
		this._siteSwapQueue.push({ data: data, replace: replace });
		this._data = data;

		CustomEvents.dispatch(Events.PAGELOADED);
	};

	private _loadReady = false;
	private _transitionReady = false;

	private startLoadAnim = () => {
		if (this._gotoLock) {
			CustomEvents.dispatch(Events.LOADINGPAGE);
			CustomEvents.once(Events.TRANSITIONOUT, () => {
				this.finishAnim();
			});
		} else {
			CustomEvents.dispatch(Events.LOADINGPAGE);

			CustomEvents.once(Events.PAGELOADED, () => {
				this._loadReady = true;
				this.everyoneready();
			});

			CustomEvents.once(Events.TRANSITIONOUT, () => {
				this._transitionReady = true;
				this.everyoneready();
			});
		}
	};

	private everyoneready = () => {
		if (this._loadReady && this._transitionReady) {
			this.finishAnim();
			this._loadReady = false;
			this._transitionReady = false;
		}
	};

	private finishAnim = (replace?: boolean) => {
		this.insetPage(
			this._siteSwapQueue[this._siteSwapQueue.length - 1].data,
			this._siteSwapQueue[this._siteSwapQueue.length - 1].replace
		);

		if (this._siteSwapQueue.length > 2) this._siteSwapQueue.splice(0, this._siteSwapQueue.length - 2);

		CustomEvents.once(Events.TRANSITIONDONE, e => {
			CustomEvents.dispatch(Events.FINISHEDLOADING);

			if (e === undefined || this._siteSwapQueue[this._siteSwapQueue.length - 1].data.content === e.detail?.content) {
				this._gotoLock = false;
			} else {
				this.startLoadAnim();
			}
		});
	};

	private insetPage(data: any, scroll?: boolean) {
		CustomEvents.dispatch(Events.NEWPAGE, data);
		if (scroll && sessionStorage.getItem('scrollPosition_' + this._currentLink) !== undefined) {
			window.scrollTo(0, Number(sessionStorage.getItem('scrollPosition_' + this._currentLink)));
		} else {
			window.scrollTo(0, 0);
		}
		var meta = new Meta(data.content, this.global);
		meta.setMeta();
		// GoogleAnalytics.getInstance().pageView(meta);
	}
}
