export const Variables = {
	gridColumnsMobile: 4,
	gridMarginMobile: '12px',
	gridGutterMobile: '24px',
	gridColumns: 12,
	gridMargin: '40px',
	gridGutter: '40px',
	gridMarginUltra: '40px',
	gridGutterUltra: '40px',
};

export const Fonts = {
	defaultFamily: `Spezia Web, Arial, Roboto, sans-serif`,
	monoFamily: `Spezia Web SemiMono Medium, Arial, Roboto, sans-serif`,
	monoFamilyLight: `Spezia Web SemiMono Regular, Arial, Roboto, sans-serif`,
	sprittenFamily: `Kunsthal Spritten, Arial, Roboto, sans-serif`,

	ultraSmall: '9px',
	smaller: '12px',
	small: '14px',
	defaultSize: '16px',
	normal: '18px',
	notQuiteMediuem: '20px',
	medium: '24px',
	notQuiteBig: '32px',
	big: '36px',
	mediumBig: '40px',
	bigger: '48px',
	biggest: '64px',
	huge: '72px',
	massive: '96px',
};

export const Index = {
	historyCarousel: 5,
	mobileImageCarousel: 5,
	link: 10,
	signUpButton: 10,
	filter: 20,
	topMenu: 25,
	mobileFilter: 30,
	overlay: 35,
	subMenu: 40,
	navMenu: 45,
	footer: 48,

	scrolledNavMenu: 49,
	fullScreenMenu: 50,
	cookieBanner: 55,

	siteLoaderCurtain: 65,
	FullScreenvideoComponent: 67,
	openFullScreenMenu: 68,
	siteLoader: 70,
	debug: 100,
	cursor: 150,
};

export const Breakpoints = {
	tiny: '420px',
	mobile: '690px',
	tablet: '980px',
	laptop: '1300px',
	desktop: '1600px',
	ultra: '2200px',
};

export const Colors = {
	black: '#000',
	lightGrey: '#F5F5F5',
	grey: '#E6E6E6',
	darkGrey: '#CCCCCC',
	white: '#ffffff',
	offWhite: '#F7F3F2',
	yellow: '#D1F504',

	transition: 0.4,
};
export default Variables;
