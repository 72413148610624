import React, { useEffect, useReducer } from 'react';
import { Store, Provider } from './core/context';
import DebugModule from './modules/DebugModule';
import RouterSwitch from './RouterSwitch';
import Global from './style/Global';
import Normalize from './style/Normalize';
import Mode from './core/interface/Mode';
import { SnailLog } from './core/SnailLog';
import PageHandler from './core/PageSwitch/PageHandler';
import ResizeHandler from './core/utils/ResizeHandler';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import CookieBanner from './modules/CookieBanner';
import CustomCursor from './core/CustomCursor';

export default function App({ data = null }) {
	var ssrState = typeof window !== 'undefined' ? (window as any)._ssrState : data ? data : {};

	const [state, dispatch] = useReducer(Store.reducer, {
		server: ssrState?.server ?? Store.defaultState.server,
		language: ssrState?.language ?? Store.defaultState.language,
		global: ssrState?.global ?? Store.defaultState.global,
		footer: ssrState?.footer ?? Store.defaultState.footer,
		topMenu: ssrState?.topMenu ?? Store.defaultState.topMenu,
	});

	useEffect(() => {
		const handleError = (err: string | Event) => {
			if (ssrState?.server?.mode !== Mode.PROD || !ssrState?.server?.bugsnagKey) return;
			console.warn('Caught error: ' + err);
			Bugsnag.notify(err.toString());
			return false;
		};

		const handleRejection = (e: PromiseRejectionEvent) => {
			if (ssrState?.server?.mode !== Mode.PROD || !ssrState?.server?.bugsnagKey) return;
			console.warn('Caught rejection: ' + e.reason.message);
			Bugsnag.notify(e.reason.message);
			return false;
		};

		if (ssrState?.server?.mode === Mode.PROD && ssrState?.server?.bugsnagKey) {
			Bugsnag.start({
				apiKey: ssrState.server.bugsnagKey,
				plugins: [new BugsnagPluginReact(React)],
			});

			window.addEventListener('error', handleError);
			window.addEventListener('unhandledrejection', handleRejection);
		}

		if (ssrState?.server?.mode === Mode.PROD) {
			SnailLog.brand();
			PageHandler.getInstance(ssrState.global, ssrState?.server?.uid);
		} else {
			SnailLog.log(ssrState);
			PageHandler.getInstance(ssrState.global);
		}
		ResizeHandler.getInstance();

		return function () {
			window.removeEventListener('error', handleError);
			window.removeEventListener('unhandledrejection', handleRejection);

			PageHandler.getInstance().unmount();
			ResizeHandler.getInstance().unmount();
		};
	}, []);

	return (
		<Provider value={{ state, dispatch }}>
			<Normalize />
			<Global />

			{state.server.mode === Mode.DEV ? <DebugModule /> : <></>}

			{/* <TopMenu /> */}
			<CookieBanner />
			<RouterSwitch data={ssrState ? ssrState.content : null} />
			<CustomCursor />
		</Provider>
	);
}
