import Theme from '../interface/Theme';
import DefaultState from './DefaultState';

const Reducers = {
	setTheme: (state: typeof DefaultState, value: Theme): typeof DefaultState => {
		return {
			...state,
			theme: value,
		};
	},
	newPage: (state: typeof DefaultState, value: any): typeof DefaultState => {
		return {
			...state,
			...value,
		};
	},
};

export default Reducers;
