import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MagicModuleSwitcher from '../modules/MagicModuleSwitcher';
import { Page, PageContent } from '../style/Shared';
import NavigationMenu from '../modules/SiteNavigation/NavigationMenu';
import { Breakpoints, Colors } from '../style/Variables';
import CurtainRevealer from '../modules/CurtainRevealer';
import Functions from '../style/Functions';
import CurtainRevealMobile from '../modules/CurtainRevealMobile';
import ResizeHandler from '../core/utils/ResizeHandler';
import CustomEvents, { Events } from '../core/CustomEvents';

const ContentWrapper = styled(PageContent)``;

export default function ContentPage(props: { content?: any }) {
	const [currentMobile, setCurrentMobile] = useState<boolean>(undefined);
	useEffect(() => {
		onBreakpointChange();

		CustomEvents.listen(Events.BREAKPOINT, onBreakpointChange);

		return function () {
			CustomEvents.remove(Events.BREAKPOINT, onBreakpointChange);
		};
	}, []);

	const onBreakpointChange = () => {
		if (window.innerWidth <= parseInt(Breakpoints.mobile)) {
			setCurrentMobile(true);
		} else {
			setCurrentMobile(false);
		}
	};

	return (
		<Page>
			<NavigationMenu
				breadcrumb={props.content.slug === '' ? 'Frontpage' : props.content?.title}
				type={props.content?.modelId}
				startMenuColor={Colors.black}
			/>
			<ContentWrapper id='contentWrapper'>
				<MagicModuleSwitcher modules={props.content ? props.content.modules : []} />
			</ContentWrapper>
			{props.content.slug === '' ? currentMobile ? <CurtainRevealMobile /> : <CurtainRevealer /> : <></>}
		</Page>
	);
}
