import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Colors, Fonts, Index } from '../style/Variables';
import { gsap } from 'gsap';
import Link from '../core/PageSwitch/Link';
import GoogleAnalytics from '../core/utils/GoogleAnalytics';
import { Language } from '../core/utils/Language';
import context from '../core/context';
import CustomEvents, { Events } from '../core/CustomEvents';
import CustomOverDetection from '../core/utils/CustomOverDetection';

const Container = styled.div`
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: ${Index.cookieBanner};
	background-color: ${Colors.white};
	padding: 26px 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	border-top: 1px solid ${Colors.black};

	font-family: ${Fonts.monoFamily};
	font-size: var(--fontSmall);
	font-weight: 400;

	opacity: 0;
`;

const Text = styled.div`
	text-align: center;

	a {
		text-decoration: underline;
		transition: color 0s;
		&:hover,
		&:focus {
			background-color: ${Colors.yellow};
		}
	}
`;

const Buttons = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;
`;

const Button = styled.div`
	text-decoration: underline;

	&:hover,
	&:focus {
		background-color: ${Colors.yellow};
		cursor: none;
	}
	cursor: none;
`;

const AcceptButton = styled(Button)`
	font-weight: 600;
`;

export default function CookieBanner() {
	const { state } = useContext(context);
	const [isShowing, setIsShowing] = useState(false);
	var container = useRef<HTMLDivElement>();
	const acceptRef = useRef();
	const rejectRef = useRef();
	const readMoreRef = useRef();

	useEffect(() => {
		// THIS DOES NOT CHECK SEND ANYTHING TO GA
		setIsShowing(!GoogleAnalytics.getInstance().checkConsent());
	}, []);

	useEffect(() => {
		CustomOverDetection.Instance.add(acceptRef.current, mouseOver, mouseOut, () => {}, 45);
		CustomOverDetection.Instance.add(rejectRef.current, mouseOver, mouseOut, () => {}, 45);
		CustomOverDetection.Instance.add(readMoreRef.current, mouseOver, mouseOut, () => {}, 45);

		CustomEvents.listen(Events.REVEALED, show);

		return () => {
			CustomOverDetection.Instance.remove(acceptRef.current);
			CustomOverDetection.Instance.remove(rejectRef.current);
			CustomOverDetection.Instance.remove(readMoreRef.current);
		};
	}, []);

	const hide = () => {
		if (container.current)
			gsap.to(container.current, {
				opacity: -0.1,
				y: 20,
				duration: 0.3,
				onComplete: () => {
					setIsShowing(false);
				},
			});
	};

	const show = () => {
		if (container.current) {
			gsap.set(container.current, {
				y: 20,
			});
			gsap.to(container.current, {
				opacity: 1,
				y: 0,
				duration: 0.3,
			});
		}
	};

	const reject = () => {
		// THIS DOES NOT CHECK SEND ANYTHING TO GA

		GoogleAnalytics.getInstance().setConsent(false);
		hide();
	};

	const accept = () => {
		// THIS DOES NOT CHECK SEND ANYTHING TO GA

		GoogleAnalytics.getInstance().setConsent(true);
		hide();
	};

	const mouseOver = () => {
		CustomEvents.dispatch(Events.HIGHLIGHTCURSOR);
	};

	const mouseOut = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR);
	};

	if (!isShowing) return null;

	return (
		<Container ref={container}>
			<Text>
				{Language.getInstance().isDanish()
					? 'Denne hjemmeside bruger cookies til at sikre den bedste brugeroplevelse.'
					: 'This website uses cookies to ensure that you receive the best experience.'}{' '}
				{state.global.cookiePolicy ? (
					<span ref={readMoreRef}>
						<Link data={state.global.cookiePolicy}>
							{Language.getInstance().isDanish() ? 'Læs mere.' : 'Read more.'}
						</Link>
					</span>
				) : (
					<></>
				)}
			</Text>
			<Buttons>
				<Button ref={rejectRef} onClick={reject}>
					{Language.getInstance().isDanish() ? 'Afvis' : 'Reject'}
				</Button>

				<AcceptButton ref={acceptRef} onClick={accept}>
					{Language.getInstance().isDanish() ? 'Accepter' : 'Accept'}
				</AcceptButton>
			</Buttons>
		</Container>
	);
}
