import React from 'react';
import styled from 'styled-components';
import HTML from '../core/utils/HTML';
import { Colors, Fonts } from '../style/Variables';
import EffectImage from './EffectImage';
import Image, { ImageData } from './Image';

const ImageTextContainer = styled.div`
	width: 100%;
	margin-top: 20px;
	border-top: 1px solid ${Colors.black};

	font-family: ${Fonts.monoFamilyLight};
	font-size: var(--fontSmall);
	line-height: 140%;
`;
export default function ImageWithDescription({ image, text }: { image: ImageData; text: string }) {
	return (
		<>
			<Image data={image} />
			{text ? <ImageTextContainer dangerouslySetInnerHTML={{ __html: HTML.clean(text) }} /> : <></>}
		</>
	);
}
