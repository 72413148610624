import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import CustomEvents, { Events } from '../core/CustomEvents';
import Link from '../core/PageSwitch/Link';
import CustomOverDetection from '../core/utils/CustomOverDetection';
import { DateFormats } from '../core/utils/DateFormats';
import Functions from '../style/Functions';
import { Breakpoints, Colors, Fonts } from '../style/Variables';

const Container = styled(Link)`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
	overflow: hidden;

	${Functions.slideInHover(Colors.yellow)};

	&:focus,
	:hover {
		color: ${Colors.black};
		cursor: none;
	}
	/* 	div > span {
		overflow: hidden;
		position: relative;
		:before {
			transition: 0.4s transform ease, 0.5s opacity;

			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			background-position: bottom left;
			background-image: linear-gradient(${Colors.yellow} 50%, ${Colors.yellow} 50%);
			background-size: 150% 150%;

			width: 150%;
			height: 100%;
			opacity: 0;

			transform-origin: 0% 100%;
			transform: rotate(-5deg) translateY(-100%);
		}
		> * {
			z-index: 2;
			position: relative;
		}
	}

	:hover {
		div > span {
			:before {
				transform: rotate(0) translateY(0%);
				opacity: 1;
			}
		}
	} */

	${Functions.breakpoint(Breakpoints.mobile)} {
		flex-direction: row;
		gap: var(--gridGutter);
	}
`;

const DateContainer = styled.div`
	width: 100%;

	font-family: ${Fonts.monoFamily};
	font-size: var(--fontMedium);
	font-weight: 400;
	line-height: 100%;

	text-transform: capitalize;
	${Functions.breakpoint(Breakpoints.mobile)} {
		font-family: ${Fonts.sprittenFamily};
		/* font-weight: 700; */
		width: ${Functions.colFixed(2)};
	}
`;

const TitleContainer = styled.div`
	/* width: 100%; */

	font-family: ${Fonts.sprittenFamily};
	font-size: var(--fontBig);
	/* font-weight: 700; */
	line-height: 100%;

	overflow: hidden;

	${Functions.breakpoint(Breakpoints.mobile)} {
		/* width: ${Functions.colFixed(4)}; */
	}
`;

const RefContainer = styled.div``;

interface SmallInfoLinkProps {
	createdAt?: string;
	dates: any[];

	path?: string;
	slug: string;
	_prefix: string;
	title: string;
}

export default function SmallInfoLink({
	data,
	type,
}: {
	data: SmallInfoLinkProps;
	type: 'news' | 'events' | 'exhibitions';
}) {
	return (
		<RefContainer>
			<Container data={data}>
				<DateContainer>
					<span>
						{type === 'news'
							? DateFormats.Date(new Date(data.createdAt))
							: DateFormats.Date(new Date(data.dates[0].startDate))}
					</span>
				</DateContainer>
				<TitleContainer>
					<span>{data.title}</span>
				</TitleContainer>
			</Container>
		</RefContainer>
	);
}
