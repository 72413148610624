import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap, { Power1 } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const AppearContainer = styled.div``;

export default function AppearByFade({ ...props }) {
	const contentContainerRef = useRef<HTMLDivElement>();
	const containerRef = useRef<HTMLDivElement>();

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);

		let reveal = gsap.from(contentContainerRef.current, {
			scrollTrigger: { trigger: contentContainerRef.current, start: '20% 75%' },
			ease: Power1.easeOut,
			duration: 1,
			autoAlpha: 0,
		});

		return () => {
			if (reveal) {
				reveal.kill();
				reveal = undefined;
			}
		};
	}, []);

	return <AppearContainer ref={contentContainerRef}>{props.children}</AppearContainer>;
}
