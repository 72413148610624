import BrowserDetect from './BrowserDetect';
import gsap from 'gsap';

export default class CustomOverDetection {
	private _xPos: number = 0;
	private _yPos: number = 0;

	private _targets: Array<MouseOverItem> = [];
	private _targetsRaw: Array<HTMLElement> = [];

	private _target: MouseOverItem = null;

	private static _instance: CustomOverDetection;
	public static get Instance(): CustomOverDetection {
		if (this._instance == null) {
			this._instance = new CustomOverDetection();
		}
		return this._instance;
	}

	constructor() {
		if (this.isSupported()) {
			document.addEventListener('mousemove', this.onMouseMove);
			document.addEventListener('mouseenter', this.onMouseMove);
		}

		gsap.ticker.add(this.render);
		this.render(0, 0);
	}

	private onMouseMove = (e: MouseEvent) => {
		this._xPos = e.clientX;
		this._yPos = e.clientY;
	};

	private render = (deltaTime: number, totalTime: number) => {
		let allHits: Array<MouseOverItem> = [];
		let l = this._targets.length;
		let newTarget = null;
		let oldTarget = this._target;
		let localX;
		let localY;
		let rect;

		for (var i = 0; i < l; i += 1) {
			let target = this._targets[i];
			let targetElement = target.target;

			//console.log(targetElement);

			if (!targetElement) {
				continue;
			}

			rect = targetElement.getBoundingClientRect();

			localX = this._xPos - rect.left;
			localY = this._yPos - rect.top;

			//console.log('localX : ' + localX);
			//console.log('localY : ' + localY);

			// console.log(rect);

			if (localX > 0 && localY > 0 && localX < rect.width && localY < rect.height) {
				// targetElement.style.opacity = '0.5';
				let computedStyle = window.getComputedStyle(targetElement);

				if (computedStyle.display && computedStyle.pointerEvents !== 'none') {
					allHits.push(target);
				}

				// targetElement.style.opacity = '.5';
			} else {
				// targetElement.style.opacity = '1';
			}
		}

		// depth sorting
		l = allHits.length;
		if (l > 0) {
			if (l > 1) {
				allHits = allHits.sort(function (a, b) {
					return b.customSortIndex - a.customSortIndex;
				});

				// var debug = '';
				// for(i = 0; i < l; i += 1) {
				// 	target = allHits[i];
				// 	debug += target.customZIndex  + ',';
				// }
				// console.log(debug);
			}

			newTarget = allHits[0];
		}

		this._target = newTarget;

		if (this._target !== oldTarget) {
			if (oldTarget !== null) {
				rect = oldTarget.target.getBoundingClientRect();

				// send mouse pos data to target
				localX = this._xPos - rect.left;
				localY = this._yPos - rect.top;
				oldTarget.mouseOutCallback(localX, localY);
			}

			if (this._target !== null) {
				this._target.mouseOverCallback();
			}
		}

		if (this._target !== null) {
			rect = this._target.target.getBoundingClientRect();

			// send mouse pos data to target
			localX = this._xPos - rect.left;
			localY = this._yPos - rect.top;
			this._target.moveCallback(localX, localY);
		}
	};

	public add(
		target: HTMLElement,
		mouseOverCallback: Function,
		mouseOutCallback: (localX: number, localY: number) => void,
		moveCallback: (localX: number, localY: number) => void,
		customSortIndex: number
	): void {
		// console.log('MousePointer.add();');
		this._targetsRaw.push(target);
		this._targets.push(new MouseOverItem(target, mouseOverCallback, mouseOutCallback, moveCallback, customSortIndex));
	}

	public remove(target: HTMLElement): void {
		let index = this._targetsRaw.indexOf(target);

		if (index !== -1) {
			this._targetsRaw.splice(index, 1);
			this._targets.splice(index, 1);
		}
	}

	public isSupported(): boolean {
		// return false;

		if (BrowserDetect.isMobile()) {
			return false;
		}

		if (BrowserDetect.isTablet()) {
			return false;
		}

		return true;
	}
}

class MouseOverItem {
	public target: HTMLElement;
	public mouseOverCallback: Function;
	public mouseOutCallback: (localX: number, localY: number) => void;
	public moveCallback: (localX: number, localY: number) => void;
	public customSortIndex: number;

	constructor(
		target: HTMLElement,
		mouseOverCallback: Function,
		mouseOutCallback: (localX: number, localY: number) => void,
		moveCallback: (localX: number, localY: number) => void,
		customSortIndex: number
	) {
		this.target = target;
		this.mouseOverCallback = mouseOverCallback;
		this.mouseOutCallback = mouseOutCallback;
		this.moveCallback = moveCallback;
		this.customSortIndex = customSortIndex;
	}
}
