import { defaultLang, Lang, Language, languages } from './Language';

export interface ILink {
	isLocal: boolean;
	path: string;
	full: string;
	url: URL;
	lang: Lang;
	pathWithoutLang: string;
	hasLang: boolean;
}

export default class LinkTools {
	public static getDefaultUrl(): string {
		if (typeof window !== 'undefined') return window.location.origin;

		if (typeof process !== 'undefined' && !!process?.env && !!process?.env['DOMAIN']) {
			var domain = process?.env['DOMAIN'];
			if (!/^https?:\/\//.test(domain)) domain = 'http://' + domain;
			var match = domain.match(/^https?:\/\/[a-zæøå\.\-_]+\.[a-z]+/i);
			if (match) return match[0];
			else return 'http://localhost:3000';
		}

		return 'http://localhost:3000';
	}

	public static getLink(link: string, forceLang?: Lang): ILink {
		var url = new URL(link, this.getDefaultUrl());
		var hasLang = false;
		var cleanPath = this.cleanPath(url);
		var pathWithoutLang = cleanPath;

		var isLocal = this.isUrlLocal(url);

		if (isLocal) {
			let split = cleanPath.split('/').filter(s => s);
			let lang = languages.find(l => `${l}` === split[0]);
			if (!lang) {
				lang = defaultLang;
			} else {
				hasLang = true;
				split.shift();
				pathWithoutLang = this.cleanPath(url, split.join('/'));
			}

			let currentLang = forceLang == null ? Language.getInstance().getCurrentLanguage() : forceLang;
			if (currentLang === defaultLang) {
				cleanPath = pathWithoutLang;
			} else {
				cleanPath = '/' + currentLang + pathWithoutLang.replace(/\/$/, '');
			}
		}

		return {
			isLocal,
			path: cleanPath + url.search + url.hash,
			full: this.getUrlFull(url, isLocal),
			lang: this.getUrlLang(url),
			url,
			pathWithoutLang: pathWithoutLang + url.search + url.hash,
			hasLang,
		};
	}

	private static cleanPath(url: URL, path?: string): string {
		if (!url) return '';
		let cleanPath = (path == null ? url.pathname : path).replace(/\/$/, '').replace(/\/\//g, '/');
		if (cleanPath[0] !== '/') cleanPath = '/' + cleanPath;
		return cleanPath;
	}

	private static isUrlLocal(url: URL): boolean {
		if (!url) return false;
		return (typeof window !== 'undefined' ? window.location.origin : this.getDefaultUrl()) === url.origin;
	}

	private static getUrlFull(url: URL, isLocal: boolean): string {
		if (!url) return '';
		return url.origin + url.pathname.replace(/\/$/g, '').replace(/\/\//g, '/') + url.search + url.hash;
	}

	private static getUrlLang(url: URL): Lang {
		let split = url.pathname.split('/').filter(s => s);
		let lang = languages.find(l => `${l}` === split[0]);
		if (!lang) lang = defaultLang;
		return lang;
	}
}
