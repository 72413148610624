import React from 'react';
import styled from 'styled-components';
import Functions from '../style/Functions';
import { Breakpoints, Colors, Fonts } from '../style/Variables';
import Link from '../core/PageSwitch/Link';

const Container = styled.div``;

const TitleLink = styled(Link)<{ hasSuperScript: boolean }>`
	margin: 0;
	font-family: ${Fonts.sprittenFamily};
	font-size: var(--fontHuge);
	/* font-weight: 700; */
	margin-inline-start: 0;
	transition: all 0.2s linear 0s;
	line-height: ${props => (props.hasSuperScript ? '0.8' : '1.2')};

	:hover {
		background-color: ${Colors.yellow};
	}
`;

const Title = styled.h1<{ hasSuperScript: boolean }>`
	margin: 0;
	font-family: ${Fonts.sprittenFamily};
	font-size: var(--fontHuge);
	font-weight: 400;
	margin-inline-start: 0;

	line-height: ${props => (props.hasSuperScript ? '0.8' : '1.2')};

	overflow-wrap: break-word;
	-ms-word-wrap: break-word;
	word-wrap: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
`;

const Subtitle = styled.h2`
	margin: 0;
	margin-top: 50px;
	font-size: var(--fontBigger);
	/* font-weight: 700; */
	line-height: 140%;
	font-family: ${Fonts.sprittenFamily};

	/* margin-left: ${Functions.col(1, 12, 1)}; */
	width: 100%;

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-top: 100px;

		margin-left: ${Functions.col(1, 12, 1)};
		width: ${Functions.col(8, 12)};
	}
`;

const ItemNumber = styled.span`
	font-family: ${Fonts.monoFamily};
	font-size: var(--fontDefaultSize);
	font-weight: 400;
	vertical-align: top;
	/* font-size: 25%; */
	/* font-size: 33%; */

	margin-left: 2px;

	top: 0.08em;
	line-height: 1;

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-left: 4px;
	}
	${Functions.breakpoint(Breakpoints.tablet)} {
		/* font-size: 25%; */
		margin-left: 6px;
	}
`;

export default function SectionHeader({
	title,
	subtitle,
	numberOfItems,
	headerLink,
}: {
	title?: string;
	subtitle?: string;
	numberOfItems?: number;
	headerLink?: any;
}) {
	return (
		<Container>
			{title && headerLink && (
				<TitleLink data={headerLink} hasSuperScript={numberOfItems !== undefined}>
					{title}
					<ItemNumber>{numberOfItems}</ItemNumber>
				</TitleLink>
			)}
			{title && (headerLink === undefined || headerLink === null) && (
				<Title hasSuperScript={numberOfItems !== undefined}>
					{title}
					<ItemNumber>{numberOfItems}</ItemNumber>
				</Title>
			)}
			{subtitle && <Subtitle>{subtitle}</Subtitle>}
		</Container>
	);
}
