import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const Icon = styled.div`
	position: relative;
	width: fit-content;
`;

const Item = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export default function CollapsiblePlus({ isOpen }: { isOpen: boolean }) {
	const plusRef = useRef<HTMLDivElement>(undefined);
	const minusRef = useRef<HTMLDivElement>(undefined);

	useEffect(() => {
		if (isOpen) {
			gsap.to(plusRef.current, {
				duration: 0.1,
				// scale: 0,
				autoAlpha: 0,
			});
			gsap.to(minusRef.current, {
				duration: 0.1,
				scale: 1,
				autoAlpha: 1,
			});
		} else {
			gsap.to(minusRef.current, {
				duration: 0.1,
				// scale: 0,
				autoAlpha: 0,
			});
			gsap.to(plusRef.current, {
				duration: 0.1,
				scale: 1,
				autoAlpha: 1,
			});
		}
	}, [isOpen]);

	return (
		<Icon>
			<Item ref={plusRef}>+</Item>
			<Item ref={minusRef} style={{ position: 'absolute', opacity: 0 }}>
				-
			</Item>
		</Icon>
	);
}
