export class ArrayUtils {
	public static removeElement<T>(array: T[], element: T): T[] {
		const index = array.indexOf(element);

		if (index >= 0) {
			return array.splice(index, 1);
		}

		return array;
	}

	public static lastElement<T>(array: T[]): T {
		if (array.length <= 0) {
			return null;
		}

		return array[array.length - 1];
	}

	public static randomElement<T>(array: T[]): T {
		const randomIndex = Math.floor(Math.random() * array.length);
		return array[randomIndex];
	}

	public static randomIndex(array: any[]) {
		return Math.floor(Math.random() * array.length);
	}

	public static takeRandom<T>(array: T[]): T {
		const randomIndex = Math.floor(Math.random() * array.length);

		return array.splice(randomIndex, 1)[0];
	}

	public static min<T>(list: T[], selector: (T) => number): number {
		let minValue = Infinity;

		list.forEach(item => {
			const value = selector(item);

			if (value < minValue) {
				minValue = value;
			}
		});

		return minValue;
	}

	public static max<T>(list: T[], selector: (T) => number): number {
		let maxValue = -Infinity;

		list.forEach(item => {
			const value = selector(item);

			if (value > maxValue) {
				maxValue = value;
			}
		});

		return maxValue;
	}
}
