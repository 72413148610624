import React, {
	AnchorHTMLAttributes,
	DetailedHTMLProps,
	KeyboardEvent,
	MouseEvent,
	Touch,
	TouchEvent,
	useEffect,
	useRef,
} from 'react';
import LinkTools from '../utils/LinkTools';
import PageHandler from './PageHandler';
import { Index } from '../../style/Variables';
import CustomEvents, { Events } from './../CustomEvents';
import CustomOverDetection from '../../core/utils/CustomOverDetection';

interface LinkProps extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
	to?: string;
	data?: {
		path?: string;
		slug: string;
		_prefix: string;
		title: string;
	};
	customZIndex?: number;
}

export default function Link({ to, data, customZIndex, ...props }: LinkProps) {
	const LinkContainerRef = useRef();

	useEffect(() => {
		CustomOverDetection.Instance.add(
			LinkContainerRef.current,
			mouseOver,
			mouseOut,
			() => {},
			customZIndex ? customZIndex : Index.link
		);
		return () => {
			CustomOverDetection.Instance.remove(LinkContainerRef.current);
		};
	}, []);

	const mouseOver = () => {
		CustomEvents.dispatch(Events.HIGHLIGHTCURSOR, {});
	};

	const mouseOut = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR);
	};

	let touchId: number = null;
	let isCoolingDown = false;

	const link = LinkTools.getLink(
		data && data.slug && data._prefix ? data._prefix + data.slug : data && data.path ? data.path : props.href || to
	);

	const openLink = () => {
		if (isCoolingDown) return;
		cooldown();

		if (link.isLocal) {
			PageHandler.getInstance().goto(link.url.href);
		} else {
			if (/^(mailto|tel):/.test(link.url.href)) {
				window.location.href = link.url.href;
			} else {
				window.open(link.full, '_blank');
			}
		}
	};

	const click = (e: MouseEvent) => {
		if (!e) return;

		e.preventDefault();
		e.stopPropagation();

		if (e.ctrlKey) {
			window.open(link.full, '_blank');
		} else {
			openLink();
		}
	};

	const keydown = (e: KeyboardEvent) => {
		if (!e) return;
		if (e.key === 'Enter') {
			e.preventDefault();
			e.stopPropagation();

			if (e.ctrlKey) {
				window.open(link.full, '_blank');
			} else {
				openLink();
			}
		}
	};

	var moveDist = 0;
	var lastTouchXPos = 0;
	var lastTouchYPos = 0;

	const touchstart = (e: TouchEvent) => {
		let touch: Touch = e.touches[0];

		touchId = touch.identifier;

		moveDist = 0;
		lastTouchXPos = touch.screenX;
		lastTouchYPos = touch.screenY;
	};

	const touchmove = (e: TouchEvent) => {
		let touch: Touch = e.touches[0];

		if (touch.identifier !== touchId) {
			return;
		}

		let deltaX: number = lastTouchXPos - touch.screenX;
		let deltaY: number = lastTouchYPos - touch.screenY;

		moveDist += Math.sqrt(deltaX * deltaX + deltaY * deltaY);

		lastTouchXPos = touch.screenX;
		lastTouchYPos = touch.screenY;
	};

	const touchend = (e: TouchEvent) => {
		if (moveDist <= 5 && touchId !== null) openLink();

		touchId = null;
	};

	const cooldown = (t: number = 200) => {
		isCoolingDown = true;

		setTimeout(() => {
			isCoolingDown = false;
		}, t);
	};

	return (
		<a
			ref={LinkContainerRef}
			{...props}
			href={link.isLocal ? link.path : link.full}
			target={props.target || '_self'}
			tabIndex={props.tabIndex || 0}
			onClick={click}
			onKeyDown={keydown}
			onTouchStart={touchstart}
			onTouchMove={touchmove}
			onTouchEnd={touchend}
			title={props.title || (data && data.title ? data.title : null)}
		>
			{props.children}
		</a>
	);
}
