import React from 'react';
import styled from 'styled-components';
import Functions from '../style/Functions';
import { Breakpoints } from '../style/Variables';
import RichTextModule from './RichTextModule';
import SectionHeader from './SectionHeader';

const Container = styled.div`
	width: 100%;
	margin-top: var(--contentModuleGap);
`;

const Wrapper = styled.div`
	margin-top: 40px;
	width: 100%;
	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${Functions.col(5, 12)};
		margin-left: ${Functions.col(1, 12, 1)};
	}
`;

export default function TextModule({ data }: { data: { textBlock: { text: string }; title: string } }) {
	return (
		<Container>
			<SectionHeader title={data.title}></SectionHeader>
			<Wrapper>
				<RichTextModule text={data.textBlock[0].text}></RichTextModule>
			</Wrapper>
		</Container>
	);
}
