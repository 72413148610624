import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Image, { ImageData } from './Image';
import gsap, { Power1 } from 'gsap';
import { VideoData } from './VideoComponent';
import Functions from '../style/Functions';
import { Breakpoints } from '../style/Variables';

const StyledImage = styled(Image)``;

const ImageContainer = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	visibility: hidden;
`;

const ImageGallery = styled.div`
	width: calc(100% + var(--gridMargin) * 2);
	height: 100vh;
	position: relative;
`;

const ThumbnailVideo = styled.video`
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 85vh;
	object-fit: cover;

	${Functions.breakpoint(Breakpoints.mobile)} {
		height: 100%;
	}
`;

export default function RevealHero({
	data,
}: {
	data: { mediaType: boolean; imageGallery: ImageData[]; video: VideoData };
}) {
	var imgs: HTMLDivElement[] = [];
	const next = 5; // time to change
	const fade = 1.5; // fade time

	useEffect(() => {
		imgs = gsap.utils.toArray('#heroimage');

		let revealAnim = gsap.timeline({});

		if (imgs.length > 0) gsap.set(imgs[0], { autoAlpha: 1 });
		if (imgs.length > 1) gsap.delayedCall(next, crossfade);
		return () => {
			if (revealAnim != undefined) revealAnim.kill();
		};
	}, []);

	function crossfade() {
		const action = gsap
			.timeline()
			.to(imgs[0], { autoAlpha: 0, duration: fade })
			.to(imgs[1], { autoAlpha: 1, duration: fade }, 0);

		imgs.push(imgs.shift());
		// start endless run
		gsap.delayedCall(next, crossfade);
	}

	return (
		<>
			{data.mediaType && data.video ? (
				<ImageGallery>
					<ThumbnailVideo src={data.video.url} autoPlay loop muted playsInline />
				</ImageGallery>
			) : (
				<ImageGallery>
					{data.imageGallery.map((item, i) => {
						return (
							<ImageContainer key={i} id='heroimage'>
								<StyledImage src={item.url}></StyledImage>
							</ImageContainer>
						);
					})}
				</ImageGallery>
			)}
		</>
	);
}
