import React, { useContext, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Fonts, Colors, Breakpoints } from '../style/Variables';
import Functions from '../style/Functions';
import OnHoverImage from './OnHoverImage';
import SectionHeader from './SectionHeader';
import Image from './Image';

const Container = styled.div`
	width: 100vw;
	position: relative;
	margin-left: -(var(--gridMargin));
	padding: 0;
	display: flex;
	/* justify-content: center;
	align-items: center;
	overflow: hidden;
	max-height: 100vh;
	aspect-ratio: 1920/900;
	background-color: #cccccc; */
	flex-direction: column;
	font-family: ${Fonts.monoFamily};
	margin-top: var(--contentModuleGap);
`;

const Employees = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
	border-top: 1px solid ${Colors.black};
	padding-top: 132px;
	flex-direction: row;
	padding-left: var(--gridMargin);
	padding-right: var(--gridMargin);
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-left: ${Functions.colFixed(1, 2)};
		padding-right: ${Functions.colFixed(1, 2)};
		flex-direction: row;
		flex-wrap: wrap;
		gap: var(--gridGutter);
	}
`;

const Employee = styled.span`
	width: ${Functions.colFixed(3, 1)};
	position: relative;
	height: auto;
	padding-bottom: 40px;
	width: 100%;
	display: block;
	font-family: ${Fonts.monoFamily};

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-bottom: 132px;
		width: ${Functions.colFixed(3)};
		/* flex: 0 0 33.333333%; */
	}
`;

const EmployeeData = styled.div`
	position: relative;
	display: inline-block;
	width: 100%;
	transition: 0.2s all ease 0s;
	:hover {
		background-color: ${Colors.yellow};
	}
`;

const MovedSectionHeader = styled.div`
	padding-left: ${Functions.colFixed(0, 2)};
`;

const DataContainer = styled.span`
	line-height: 20px;
	font-family: ${Fonts.monoFamilyLight};

	font-size: var(--fontNotQuiteMedium);
	word-break: break-word;
	white-space: pre-wrap;
	overflow: hidden;
	:first-of-type {
		font-family: ${Fonts.monoFamily};
	}
	display: none;
	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: var(--fontDefaultSize);
		display: block;
	}
`;

const DataContainerMobile = styled(DataContainer)`
	display: block;

	${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	}
`;

const MobileImage = styled(Image)`
	max-height: 100%;
	width: auto;
	padding-bottom: 20px;
	img {
		height: auto;
		width: ${Functions.colFixed(6, 0)};
		aspect-ratio: 120/160;
	}
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	}
`;

const StyledOnHover = styled(OnHoverImage)`
	height: fit-content;
	width: fit-content;
	padding: 0;

	:hover {
		background-color: ${Colors.yellow};
	}
`;

export default function EmployeeList({ data }: { data: { headline: string; listOfEmployees: any } }) {
	return (
		<Container>
			<MovedSectionHeader>
				<SectionHeader title={data.headline}></SectionHeader>
			</MovedSectionHeader>
			<Employees>
				{data.listOfEmployees.map((item, i) => (
					<Employee key={i}>
						<EmployeeData>
							{item.image?.url && (
								<StyledOnHover imageUrl={item.image.url}>
									<DataContainer>
										{item.name}
										<br />
									</DataContainer>
									<DataContainer>
										{item.title}
										<br />
									</DataContainer>
									<DataContainer>
										<a href={`mailto:${item.mail}`}>{item.mail}</a>
										<br />
									</DataContainer>
									<DataContainer>
										{item.phone.substring(0, 4) + ' ' + item.phone.substring(4, item.phone.length)}
									</DataContainer>
								</StyledOnHover>
							)}
							{item.image?.url && (
								<>
									<MobileImage data={item.image}></MobileImage>
									<DataContainerMobile>
										{item.name}
										<br />
									</DataContainerMobile>
									<DataContainerMobile>
										{item.title}
										<br />
									</DataContainerMobile>
									<DataContainerMobile>
										<a href={`mailto:${item.mail}`}>{item.mail}</a>
										<br />
									</DataContainerMobile>
									<DataContainerMobile>
										{item.phone.substring(0, 4) + ' ' + item.phone.substring(4, item.phone.length)}
									</DataContainerMobile>
								</>
							)}
						</EmployeeData>
					</Employee>
				))}
			</Employees>
		</Container>
	);
}
