const CommonWords = {
	weekday: {
		monday: {
			da: 'mandag',
			en: 'Monday',
		},
		tuesday: {
			da: 'tirsdag',
			en: 'Tuesday',
		},
		wednesday: {
			da: 'onsdag',
			en: 'Wednesday',
		},
		thursday: {
			da: 'torsdag',
			en: 'Thursday',
		},
		friday: {
			da: 'fredag',
			en: 'Friday',
		},
		saturday: {
			da: 'lørdag',
			en: 'Saturday',
		},
		sunday: {
			da: 'søndag',
			en: 'Sunday',
		},
	},
	month: {
		january: {
			da: 'Januar',
			en: 'January',
		},
		feburary: {
			da: 'Febuar',
			en: 'Feburary',
		},
		march: {
			da: 'Marts',
			en: 'March',
		},
		april: {
			da: 'April',
			en: 'April',
		},
		may: {
			da: 'Maj',
			en: 'May',
		},
		june: {
			da: 'Juni',
			en: 'June',
		},
		july: {
			da: 'Juli',
			en: 'July',
		},
		august: {
			da: 'August',
			en: 'August',
		},
		september: {
			da: 'September',
			en: 'September',
		},
		october: {
			da: 'Oktober',
			en: 'October',
		},
		november: {
			da: 'November',
			en: 'November',
		},
		december: {
			da: 'December',
			en: 'December',
		},
	},
	allExhibitions: {
		da: 'alle udstillinger',
		en: 'all exhibitions',
	},
	allEvents: {
		da: 'alle events',
		en: 'all events',
	},
	event: {
		da: 'oplevelser',
		en: 'events',
	},
	exhibition: {
		da: 'udstilling',
		en: 'exhibition',
	},
	news: {
		da: 'nyheder',
		en: 'news',
	},
	pressRelease: {
		da: 'pressemeddelelse',
		en: 'press release',
	},
	tickets: {
		da: 'billetter',
		en: 'tickets',
	},
	share: {
		da: 'del',
		en: 'share',
	},
	entry: {
		da: 'entré',
		en: 'entry',
	},
	openingHours: {
		da: 'åbningstider',
		en: 'opening hours',
	},
	location: {
		da: 'lokation',
		en: 'Location',
	},
	digital: {
		da: 'digital',
		en: 'digital',
	},
	current: {
		da: 'aktuelle',
		en: 'current',
	},
	upcoming: {
		da: 'kommende',
		en: 'upcoming',
	},
	archived: {
		da: 'arkiverede',
		en: 'archived',
	},
	contact: {
		da: 'kontakt',
		en: 'contact',
	},
	follow: {
		da: 'følg',
		en: 'follow',
	},
	close: {
		da: 'luk',
		en: 'close',
	},
	shareMessage: {
		da: 'Tjek det her utrolige event fra Kunsthal Spritten ud på',
		en: 'Checkout this amazing event from Kunsthal Spritten at',
	},
	writtenBy: {
		da: 'skrevet af',
		en: 'written by',
	},
	readMore: {
		da: 'læs mere',
		en: 'read more',
	},
	readNext: {
		da: 'læs flere',
		en: 'read next',
	},
	moreExhibitions: {
		da: 'flere udstillinger',
		en: 'more exhibitions',
	},
	moreEvents: { da: 'flere events', en: 'more events' },
	program: {
		da: 'program',
		en: 'program',
	},
};

export default CommonWords;
