import React from 'react';
import styled from 'styled-components';
import Functions from '../../style/Functions';
import { Breakpoints, Colors, Fonts } from '../../style/Variables';

const Container = styled.div`
	border: 2px solid ${Colors.yellow};
	padding: 18px 30px;
	width: fit-content;

	box-sizing: border-box;

	font-family: ${Fonts.monoFamily};
	font-size: 12px;
	line-height: 120%;

	text-transform: capitalize;

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: var(--fontNormal);

		padding: 18px 57px;
	}

	transition: background-color 0.1s linear 0s;

	${Functions.slideInHover(Colors.yellow)}/* &:hover {
		background-color: ${Colors.yellow};
	} */
`;

export default function StyledButton({ text }: { text: string }) {
	return (
		<Container>
			<span>&rarr; {text}</span>
		</Container>
	);
}
