import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DateFormats, DateType } from '../core/utils/DateFormats';
import { Breakpoints, Colors, Fonts } from '../style/Variables';
import Image, { ImageData } from './Image';
import Functions from '../style/Functions';
import DownArrow from '../assets/svgs/down-arrow.svg';
import gsap from 'gsap';
import { Elastic, Power1, ScrollToPlugin } from 'gsap/all';

const Container = styled.div<{ isFullBleed: boolean }>`
	position: relative;

	width: 100vw;

	height: 100vh;

	background-color: ${props => (props.isFullBleed ? 'none' : `${Colors.white}`)};

	${Functions.breakpoint(Breakpoints.mobile, 'max')} {
		margin-left: calc(-1 * var(--gridMargin));
		padding-left: var(--gridMargin);
	}
`;

const StyledImage = styled.div<{ isFullBleed: boolean }>`
	position: absolute;
	box-sizing: border-box;

	box-sizing: border-box;

	width: ${props => (props.isFullBleed ? '100%' : `calc(100% - var(--gridMargin) * 2)`)};
	height: ${props => (props.isFullBleed ? '100%' : `60%`)};

	bottom: ${props => (props.isFullBleed ? '0' : `7%`)};
	${Functions.breakpoint(Breakpoints.mobile)} {
		left: ${props => (props.isFullBleed ? '0' : `${Functions.colFixed(7, 1)}`)};
		width: ${props => (props.isFullBleed ? '100%' : `${Functions.colFixed(4)}`)};
		top: ${props => (props.isFullBleed ? '0' : `15%`)};
		height: ${props => (props.isFullBleed ? '100%' : `70%`)};
	}
`;

const InfoContainer = styled.div<{ isDarkTheme: boolean; isFullBleed: boolean }>`
	/* position: ${props => (props.isFullBleed ? 'sticky' : 'relative')}; */
	position: sticky;
	color: ${props => (props.isDarkTheme ? Colors.white : Colors.black)};

	top: 14%;
	padding-bottom: 10%;

	margin-left: ${props => (props.isFullBleed ? 'var(--gridMargin)' : 0)};

	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${props => (props.isFullBleed ? 'auto' : Functions.colFixed(7))};
		${Functions.hyphen()}

		top: 17%;
		padding-bottom: 10%;
	}
`;

const DateHolder = styled.div`
	font-family: ${Fonts.monoFamily};
	font-size: var(--fontDefaultSize);
`;

const Title = styled.h1`
	margin: 0;
	margin-top: 12px;

	font-family: ${Fonts.sprittenFamily};

	line-height: 100%;
	font-size: 20px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-top: 40px;
		font-size: var(--fontBiggest);
	}

	${Functions.breakpoint(Breakpoints.mobile, 'max')} {
		${Functions.limitText(2)};
	}
`;

const Subtitle = styled.h2`
	margin: 0;
	margin-top: 14px;

	font-family: ${Fonts.monoFamily};
	font-size: var(--fontNormalSize);

	line-height: 120%;

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-top: 20px;
	}
`;

const ArrowContainer = styled.div<{ isDarkTheme: boolean; isFullBleed: boolean }>`
	path {
		fill: ${props => (props.isDarkTheme ? Colors.white : Colors.black)};
	}

	margin-left: ${props => (props.isFullBleed ? 'var(--gridMargin)' : 0)};

	width: 57px;
	height: 64px;
	position: absolute;
	left: 0px;
	bottom: 64px;
`;

const BorderContainer = styled.div<{ isFullBleed: boolean }>`
	visibility: ${props => (props.isFullBleed ? 'hidden' : 'visible ')};
	position: absolute;
	border-bottom: 1px solid ${Colors.black};
	bottom: 0;
	width: 100%;
	height: 1px;
	margin-left: calc(-1 * var(--gridMargin));
`;

interface TitleHeroProps {
	image: ImageData;
	fullBleedImage: boolean;
	title: string;
	subtitle: string;
	date: DateType;
	isDarkTheme: boolean;
}

export default function TitleHero({ props }: { props: TitleHeroProps }) {
	const arrowRef = useRef<HTMLDivElement>();
	const containerRef = useRef<HTMLDivElement>();
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		if (window.innerWidth <= parseInt(Breakpoints.mobile)) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
			createAnim();
		}

		window.addEventListener('resize', checkIsMobile);

		return () => {
			window.removeEventListener('resize', checkIsMobile);
		};
	}, []);

	useEffect(() => {
		!isMobile && createAnim();
	}, [isMobile]);

	const checkIsMobile = () => {
		if (window.innerWidth <= parseInt(Breakpoints.mobile)) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	const createAnim = () => {
		gsap.registerPlugin(ScrollToPlugin);

		gsap.to(arrowRef.current, {
			scrollTrigger: {
				trigger: containerRef.current,
				start: '30%',
				end: '30%',
				scrub: 0.5,
			},
			opacity: 0,
		});

		const tl = gsap.timeline({ repeat: -1 });
		tl.to(arrowRef.current, {
			y: -25,
			duration: 0.75,

			ease: Power1.easeOut,
		});
		tl.to(arrowRef.current, {
			delay: 1,
			y: 0,
			duration: 1.5,
			ease: Elastic.easeOut.config(1, 0.4),
		});
	};

	return (
		<Container isFullBleed={props.fullBleedImage} ref={containerRef}>
			<StyledImage isFullBleed={props.fullBleedImage}>
				<Image data={props.image} />
			</StyledImage>
			<InfoContainer isFullBleed={props.fullBleedImage} isDarkTheme={props.isDarkTheme}>
				<DateHolder>
					{props.date.endDate
						? DateFormats.Short(new Date(props.date.startDate)) + '-' + DateFormats.Short(new Date(props.date.endDate))
						: DateFormats.Short(new Date(props.date.startDate))}
				</DateHolder>
				<Title>{props.title}</Title>
				<Subtitle>{props.subtitle}</Subtitle>
			</InfoContainer>
			<BorderContainer isFullBleed={props.fullBleedImage} />
			{!isMobile && (
				<ArrowContainer ref={arrowRef} isDarkTheme={props.isDarkTheme} isFullBleed={props.fullBleedImage}>
					<DownArrow></DownArrow>
				</ArrowContainer>
			)}
		</Container>
	);
}
