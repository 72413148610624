import Loader from '../Loader';

export async function loadArticles(amountToLoad: number = 10000, types?: string[]) {
	var typeFilter = types ? formatFilterString(types, 'types') : '';

	var articles = await Loader.get(`/api/articles?max=${amountToLoad.toString()}&group=&offset=0${typeFilter}`);
	var json = JSON.parse(articles);
	return json;
}

export async function loadEvents(amountToLoad: number = 10000, fromDate?: Date, types?: string[]) {
	var dateFilter = fromDate ? '&from=' + fromDate : '';
	var typeFilter = types ? formatFilterString(types, 'types') : '';

	var events = await Loader.get(`/api/events?max=${amountToLoad.toString()}&group=&offset=0${dateFilter}${typeFilter}`);
	var json = JSON.parse(events);
	return json;
}

export async function loadExhibitions(amountToLoad: number = 10000, fromDate?: Date, types?: string[]) {
	var dateFilter = fromDate ? '&from=' + fromDate : '';
	var typeFilter = types ? formatFilterString(types, 'types') : '';

	var events = await Loader.get(
		`/api/exhibitions?max=${amountToLoad.toString()}&group=&offset=0${dateFilter}${typeFilter}`
	);
	var json = JSON.parse(events);
	return json;
}

export async function loadArchivedExhibitions(amountToLoad: number = 10000, fromDate?: Date, offset?: number) {
	var offsetFilter = offset !== undefined ? '&offset=' + offset : '';

	var dateFilter = fromDate ? '&from=' + fromDate : '';

	var events = await Loader.get(
		`/api/exhibitions?max=${amountToLoad.toString()}&archived=true&group=${offsetFilter}${dateFilter}`
	);
	var json = JSON.parse(events);
	return json;
}

export async function loadCurrentExhibitons(types?: string[]) {
	const max = 10;
	var typeFilter = types ? formatFilterString(types, 'types') : '';

	const exhibitions = await Loader.get(`/api/exhibitions?max=${max.toString()}&current=true${typeFilter}`);
	var json = JSON.parse(exhibitions);
	return json;
}

function formatFilterString(filterArray: string[], queryParam: string) {
	if (filterArray.length === 0) return '';

	let tempString = `&${queryParam}=`;

	for (let index = 0; index < filterArray.length; index++) {
		const element = filterArray[index];

		if (index > 0) {
			tempString = tempString + ',';
		}
		tempString = tempString + element;
	}

	return tempString;
}
