import React, { useContext, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Fonts, Colors, Breakpoints } from '../style/Variables';
import Functions from '../style/Functions';
import gsap from 'gsap';
import MalteseCross from './MalteseCross';
import SectionHeader from './SectionHeader';
import textPlugin from 'gsap/TextPlugin';
import CustomEvents, { Events } from './../core/CustomEvents';
import CustomOverDetection from '../core/utils/CustomOverDetection';
import HTML from '../core/utils/HTML';

const Container = styled.div`
	width: 100vw;
	position: relative;
	margin-left: -(var(--gridMargin));
	padding: 0;
	display: flex;

	flex-direction: column;
	font-family: ${Fonts.monoFamily};
	margin-top: var(--contentModuleGap);
`;

const MovedSectionHeader = styled.div`
	padding-left: ${Functions.colFixed(0, 2)};
	padding-bottom: 20px;
`;

const InfoContainer = styled.div`
	display: flex;
	position: relative;
	overflow: hidden;

	:before {
		transition: 0.4s transform ease, 0.5s opacity;

		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background-position: bottom left;
		background-image: linear-gradient(${Colors.black} 50%, ${Colors.black} 50%);
		background-size: 150% 150%;

		width: 150%;
		height: 150%;
		opacity: 0;

		transform-origin: 0% 100%;
		transform: rotate(-2deg) translateY(-100%);
	}
	> * {
		z-index: 2;
	}
`;

const ItemContainer = styled.div`
	${Functions.col(12, 12, 2)};
	border-top: 1px solid ${Colors.black};
	:last-child {
		border-bottom: 1px solid ${Colors.black};
	}

	:hover {
		${InfoContainer} {
			/* background-color: ${Colors.black}; */
			color: ${Colors.white};
			:before {
				transform: rotate(0) translateY(0%);
				opacity: 1;
			}
		}
	}
`;

const PrimaryInfo = styled.div`
	padding-top: 30px;
	padding-left: var(--gridMargin);
	padding-bottom: 30px;
	display: flex;
	transition: 0.2s all ease 0s;
	flex-direction: column;
	font-size: var(--fontNotQuiteMedium);
	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: var(--fontDefaultSize);
		padding-left: ${Functions.colFixed(2, 2)};
		flex-direction: row;
	}
`;

const TitleContainer = styled.div`
	width: ${Functions.colFixed(11, -1)};
	${Functions.breakpoint(Breakpoints.mobile)} {
		font-weight: initial;
		width: ${Functions.colFixed(5, 1)};
	}
`;

const SelectedInfo = styled.div`
	width: ${Functions.colFixed(4)};
	font-family: ${Fonts.monoFamilyLight};
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-right: -7px;
	}
`;

const PlusMinusSelector = styled.div`
	display: flex;
	align-items: center;
`;

const ExpandedInfo = styled.div`
	height: 0px;
	overflow: hidden;
`;

const ExpandContainer = styled.div`
	font-family: ${Fonts.monoFamilyLight};
	white-space: pre-wrap;
	padding-left: var(--gridMargin);
	padding-right: var(--gridMargin);
	padding-top: 30px;
	padding-bottom: 30px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-left: ${Functions.colFixed(7, 2)};
		//width: ${Functions.colFixed(12)};
		padding-right: ${Functions.colFixed(1, 2)};
		margin-top: 60px;
		margin-bottom: 60px;
	}
`;

export default function ExpandableList({ data }: { data: { headline: string; expandableElements: any } }) {
	gsap.registerPlugin(textPlugin);
	const expandableRefs = useRef(data.expandableElements.map(() => useRef()));
	const selectorRefs = useRef(data.expandableElements.map(() => useRef()));
	const elementsRef = useRef(data.expandableElements.map(() => useRef()));

	console.log(data.expandableElements);

	useEffect(() => {
		expandableRefs.current.forEach(item => {
			gsap.set(item.current, { height: '0px' });
		});
	});

	useEffect(() => {
		elementsRef.current.forEach(item => {
			CustomOverDetection.Instance.add(item.current, mouseOver, mouseOut, () => {}, 10);
		});
		return () => {
			elementsRef.current.forEach(item => {
				CustomOverDetection.Instance.remove(item.current);
			});
		};
	}, []);

	const toggleVisibility = index => {
		if (expandableRefs.current[index].current.style.height !== '0px') {
			gsap.to(expandableRefs.current[index].current, { height: '0px', duration: 0.5 });
			gsap.to(selectorRefs.current[index].current, { text: '+' });
		} else {
			gsap.to(expandableRefs.current[index].current, { height: 'auto', duration: 0.5 });
			gsap.to(selectorRefs.current[index].current, { text: '-' });
		}
	};

	const mouseOver = () => {
		CustomEvents.dispatch(Events.HIGHLIGHTCURSOR, {});
	};

	const mouseOut = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR);
	};

	return (
		<Container>
			<MovedSectionHeader>
				<SectionHeader title={data.headline}></SectionHeader>
			</MovedSectionHeader>
			{data.expandableElements.map((item, i) => (
				<ItemContainer ref={elementsRef.current[i]} onClick={e => toggleVisibility(i)} key={i}>
					<InfoContainer>
						<PrimaryInfo>
							<TitleContainer>{item.name}</TitleContainer> <SelectedInfo>{item.selectedInfo}</SelectedInfo>
						</PrimaryInfo>
						<PlusMinusSelector ref={selectorRefs.current[i]}>+</PlusMinusSelector>
					</InfoContainer>
					<ExpandedInfo ref={expandableRefs.current[i]}>
						<ExpandContainer dangerouslySetInnerHTML={{ __html: HTML.clean(item.expandedInfo) }}></ExpandContainer>
					</ExpandedInfo>
				</ItemContainer>
			))}
		</Container>
	);
}
