import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Loader from '../core/Loader';
import { loadArticles as loadArticles, loadEvents as loadEvents, loadExhibitions } from '../core/utils/EntityLoader';
import { getWord } from '../core/utils/Language';
import Functions from '../style/Functions';
import { Breakpoints, Colors, Fonts } from '../style/Variables';
import CommonWords from '../types/CommonWords';
import SmallInfoLink from './SmallInfoLink';

const Container = styled.div<{ empty: boolean }>`
	width: calc(100% + var(--gridMargin) * 2);
	display: flex;
	flex-direction: column;

	border-top: 1px solid ${Colors.black};
	padding-left: var(--gridMargin);
	padding-right: var(--gridMargin);
	padding-top: ${props => (props.empty ? '0px' : '100px')};

	box-sizing: border-box;
	gap: var(--gridGutter);

	margin-top: var(--contentModuleGap);

	${Functions.breakpoint(Breakpoints.mobile)} {
		flex-direction: row;
	}
`;

const Title = styled.h2`
	margin: 0;
	width: 100%;

	font-family: ${Fonts.sprittenFamily};
	font-size: var(--fontBig);

	line-height: 100%;

	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${Functions.col(5)};
	}
`;

const LinksContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;

	width: 100%;

	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${Functions.col(7)};
		gap: 50px;
	}
`;

export default function ReadNext({
	entityDate,
	type,
}: {
	entityDate: string;
	type: 'news' | 'events' | 'exhibitions';
}) {
	const [entityList, setEntities] = useState([]);

	useEffect(() => {
		loadData();
	}, []);

	async function loadData() {
		if (type === 'news') {
			var json = await loadArticles(4);
			if (json.data?.results) {
				setEntities(json.data?.results);
			}
		} else if (type === 'events') {
			var json = await loadEvents(4, new Date(entityDate));
			if (json.data?.results) {
				setEntities(json.data?.results);
			}
		} else if (type === 'exhibitions') {
			var json = await loadExhibitions(4, new Date(entityDate));
			if (json.data?.results) {
				setEntities(json.data?.results);
			}
		}
	}

	return entityList.length === 0 ? (
		<></>
	) : (
		<Container empty={entityList.length === 0}>
			{entityList.length > 0 && (
				<Title>
					{type === 'news'
						? getWord(CommonWords.readNext)
						: type === 'events'
						? getWord(CommonWords.moreEvents)
						: getWord(CommonWords.program)}
				</Title>
			)}
			<LinksContainer>
				{entityList.map((entity, index) => (
					<SmallInfoLink key={index} data={entity} type={type}></SmallInfoLink>
				))}
			</LinksContainer>
		</Container>
	);
}
