import React from 'react';
import styled from 'styled-components';
import { getWord } from '../../core/utils/Language';
import { Colors } from '../../style/Variables';
import CommonWords from '../../types/CommonWords';
import LinkButton from '../Buttons/LinkButton';
import StyledButton from '../Buttons/StyledButton';
import StyledButtonBig from '../Buttons/StyledButtonBig';
import RichTextModule from '../RichTextModule';
import SectionHeader from '../SectionHeader';
import TicketInfo from '../TicketInfo';
import { EventInfoProps } from './EventInfoSection';

const Container = styled.div`
	width: 100%;
`;

const TicketContainer = styled.div`
	width: fit-content;
`;

const TicketInfoContainer = styled.div`
	width: calc(100% + var(--gridMargin) * 2);
	margin-left: calc(-1 * var(--gridMargin));
	padding-left: var(--gridMargin);
	padding-bottom: 50px;
	margin-top: 46px;
	border-bottom: 1px solid ${Colors.black};
`;

const ButtonWrapper = styled.div`
	margin-top: 50px;
`;

export default function EventInfoSectionMobile(props: EventInfoProps) {
	return (
		<Container>
			{props.ticketLink && (
				<TicketContainer>
					<LinkButton to={props.ticketLink}>
						<StyledButtonBig
							text={(props.ticketLinkText ? props.ticketLinkText : getWord(CommonWords.tickets)) + ' ↗'}
						/>
					</LinkButton>
				</TicketContainer>
			)}
			<TicketInfoContainer>
				<TicketInfo
					location={props.location.location}
					openingHours={props.location.openingHours}
					price={props.price}
				></TicketInfo>
			</TicketInfoContainer>
			<SectionHeader subtitle={props.quote} />
			<RichTextModule text={props.description} size={16} />
			{props.pressReleaseLink && (
				<ButtonWrapper>
					<LinkButton to={props.pressReleaseLink}>
						<StyledButton
							text={props.pressReleaseLinkText ? props.pressReleaseLinkText : getWord(CommonWords.readMore)}
						/>
					</LinkButton>
				</ButtonWrapper>
			)}
		</Container>
	);
}
