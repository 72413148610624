import gsap from 'gsap';
import { TextPlugin } from 'gsap/all';
import gsapCore from 'gsap/gsap-core';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { takeCoverage } from 'v8';
import { Colors, Fonts, Index, Breakpoints } from '../style/Variables';
import CustomEvents, { Events } from './CustomEvents';
import BrowserDetect from './utils/BrowserDetect';

const Cursor = styled.div`
	pointer-events: none;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	box-sizing: border-box;
	min-width: 32px;
	min-height: 32px;

	z-index: ${Index.cursor};
`;

const Border = styled.div`
	position: absolute;
	width: 100%;
	height: 0;
	// 6px is 2 times border
	padding-top: calc(100% - 6px);

	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	border: 3px solid ${Colors.yellow};
	border-radius: 50%;
	box-sizing: border-box;
`;

const Background = styled.div`
	position: absolute;
	width: 100%;
	height: 0;
	padding-top: 100%;

	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: ${Colors.yellow};
	border-radius: 50%;

	box-sizing: border-box;
`;

const Text = styled.div`
	font-family: ${Fonts.monoFamily};
	font-size: var(--fontNotQuiteBig);
	font-weight: 400;
	line-height: 140%;
	text-align: center;
	white-space: nowrap;

	top: 50%;
	left: 50%;

	width: 0%;
	height: 0%;

	display: flex;
	justify-content: center;
	align-items: center;

	color: ${Colors.yellow};

	opacity: 0;

	box-sizing: border-box;
`;

const LoadingText = styled.div`
	position: absolute;

	font-family: ${Fonts.sprittenFamily};
	font-size: var(--fontBig);
	white-space: nowrap;

	/* opacity: 0; */
	box-sizing: border-box;
	background-color: ${Colors.black};

	color: ${Colors.yellow};
	padding: 0px 5px;

	top: 50%;
	left: -350%;

	width: fit-content;
	height: fit-content;

	display: flex;
	/* justify-content: center; */
	align-items: center;
	text-align: left;
	opacity: 0;
`;

export interface CursorEventData {
	text?: string;
	fontSize?: number;
}

export default function CustomCursor() {
	const [isSupported, setIsSupported] = useState(true);

	const cursorRef = useRef(undefined);
	const textRef = useRef(undefined);
	const borderRef = useRef(undefined);
	const backgroundRef = useRef(undefined);
	const loadingTextRef = useRef(undefined);

	const [cursorVisible, setCursorVisible] = useState(true);

	const [info, setInfo] = useState<String>('');

	useEffect(() => {
		gsap.registerPlugin(TextPlugin);
		if (BrowserDetect.isMobile()) {
			setIsSupported(false);
			return;
		}

		if (BrowserDetect.isTablet()) {
			setIsSupported(false);
			return;
		}

		window.addEventListener('mousemove', mouseMoveEvent);

		CustomEvents.listen(Events.LOADINGCURSOR, mouseLoading);
		CustomEvents.listen(Events.FINISHEDLOADING, doneLoading);

		sub();

		document.body.addEventListener('mouseenter', () => {
			setCursorVisible(true);
		});
		document.body.addEventListener('mouseleave', () => {
			setCursorVisible(false);
		});

		return () => {
			window.removeEventListener('mousemove', mouseMoveEvent);
			unsub();
			CustomEvents.remove(Events.LOADINGCURSOR, mouseLoading);
			CustomEvents.remove(Events.FINISHEDLOADING, doneLoading);
		};
	}, []);

	useEffect(() => {
		if (cursorVisible) {
			cursorRef.current.style.opacity = 1;
		} else {
			cursorRef.current.style.opacity = 0;
		}
	}, [cursorVisible]);

	const unsub = () => {
		CustomEvents.remove(Events.HIGHLIGHTCURSOR, highlightCursor);
		CustomEvents.remove(Events.STANDARDCURSOR, showNormalCursor);
		CustomEvents.remove(Events.SHOWMOUSE, showMouse);
		CustomEvents.remove(Events.HIDEMOUSE, hideMouse);
	};
	const sub = () => {
		CustomEvents.listen(Events.HIGHLIGHTCURSOR, highlightCursor);
		CustomEvents.listen(Events.STANDARDCURSOR, showNormalCursor);
		CustomEvents.listen(Events.SHOWMOUSE, showMouse);
		CustomEvents.listen(Events.HIDEMOUSE, hideMouse);
	};

	const showMouse = (e: CustomEvent) => {
		gsap.set(cursorRef.current, {
			duration: 0.05,
			scale: 1,
		});
	};

	const hideMouse = (e: CustomEvent) => {
		gsap.set(cursorRef.current, {
			scale: 0,
		});
	};

	const highlightCursor = (e: CustomEvent<CursorEventData>) => {
		let tl = gsap.timeline();
		tl.set(textRef.current, {
			fontSize: e.detail?.fontSize ? e.detail.fontSize + 'px' : 'var(--defaultSize)',
			overwrite: true,
		});

		tl.addLabel('start', 0);

		tl.to(cursorRef.current, {
			duration: 0,
			ease: 'power2.inOut',
			mixBlendMode: 'exclusion',
			overwrite: 'auto',
		});
		tl.to(backgroundRef.current, { duration: 0.05, ease: 'power2.inOut', opacity: 0, overwrite: true }, 'start');
		tl.to(
			textRef.current,
			{
				onStart: () => {
					setInfo(e.detail?.text);
				},
				duration: 0.1,
				padding: '29px',
				width: '100%',
				ease: 'power2.inOut',
			},
			'start'
		);
		tl.to(textRef.current, { opacity: 1, duration: 0.2 });
	};

	const showNormalCursor = (e: CustomEvent) => {
		let tl = gsap.timeline();
		tl.addLabel('start', 0);
		tl.to(textRef.current, { opacity: 0, duration: 0.05, overwrite: true });
		tl.to(
			textRef.current,
			{
				duration: 0.1,
				width: 0,
				padding: 0,
				ease: 'power2.inOut',
				onComplete: () => {
					setInfo('');
				},
			},
			'afterText'
		);
		tl.to(
			cursorRef.current,
			{
				overwrite: true,
				scale: 1,
				duration: 0,
				ease: 'power2.inOut',
				mixBlendMode: 'normal',
			},
			'start'
		);
		tl.to(backgroundRef.current, { duration: 0.1, ease: 'power2.inOut', opacity: 1 }, 'afterText');
	};

	const mouseLoading = () => {
		showNormalCursor(undefined);
		unsub();
		gsap.set(loadingTextRef.current, {
			text: 'loading',
			// opacity: 1,
		});

		gsap.to(loadingTextRef.current, {
			// text: 'loading',
			duration: 0.5,
			opacity: 1,
		});
		gsap.set([backgroundRef.current, borderRef.current], {
			overwrite: true,
			opacity: 0,
		});

		let tl = gsap.timeline({ repeat: -1, repeatDelay: 0.5 });
		tl.to(loadingTextRef.current, {
			duration: 0.5,
			text: 'loading',
		});
		tl.to(loadingTextRef.current, {
			duration: 0.5,
			text: 'loading.',
		});
		tl.to(loadingTextRef.current, {
			duration: 0.5,
			text: 'loading..',
		});
		tl.to(loadingTextRef.current, {
			duration: 0.5,
			text: 'loading...',
		});

		tl.play();
	};

	const doneLoading = () => {
		gsap.set(loadingTextRef.current, {
			opacity: 0,
		});
		gsap.set([backgroundRef.current, borderRef.current], {
			overwrite: true,
			opacity: 1,
		});
		showNormalCursor(undefined);
		sub();
	};

	const mouseMoveEvent = (e: MouseEvent) => {
		if (cursorRef.current) {
			gsap.to(cursorRef.current, {
				overwrite: 'auto',
				top: `${e.clientY}px`,
				left: `${e.clientX}px`,
				duration: 0,
			});
		}
	};

	return (
		<>
			{isSupported ? (
				<Cursor ref={cursorRef}>
					<Border ref={borderRef} />
					<Background ref={backgroundRef} />
					<Text ref={textRef}>{info}</Text>
					<LoadingText ref={loadingTextRef}>loading</LoadingText>
				</Cursor>
			) : (
				<></>
			)}
		</>
	);
}
