import React from 'react';
import styled from 'styled-components';
import Image, { ImageData } from './Image';

const StyledImage = styled(Image)`
	margin-top: var(--contentModuleGap);
	width: 100vw;
	height: 100vh;
`;

export default function FullscreenImage({ data }: { data: { image: ImageData } }) {
	return <StyledImage data={data.image}></StyledImage>;
}
