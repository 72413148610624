import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Link from '../core/PageSwitch/Link';
import { getWord, Language } from '../core/utils/Language';
import { Breakpoints, Colors, Fonts } from '../style/Variables';
import CommonWords from '../types/CommonWords';
import DetailedThumbnailLink from './DetailedThumbnailLink';
import SectionHeader from './SectionHeader';
import EventCard from './ListPages/EventCard';
import Functions from '../style/Functions';
import AppearByFade from './AnimationContainers/AppearByFade';

const Container = styled.div`
	margin-top: var(--contentModuleGap);
	width: 100%;
	display: flex;
	flex-direction: column;
	left: 0;

	overflow-x: hidden;
	overflow-y: hidden;
`;

const ContentContainer = styled.div`
	position: relative;

	margin-top: 35px;
	display: flex;
	gap: var(--gridGutter);
	justify-content: space-between;

	/* visibility: hidden; */
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-top: 71px;
	}
`;

const LinkContainer = styled(Link)`
	margin-top: 25px;
	align-self: end;
	font-family: ${Fonts.monoFamily};
	font-size: var(--fontNormal);
	font-weight: 500;

	${Functions.slideInHover(Colors.yellow)}

	text-transform: capitalize;

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-top: 62px;
	}
`;

export default function DetailedLinksSection({
	data,
}: {
	data: { title: string; link: any; links: any[]; contentType: 'exhibition' | 'event' };
}) {
	return (
		<Container>
			<SectionHeader title={data.title} />
			<AppearByFade>
				<ContentContainer>
					{data.links.length < 3
						? data.links[0] && <DetailedThumbnailLink data={data.links[0]} />
						: data.links.map((item, i) => {
								return <EventCard key={i} data={item} />;
						  })}
				</ContentContainer>
			</AppearByFade>
			{data.contentType && (
				<LinkContainer data={data.link}>
					<span>
						&rarr;{' '}
						{data.contentType === 'exhibition' ? getWord(CommonWords.allExhibitions) : getWord(CommonWords.allEvents)}
					</span>
				</LinkContainer>
			)}
		</Container>
	);
}
